import React from 'react'
import style from '../molecules/CollectorRewards/CollectorRewards.module.css'

type SquareButtonProps = {
  title: string
  onClick?: () => void
}

const SquareButton: React.FC<SquareButtonProps> = ({ title, onClick }) => {
  return (
    <div
      className={`${style['ver-wrapper']} ver-w-full ver-overflow-hidden ver-rounded-xl ver-px-8`}
    >
      <div
        className={`ver-my-6 ver-flex ver-flex-row ver-items-center ver-cursor-pointer ver-select-none`}
        onClick={onClick}
      >
        <div className="ver-w-full">
          <span>
            <span className="ver-font-sans ver-font-bold ver-text-lg ver-text-primary">
              {title}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default SquareButton
