import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { Blockchain, ContractType } from '@verisart/nft/src'
import axiosRetry from 'axios-retry'
import * as Sentry from '@sentry/browser'
import { Artist, CertificateDataRaw, NFT } from '@verisart/shared'
import mixpanel from 'mixpanel-browser'

export const ART_BLOCKS_INTEGRATION_ADDRESS_V1 =
  process.env.REACT_APP_ART_BLOCKS_INTEGRATION_V1?.toLowerCase() as string

export const ART_BLOCKS_INTEGRATION_ADDRESS_V2 =
  process.env.REACT_APP_ART_BLOCKS_INTEGRATION_V2?.toLowerCase() as string

export const HIGHLIGHT_MINT_MANAGER: Omit<
  Record<Blockchain, string>,
  | Blockchain.ETHEREUM_GOERLI
  | Blockchain.POLYGON_MUMBAI
  | Blockchain.BASE_MAINNET
  | Blockchain.BASE_SEPOLIA
  | Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
> = {
  [Blockchain.ETHEREUM]:
    process.env.REACT_APP_HIGHLIGHT_MINT_MANAGER_ETHEREUM?.toLowerCase() as string,
  [Blockchain.ETHEREUM_SEPOLIA]:
    process.env.REACT_APP_HIGHLIGHT_MINT_MANAGER_ETHEREUM_SEPOLIA?.toLowerCase() as string,
  [Blockchain.FAKE_ETHEREUM_ON_SEPOLIA]:
    process.env.REACT_APP_HIGHLIGHT_MINT_MANAGER_ETHEREUM_SEPOLIA?.toLowerCase() as string,
  [Blockchain.POLYGON]:
    process.env.REACT_APP_HIGHLIGHT_MINT_MANAGER_POLYGON?.toLowerCase() as string,
  [Blockchain.POLYGON_AMOY]:
    process.env.REACT_APP_HIGHLIGHT_MINT_MANAGER_POLYGON_AMOY?.toLowerCase() as string,
}

export const HIGHLIGHT_MINT_FEE_GWEI: Omit<
  Record<Blockchain, number>,
  | Blockchain.ETHEREUM_GOERLI
  | Blockchain.POLYGON_MUMBAI
  | Blockchain.BASE_MAINNET
  | Blockchain.BASE_SEPOLIA
  | Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
> = {
  [Blockchain.ETHEREUM]: 800000, //0.0008 eth
  [Blockchain.ETHEREUM_SEPOLIA]: 800000,
  [Blockchain.FAKE_ETHEREUM_ON_SEPOLIA]: 800000,
  [Blockchain.POLYGON]: 2256000000,
  [Blockchain.POLYGON_AMOY]: 2256000000, //2.256 matic
}

export type NFTTransferStatus =
  | 'NOT_REQUESTED'
  | 'PENDING'
  | 'COMPLETE'
  | 'FAILED'

export type MintStatus = 'RESERVED' | 'MINTING' | 'COMPLETE'

export interface PurchasedData {
  title: string
  certificateId: string | undefined
  thumbnail: string
  artist?: Artist
  productionYear: number | undefined
  productionYearEnd: number | undefined
  nft: NFT | undefined
  nftTransferStatus?: NFTTransferStatus | null
  images: {
    primaryUrl: string
    displayUrl: string | null
  }
  quantity: number | null
  dropVolume: number | null
  superLazyMintOrderId: string | null
  mintStatus: MintStatus | null
  mintingStartedAt: string | null
  blockchain: Blockchain | null
  nftTestnet: boolean
  placeholder: boolean
}

export interface GetCustomerCertificateResponse {
  certificateData: CertificateDataRaw
  withdrawalState: NFTTransferStatus
}

export interface SuperLazyDetailsResponse {
  artist: Artist
  title: string
  imageUrl: string
  volume: number | null
  quantity: number
  signedMinting: boolean
  claimed: boolean
  completed: boolean
  blockchain: Blockchain
  contractAddress: string
  tokenIds: string[]
  firstCertificatePrimaryAssetUrl?: string
  firstCertificateImageUrl?: string
  firstCertificateTitle?: string
}

export interface MintSignature {
  blockchain: Blockchain
  contractAddress: string
  extensionAddress: string
  uris: string[] | null | undefined
  mintTo: string
  chosenRoyaltyAddress: string | null | undefined
  chosenBps: number | null | undefined
  tokenNonce: string
  signature: string
  artBlocksProjectId: number | null | undefined
  contractType: ContractType
  highlightId: string | null
  highlightChosenTokens: string[] | null
  artBlocksHash: string | null
}

export interface ShopifyCustomerAPIOptions {
  shopDomain: string
  customerId: string | undefined
  customerSecret: string | undefined
  baseUrl: string
}

interface GetCustomerTokenResponse {
  token: string
  accountId: string
}

export class ShopifyCustomerAPI {
  readonly shopDomain: string
  private readonly customerId: string | undefined
  private readonly customerSecret: string | undefined
  private readonly options: ShopifyCustomerAPIOptions
  private sessionStatusCallback: ((expired: boolean) => void) | null

  readonly axios: AxiosInstance
  readonly axiosFactory: () => AxiosInstance

  private sessionToken: string | null

  constructor(options: ShopifyCustomerAPIOptions) {
    const { shopDomain, customerId, customerSecret } = options
    this.shopDomain = shopDomain
    this.customerId = customerId
    this.customerSecret = customerSecret
    this.options = options
    this.sessionStatusCallback = null
    this.sessionToken = localStorage.getItem('verisart.sessionToken')
    const accountId = localStorage.getItem('verisart.accountId')
    if (accountId) {
      Sentry.setUser({ username: accountId })
    } else {
      Sentry.setUser(null)
    }

    this.axiosFactory = () => {
      const client = axios.create({
        baseURL: `${options.baseUrl}/v3`,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      client.interceptors.request.use(async (request) => {
        // This is done lazily so that as long as the user is logged in, we'll get a successful refresh.
        await this.tryRefreshSessionFromCustomerSecret()

        const authorization = this.sessionToken
          ? `Bearer ${this.sessionToken}`
          : ''
        if (request.headers) {
          // Add (or overwrite the existing) authorization header
          request.headers.Authorization = authorization
        } else {
          // Setup empty headers with an Authorization header
          request.headers = { Authorization: authorization }
        }
        return request
      })

      client.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (error.response.status === 401) {
            this.setSessionToken(null, '')
          }
          return Promise.reject(error)
        }
      )
      return client
    }

    this.axios = this.axiosFactory()
  }
  async getPurchasedList(
    page: number,
    size: number
  ): Promise<AxiosResponse<PurchasedData[]>> {
    return this.axios.get(
      `/shop/customer/purchaseList?shop=${this.shopDomain}&page=${page}&size=${size}`
    )
  }

  async getCertificate(
    certificateId: string
  ): Promise<AxiosResponse<GetCustomerCertificateResponse>> {
    return this.axios.get(`/shop/customer/certificate/${certificateId}`)
  }

  async getSuperLazyMintOrder(
    id: string,
    pollInterval?: number
  ): Promise<AxiosResponse<SuperLazyDetailsResponse>> {
    if (pollInterval) {
      return new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            const response = await this.axios.get(
              `/shop/customer/superLazyMintOrder/${id}`
            )
            if (response.data && response.data.completed) {
              clearInterval(intervalId)
              resolve(response)
            }
          } catch (error) {
            clearInterval(intervalId)
            reject(error)
          }
        }, pollInterval)
      })
    } else {
      return this.axios.get(`/shop/customer/superLazyMintOrder/${id}`)
    }
  }

  /**
   * Note this is a super old API for "custodial NFTs" - things we ony had for a short period of time.
   * See https://www.notion.so/verisart/Shopify-Custodial-NFT-feature-should-be-retired-da038363d6894fd1a0ed931c617e2dd3
   * @deprecated
   */
  async claimNft(
    blockchain: Blockchain,
    contractAddress: string,
    tokenId: string,
    withdrawTo: string
  ): Promise<AxiosResponse> {
    return this.axios.post(`/nft/withdraw`, {
      blockchain,
      contractAddress,
      tokenId,
      withdrawTo,
    })
  }

  async claimMintNft(
    superLazyMintOrderId: string,
    mintTo: string
  ): Promise<AxiosResponse> {
    return this.axios.post(
      `/shop/customer/superLazyMintOrder/${superLazyMintOrderId}/mint`,
      {
        mintTo,
        shopDomain: this.shopDomain,
      }
    )
  }

  async claimMintSignable(
    orderId: string,
    mintTo: string
  ): Promise<AxiosResponse<MintSignature>> {
    return this.axios.post(
      `/shop/customer/superLazyMintOrder/${orderId}/mintSignature`,
      {
        mintTo,
      }
    )
  }

  async claimMintSignableSetTransaction(
    orderId: string,
    transaction: string
  ): Promise<AxiosResponse> {
    const axiosWithRetry = this.axiosFactory()
    axiosRetry(axiosWithRetry, {
      retries: 30,
      retryCondition: () => true,
      retryDelay: () => 1000,
    })
    return axiosWithRetry.post(
      `/shop/customer/superLazyMintOrder/${orderId}/mintSignature/transaction`,
      {
        transaction,
      }
    )
  }

  async tryRefreshSessionFromCustomerSecret() {
    if (!this.sessionToken && this.customerSecret) {
      try {
        const response = await axios.post<GetCustomerTokenResponse>(
          `${this.options.baseUrl}/v3/shop/customer/auth/token`,
          {
            shopDomain: this.shopDomain,
            shopifyCustomerId: this.customerId,
            secret: this.customerSecret,
          }
        )
        this.setSessionToken(response.data.token, response.data.accountId)
      } catch (e) {
        console.error('Failed to refresh session from customer secret', e)
      }
    }
  }

  async tryRefreshTokenFromMagicLinkCode(code: string) {
    // Note NOT using `this.axios` as no auth is needed here
    try {
      const response = await axios.post<GetCustomerTokenResponse>(
        `${this.options.baseUrl}/v3/shop/customer/auth/magicLink/token`,
        {
          code,
        }
      )
      this.setSessionToken(response.data.token, response.data.accountId)
    } catch (e) {
      // No action - we can't refresh session token
    }
  }

  async requestLoginCode(email: string) {
    const response = await axios.post<string>(
      `${this.options.baseUrl}/v3/shop/customer/auth/loginCode`,
      { email: email, shopDomain: this.shopDomain },
      {
        headers: {
          // Express the language preference of the user to the API with
          // the shops language as the highest priority.
          ...(window.verisartAppConfig?.shopLanguage
            ? {
                'Accept-Language': window.verisartAppConfig.shopLanguage,
              }
            : {}),
        },
      }
    )
    return response.data
  }

  async completeLoginCode(session: string, code: string) {
    const response = await axios.post<GetCustomerTokenResponse>(
      `${this.options.baseUrl}/v3/shop/customer/auth/loginCode/token`,
      { session, code }
    )
    this.setSessionToken(response.data.token, response.data.accountId)
  }

  async getPurchaseToken(): Promise<string> {
    const response = await this.axios.post<{ token: string }>(
      `${this.options.baseUrl}/v3/shop/customer/purchaseToken`
    )
    return response.data.token
  }

  setSessionToken(token: string | null, accountId: string) {
    this.sessionToken = token
    if (!token) {
      localStorage.removeItem('verisart.sessionToken')
      localStorage.removeItem('verisart.accountId')
      this.sessionStatusCallback?.(false)
      Sentry.setUser(null)
    } else {
      localStorage.setItem('verisart.sessionToken', token)
      localStorage.setItem('verisart.accountId', accountId)
      this.sessionStatusCallback?.(true)
      Sentry.setUser({ username: accountId })
      mixpanel.identify(accountId)
    }
  }

  hasSession(): boolean {
    return !!this.sessionToken
  }

  registerSessionStatusCallback(onExpiry: (expired: boolean) => void) {
    this.sessionStatusCallback = onExpiry
  }
}

export let customer: ShopifyCustomerAPI

export const initializeShopifyCustomerAPI = (
  options: ShopifyCustomerAPIOptions
) => {
  customer = new ShopifyCustomerAPI(options)
}
