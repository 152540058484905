// Stores the currently-being-typechecked object for error messages.
let obj: any = null
export class TranslationProxy {
  public readonly card: CardProxy
  public readonly dashboard: DashboardProxy
  public readonly footer: FooterProxy
  public readonly claim: ClaimProxy
  public readonly works: WorksProxy
  public readonly testNFT: BackOrTestNFTProxy
  public readonly login: LoginProxy
  public readonly dialog: DialogProxy
  public readonly utility: UtilityProxy
  public readonly topBar: TopBarProxy
  public static Parse(d: string): TranslationProxy {
    return TranslationProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): TranslationProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    d.card = CardProxy.Create(d.card, field + '.card')
    d.dashboard = DashboardProxy.Create(d.dashboard, field + '.dashboard')
    d.footer = FooterProxy.Create(d.footer, field + '.footer')
    d.claim = ClaimProxy.Create(d.claim, field + '.claim')
    d.works = WorksProxy.Create(d.works, field + '.works')
    d.testNFT = BackOrTestNFTProxy.Create(d.testNFT, field + '.testNFT')
    d.login = LoginProxy.Create(d.login, field + '.login')
    d.dialog = DialogProxy.Create(d.dialog, field + '.dialog')
    d.utility = UtilityProxy.Create(d.utility, field + '.utility')
    d.topBar = TopBarProxy.Create(d.topBar, field + '.topBar')
    return new TranslationProxy(d)
  }
  private constructor(d: any) {
    this.card = d.card
    this.dashboard = d.dashboard
    this.footer = d.footer
    this.claim = d.claim
    this.works = d.works
    this.testNFT = d.testNFT
    this.login = d.login
    this.dialog = d.dialog
    this.utility = d.utility
    this.topBar = d.topBar
  }
}

export class CardProxy {
  public readonly transfer: string
  public readonly footerText: string
  public static Parse(d: string): CardProxy {
    return CardProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): CardProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.transfer, false, field + '.transfer')
    checkString(d.footerText, false, field + '.footerText')
    return new CardProxy(d)
  }
  private constructor(d: any) {
    this.transfer = d.transfer
    this.footerText = d.footerText
  }
}

export class DashboardProxy {
  public readonly title: string
  public readonly claimButton: string
  public readonly claimInProgress: string
  public readonly claimPending: string
  public readonly claimPendingTooltip: string
  public readonly claimedTag: string
  public readonly subtitleOneNft: string
  public readonly subtitleManyNfts: string
  public readonly emptyState: EmptyStateProxy
  public readonly mintTakingTooLong: MintTakingTooLongProxy
  public readonly cards: CardsProxy
  public readonly subtitle: SubtitleProxy
  public readonly collectorRewards: CollectorRewardsProxy
  public readonly pendingTag: string
  public readonly notes: NotesProxy
  public readonly manageNfts: string
  public readonly upsellBanner: string
  public static Parse(d: string): DashboardProxy {
    return DashboardProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): DashboardProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    checkString(d.claimButton, false, field + '.claimButton')
    checkString(d.claimInProgress, false, field + '.claimInProgress')
    checkString(d.claimPending, false, field + '.claimPending')
    checkString(d.claimPendingTooltip, false, field + '.claimPendingTooltip')
    checkString(d.claimedTag, false, field + '.claimedTag')
    checkString(d.subtitleOneNft, false, field + '.subtitleOneNft')
    checkString(d.subtitleManyNfts, false, field + '.subtitleManyNfts')
    d.emptyState = EmptyStateProxy.Create(d.emptyState, field + '.emptyState')
    d.mintTakingTooLong = MintTakingTooLongProxy.Create(
      d.mintTakingTooLong,
      field + '.mintTakingTooLong'
    )
    d.cards = CardsProxy.Create(d.cards, field + '.cards')
    d.subtitle = SubtitleProxy.Create(d.subtitle, field + '.subtitle')
    d.collectorRewards = CollectorRewardsProxy.Create(
      d.collectorRewards,
      field + '.collectorRewards'
    )
    checkString(d.pendingTag, false, field + '.pendingTag')
    d.notes = NotesProxy.Create(d.notes, field + '.notes')
    checkString(d.manageNfts, false, field + '.manageNfts')
    checkString(d.upsellBanner, false, field + '.upsellBanner')
    return new DashboardProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.claimButton = d.claimButton
    this.claimInProgress = d.claimInProgress
    this.claimPending = d.claimPending
    this.claimPendingTooltip = d.claimPendingTooltip
    this.claimedTag = d.claimedTag
    this.subtitleOneNft = d.subtitleOneNft
    this.subtitleManyNfts = d.subtitleManyNfts
    this.emptyState = d.emptyState
    this.mintTakingTooLong = d.mintTakingTooLong
    this.cards = d.cards
    this.subtitle = d.subtitle
    this.collectorRewards = d.collectorRewards
    this.pendingTag = d.pendingTag
    this.notes = d.notes
    this.manageNfts = d.manageNfts
    this.upsellBanner = d.upsellBanner
  }
}

export class EmptyStateProxy {
  public readonly noNfts: string
  public readonly purchase: PurchaseProxy
  public static Parse(d: string): EmptyStateProxy {
    return EmptyStateProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): EmptyStateProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.noNfts, false, field + '.noNfts')
    d.purchase = PurchaseProxy.Create(d.purchase, field + '.purchase')
    return new EmptyStateProxy(d)
  }
  private constructor(d: any) {
    this.noNfts = d.noNfts
    this.purchase = d.purchase
  }
}

export class PurchaseProxy {
  public readonly string: string
  public readonly link: string
  public static Parse(d: string): PurchaseProxy {
    return PurchaseProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): PurchaseProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.string, false, field + '.string')
    checkString(d.link, false, field + '.link')
    return new PurchaseProxy(d)
  }
  private constructor(d: any) {
    this.string = d.string
    this.link = d.link
  }
}

export class MintTakingTooLongProxy {
  public readonly title: string
  public readonly message: string
  public static Parse(d: string): MintTakingTooLongProxy {
    return MintTakingTooLongProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): MintTakingTooLongProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    checkString(d.message, false, field + '.message')
    return new MintTakingTooLongProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.message = d.message
  }
}

export class CardsProxy {
  public readonly placeholderMintOverlayText: string
  public readonly placeholderMintExtraText: string
  public static Parse(d: string): CardsProxy {
    return CardsProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): CardsProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(
      d.placeholderMintOverlayText,
      false,
      field + '.placeholderMintOverlayText'
    )
    checkString(
      d.placeholderMintExtraText,
      false,
      field + '.placeholderMintExtraText'
    )
    return new CardsProxy(d)
  }
  private constructor(d: any) {
    this.placeholderMintOverlayText = d.placeholderMintOverlayText
    this.placeholderMintExtraText = d.placeholderMintExtraText
  }
}

export class SubtitleProxy {
  public readonly one: string
  public readonly other: string
  public static Parse(d: string): SubtitleProxy {
    return SubtitleProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): SubtitleProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.one, false, field + '.one')
    checkString(d.other, false, field + '.other')
    return new SubtitleProxy(d)
  }
  private constructor(d: any) {
    this.one = d.one
    this.other = d.other
  }
}

export class CollectorRewardsProxy {
  public readonly rewards: string
  public readonly reward: string
  public readonly reveal: string
  public readonly fileLabel: string
  public static Parse(d: string): CollectorRewardsProxy {
    return CollectorRewardsProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): CollectorRewardsProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.rewards, false, field + '.rewards')
    checkString(d.reward, false, field + '.reward')
    checkString(d.reveal, false, field + '.reveal')
    checkString(d.fileLabel, false, field + '.fileLabel')
    return new CollectorRewardsProxy(d)
  }
  private constructor(d: any) {
    this.rewards = d.rewards
    this.reward = d.reward
    this.reveal = d.reveal
    this.fileLabel = d.fileLabel
  }
}

export class NotesProxy {
  public readonly readMore: string
  public readonly readLess: string
  public static Parse(d: string): NotesProxy {
    return NotesProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): NotesProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.readMore, false, field + '.readMore')
    checkString(d.readLess, false, field + '.readLess')
    return new NotesProxy(d)
  }
  private constructor(d: any) {
    this.readMore = d.readMore
    this.readLess = d.readLess
  }
}

export class FooterProxy {
  public readonly poweredBy: string
  public static Parse(d: string): FooterProxy {
    return FooterProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): FooterProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.poweredBy, false, field + '.poweredBy')
    return new FooterProxy(d)
  }
  private constructor(d: any) {
    this.poweredBy = d.poweredBy
  }
}

export class ClaimProxy {
  public readonly heading: HeadingProxy
  public readonly subHeading: SubHeadingProxy
  public readonly notification: NotificationProxy
  public readonly walletAddress: string
  public readonly subtitleOneNft: string
  public readonly subtitleManyNfts: string
  public readonly button: ButtonProxy
  public readonly registrationError: RegistrationErrorProxy
  public static Parse(d: string): ClaimProxy {
    return ClaimProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): ClaimProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    d.heading = HeadingProxy.Create(d.heading, field + '.heading')
    d.subHeading = SubHeadingProxy.Create(d.subHeading, field + '.subHeading')
    d.notification = NotificationProxy.Create(
      d.notification,
      field + '.notification'
    )
    checkString(d.walletAddress, false, field + '.walletAddress')
    checkString(d.subtitleOneNft, false, field + '.subtitleOneNft')
    checkString(d.subtitleManyNfts, false, field + '.subtitleManyNfts')
    d.button = ButtonProxy.Create(d.button, field + '.button')
    d.registrationError = RegistrationErrorProxy.Create(
      d.registrationError,
      field + '.registrationError'
    )
    return new ClaimProxy(d)
  }
  private constructor(d: any) {
    this.heading = d.heading
    this.subHeading = d.subHeading
    this.notification = d.notification
    this.walletAddress = d.walletAddress
    this.subtitleOneNft = d.subtitleOneNft
    this.subtitleManyNfts = d.subtitleManyNfts
    this.button = d.button
    this.registrationError = d.registrationError
  }
}

export class HeadingProxy {
  public readonly connect: string
  public readonly confirm: string
  public readonly successful: string
  public readonly pending: string
  public static Parse(d: string): HeadingProxy {
    return HeadingProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): HeadingProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.connect, false, field + '.connect')
    checkString(d.confirm, false, field + '.confirm')
    checkString(d.successful, false, field + '.successful')
    checkString(d.pending, false, field + '.pending')
    return new HeadingProxy(d)
  }
  private constructor(d: any) {
    this.connect = d.connect
    this.confirm = d.confirm
    this.successful = d.successful
    this.pending = d.pending
  }
}

export class SubHeadingProxy {
  public readonly connect: string
  public readonly confirm: string
  public static Parse(d: string): SubHeadingProxy {
    return SubHeadingProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): SubHeadingProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.connect, false, field + '.connect')
    checkString(d.confirm, false, field + '.confirm')
    return new SubHeadingProxy(d)
  }
  private constructor(d: any) {
    this.connect = d.connect
    this.confirm = d.confirm
  }
}

export class NotificationProxy {
  public readonly title: TitleProxy
  public readonly body: BodyProxy
  public static Parse(d: string): NotificationProxy {
    return NotificationProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): NotificationProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    d.title = TitleProxy.Create(d.title, field + '.title')
    d.body = BodyProxy.Create(d.body, field + '.body')
    return new NotificationProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.body = d.body
  }
}

export class TitleProxy {
  public readonly claimError: string
  public readonly connectError: string
  public readonly successful: string
  public static Parse(d: string): TitleProxy {
    return TitleProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): TitleProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.claimError, false, field + '.claimError')
    checkString(d.connectError, false, field + '.connectError')
    checkString(d.successful, false, field + '.successful')
    return new TitleProxy(d)
  }
  private constructor(d: any) {
    this.claimError = d.claimError
    this.connectError = d.connectError
    this.successful = d.successful
  }
}

export class BodyProxy {
  public readonly claimError: string
  public readonly alreadyClaimed: string
  public readonly alreadyWithdrawn: string
  public readonly connectError: string
  public readonly successful: string
  public readonly couldntClaim: string
  public readonly userRejected: string
  public readonly pending: string
  public static Parse(d: string): BodyProxy {
    return BodyProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): BodyProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.claimError, false, field + '.claimError')
    checkString(d.alreadyClaimed, false, field + '.alreadyClaimed')
    checkString(d.alreadyWithdrawn, false, field + '.alreadyWithdrawn')
    checkString(d.connectError, false, field + '.connectError')
    checkString(d.successful, false, field + '.successful')
    checkString(d.couldntClaim, false, field + '.couldntClaim')
    checkString(d.userRejected, false, field + '.userRejected')
    checkString(d.pending, false, field + '.pending')
    return new BodyProxy(d)
  }
  private constructor(d: any) {
    this.claimError = d.claimError
    this.alreadyClaimed = d.alreadyClaimed
    this.alreadyWithdrawn = d.alreadyWithdrawn
    this.connectError = d.connectError
    this.successful = d.successful
    this.couldntClaim = d.couldntClaim
    this.userRejected = d.userRejected
    this.pending = d.pending
  }
}

export class ButtonProxy {
  public readonly successful: string
  public readonly connect: string
  public readonly confirm: string
  public readonly connectMagic: string
  public readonly showMagicWallet: string
  public readonly redirectLink: string
  public static Parse(d: string): ButtonProxy {
    return ButtonProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): ButtonProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.successful, false, field + '.successful')
    checkString(d.connect, false, field + '.connect')
    checkString(d.confirm, false, field + '.confirm')
    checkString(d.connectMagic, false, field + '.connectMagic')
    checkString(d.showMagicWallet, false, field + '.showMagicWallet')
    checkString(d.redirectLink, false, field + '.redirectLink')
    return new ButtonProxy(d)
  }
  private constructor(d: any) {
    this.successful = d.successful
    this.connect = d.connect
    this.confirm = d.confirm
    this.connectMagic = d.connectMagic
    this.showMagicWallet = d.showMagicWallet
    this.redirectLink = d.redirectLink
  }
}

export class RegistrationErrorProxy {
  public readonly no_wallet_found: string
  public readonly already_linked: string
  public readonly already_claimed_by_other: string
  public readonly metamask_already_pending: string
  public readonly metamask_user_cancelled: string
  public readonly unknown: string
  public static Parse(d: string): RegistrationErrorProxy {
    return RegistrationErrorProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): RegistrationErrorProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.no_wallet_found, false, field + '.no_wallet_found')
    checkString(d.already_linked, false, field + '.already_linked')
    checkString(
      d.already_claimed_by_other,
      false,
      field + '.already_claimed_by_other'
    )
    checkString(
      d.metamask_already_pending,
      false,
      field + '.metamask_already_pending'
    )
    checkString(
      d.metamask_user_cancelled,
      false,
      field + '.metamask_user_cancelled'
    )
    checkString(d.unknown, false, field + '.unknown')
    return new RegistrationErrorProxy(d)
  }
  private constructor(d: any) {
    this.no_wallet_found = d.no_wallet_found
    this.already_linked = d.already_linked
    this.already_claimed_by_other = d.already_claimed_by_other
    this.metamask_already_pending = d.metamask_already_pending
    this.metamask_user_cancelled = d.metamask_user_cancelled
    this.unknown = d.unknown
  }
}

export class WorksProxy {
  public readonly buttonText: string
  public readonly bannerCOA: string
  public readonly noHistoryError: string
  public readonly purchaseHistoryTitle: string
  public readonly view: string
  public readonly detailsHeading: string
  public readonly descriptionHeading: string
  public readonly notesHeading: string
  public readonly readMoreText: ReadMoreTextProxy
  public readonly transfer: string
  public readonly create: string
  public readonly metafieldLabel: MetafieldLabelProxy
  public readonly buyAddOn: string
  public readonly transferNFT: string
  public readonly transferNFTHelpText: string
  public readonly transferNFTInProgress: string
  public readonly transferNFTSuccessful: string
  public readonly transferButton: string
  public readonly disconnectButton: string
  public readonly closeButton: string
  public readonly transferNFTProcessStarted: string
  public readonly connectWallet: string
  public static Parse(d: string): WorksProxy {
    return WorksProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): WorksProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.buttonText, false, field + '.buttonText')
    checkString(d.bannerCOA, false, field + '.bannerCOA')
    checkString(d.noHistoryError, false, field + '.noHistoryError')
    checkString(d.purchaseHistoryTitle, false, field + '.purchaseHistoryTitle')
    checkString(d.view, false, field + '.view')
    checkString(d.detailsHeading, false, field + '.detailsHeading')
    checkString(d.descriptionHeading, false, field + '.descriptionHeading')
    checkString(d.notesHeading, false, field + '.notesHeading')
    d.readMoreText = ReadMoreTextProxy.Create(
      d.readMoreText,
      field + '.readMoreText'
    )
    checkString(d.transfer, false, field + '.transfer')
    checkString(d.create, false, field + '.create')
    d.metafieldLabel = MetafieldLabelProxy.Create(
      d.metafieldLabel,
      field + '.metafieldLabel'
    )
    checkString(d.buyAddOn, false, field + '.buyAddOn')
    checkString(d.transferNFT, false, field + '.transferNFT')
    checkString(d.transferNFTHelpText, false, field + '.transferNFTHelpText')
    checkString(
      d.transferNFTInProgress,
      false,
      field + '.transferNFTInProgress'
    )
    checkString(
      d.transferNFTSuccessful,
      false,
      field + '.transferNFTSuccessful'
    )
    checkString(d.transferButton, false, field + '.transferButton')
    checkString(d.disconnectButton, false, field + '.disconnectButton')
    checkString(d.closeButton, false, field + '.closeButton')
    checkString(
      d.transferNFTProcessStarted,
      false,
      field + '.transferNFTProcessStarted'
    )
    checkString(d.connectWallet, false, field + '.connectWallet')
    return new WorksProxy(d)
  }
  private constructor(d: any) {
    this.buttonText = d.buttonText
    this.bannerCOA = d.bannerCOA
    this.noHistoryError = d.noHistoryError
    this.purchaseHistoryTitle = d.purchaseHistoryTitle
    this.view = d.view
    this.detailsHeading = d.detailsHeading
    this.descriptionHeading = d.descriptionHeading
    this.notesHeading = d.notesHeading
    this.readMoreText = d.readMoreText
    this.transfer = d.transfer
    this.create = d.create
    this.metafieldLabel = d.metafieldLabel
    this.buyAddOn = d.buyAddOn
    this.transferNFT = d.transferNFT
    this.transferNFTHelpText = d.transferNFTHelpText
    this.transferNFTInProgress = d.transferNFTInProgress
    this.transferNFTSuccessful = d.transferNFTSuccessful
    this.transferButton = d.transferButton
    this.disconnectButton = d.disconnectButton
    this.closeButton = d.closeButton
    this.transferNFTProcessStarted = d.transferNFTProcessStarted
    this.connectWallet = d.connectWallet
  }
}

export class ReadMoreTextProxy {
  public readonly openText: string
  public readonly closeText: string
  public static Parse(d: string): ReadMoreTextProxy {
    return ReadMoreTextProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): ReadMoreTextProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.openText, false, field + '.openText')
    checkString(d.closeText, false, field + '.closeText')
    return new ReadMoreTextProxy(d)
  }
  private constructor(d: any) {
    this.openText = d.openText
    this.closeText = d.closeText
  }
}

export class MetafieldLabelProxy {
  public readonly productionYear: string
  public readonly medium: string
  public readonly duration: string
  public readonly edition: string
  public readonly dimensions: string
  public readonly orderName: string
  public readonly tokenGateBlockchain: string
  public readonly tokenGateContractAddress: string
  public readonly tokenGateTokenId: string
  public readonly tokenGateClaimedBy: string
  public static Parse(d: string): MetafieldLabelProxy {
    return MetafieldLabelProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): MetafieldLabelProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.productionYear, false, field + '.productionYear')
    checkString(d.medium, false, field + '.medium')
    checkString(d.duration, false, field + '.duration')
    checkString(d.edition, false, field + '.edition')
    checkString(d.dimensions, false, field + '.dimensions')
    checkString(d.orderName, false, field + '.orderName')
    checkString(d.tokenGateBlockchain, false, field + '.tokenGateBlockchain')
    checkString(
      d.tokenGateContractAddress,
      false,
      field + '.tokenGateContractAddress'
    )
    checkString(d.tokenGateTokenId, false, field + '.tokenGateTokenId')
    checkString(d.tokenGateClaimedBy, false, field + '.tokenGateClaimedBy')
    return new MetafieldLabelProxy(d)
  }
  private constructor(d: any) {
    this.productionYear = d.productionYear
    this.medium = d.medium
    this.duration = d.duration
    this.edition = d.edition
    this.dimensions = d.dimensions
    this.orderName = d.orderName
    this.tokenGateBlockchain = d.tokenGateBlockchain
    this.tokenGateContractAddress = d.tokenGateContractAddress
    this.tokenGateTokenId = d.tokenGateTokenId
    this.tokenGateClaimedBy = d.tokenGateClaimedBy
  }
}

export class BackOrTestNFTProxy {
  public readonly title: string
  public static Parse(d: string): BackOrTestNFTProxy {
    return BackOrTestNFTProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): BackOrTestNFTProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    return new BackOrTestNFTProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
  }
}

export class LoginProxy {
  public readonly requestCode: RequestCodeProxy
  public readonly submitCode: SubmitCodeProxy
  public static Parse(d: string): LoginProxy {
    return LoginProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): LoginProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    d.requestCode = RequestCodeProxy.Create(
      d.requestCode,
      field + '.requestCode'
    )
    d.submitCode = SubmitCodeProxy.Create(d.submitCode, field + '.submitCode')
    return new LoginProxy(d)
  }
  private constructor(d: any) {
    this.requestCode = d.requestCode
    this.submitCode = d.submitCode
  }
}

export class RequestCodeProxy {
  public readonly title: string
  public readonly subtitle: string
  public readonly inputPlaceholder: string
  public readonly button: string
  public readonly unknownUser: string
  public readonly tooManyRequests: string
  public readonly error: string
  public readonly tooManyAttempts: string
  public static Parse(d: string): RequestCodeProxy {
    return RequestCodeProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): RequestCodeProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    checkString(d.subtitle, false, field + '.subtitle')
    checkString(d.inputPlaceholder, false, field + '.inputPlaceholder')
    checkString(d.button, false, field + '.button')
    checkString(d.unknownUser, false, field + '.unknownUser')
    checkString(d.tooManyRequests, false, field + '.tooManyRequests')
    checkString(d.error, false, field + '.error')
    checkString(d.tooManyAttempts, false, field + '.tooManyAttempts')
    return new RequestCodeProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.subtitle = d.subtitle
    this.inputPlaceholder = d.inputPlaceholder
    this.button = d.button
    this.unknownUser = d.unknownUser
    this.tooManyRequests = d.tooManyRequests
    this.error = d.error
    this.tooManyAttempts = d.tooManyAttempts
  }
}

export class SubmitCodeProxy {
  public readonly title: string
  public readonly subtitle: string
  public readonly inputPlaceholder: string
  public readonly button: string
  public readonly codeNotValid: string
  public readonly error: string
  public readonly sendAnotherCode: string
  public static Parse(d: string): SubmitCodeProxy {
    return SubmitCodeProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): SubmitCodeProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    checkString(d.subtitle, false, field + '.subtitle')
    checkString(d.inputPlaceholder, false, field + '.inputPlaceholder')
    checkString(d.button, false, field + '.button')
    checkString(d.codeNotValid, false, field + '.codeNotValid')
    checkString(d.error, false, field + '.error')
    checkString(d.sendAnotherCode, false, field + '.sendAnotherCode')
    return new SubmitCodeProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.subtitle = d.subtitle
    this.inputPlaceholder = d.inputPlaceholder
    this.button = d.button
    this.codeNotValid = d.codeNotValid
    this.error = d.error
    this.sendAnotherCode = d.sendAnotherCode
  }
}

export class DialogProxy {
  public readonly connectWallet: ConnectWalletProxy
  public static Parse(d: string): DialogProxy {
    return DialogProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): DialogProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    d.connectWallet = ConnectWalletProxy.Create(
      d.connectWallet,
      field + '.connectWallet'
    )
    return new DialogProxy(d)
  }
  private constructor(d: any) {
    this.connectWallet = d.connectWallet
  }
}

export class ConnectWalletProxy {
  public readonly title: string
  public readonly providerMetamask: string
  public readonly providerWalletconnect: string
  public static Parse(d: string): ConnectWalletProxy {
    return ConnectWalletProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): ConnectWalletProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    checkString(d.providerMetamask, false, field + '.providerMetamask')
    checkString(
      d.providerWalletconnect,
      false,
      field + '.providerWalletconnect'
    )
    return new ConnectWalletProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.providerMetamask = d.providerMetamask
    this.providerWalletconnect = d.providerWalletconnect
  }
}

export class UtilityProxy {
  public readonly copy: CopyProxy
  public readonly back: BackOrTestNFTProxy
  public static Parse(d: string): UtilityProxy {
    return UtilityProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): UtilityProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    d.copy = CopyProxy.Create(d.copy, field + '.copy')
    d.back = BackOrTestNFTProxy.Create(d.back, field + '.back')
    return new UtilityProxy(d)
  }
  private constructor(d: any) {
    this.copy = d.copy
    this.back = d.back
  }
}

export class CopyProxy {
  public readonly title: string
  public readonly copiedTitle: string
  public static Parse(d: string): CopyProxy {
    return CopyProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): CopyProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.title, false, field + '.title')
    checkString(d.copiedTitle, false, field + '.copiedTitle')
    return new CopyProxy(d)
  }
  private constructor(d: any) {
    this.title = d.title
    this.copiedTitle = d.copiedTitle
  }
}

export class TopBarProxy {
  public readonly disconnect: string
  public readonly showMagicWallet: string
  public readonly connectWallet: string
  public readonly logOut: string
  public readonly linkedWallets: string
  public static Parse(d: string): TopBarProxy {
    return TopBarProxy.Create(JSON.parse(d))
  }
  public static Create(d: any, field = 'root'): TopBarProxy {
    if (!field) {
      obj = d
      field = 'root'
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.disconnect, false, field + '.disconnect')
    checkString(d.showMagicWallet, false, field + '.showMagicWallet')
    checkString(d.connectWallet, false, field + '.connectWallet')
    checkString(d.logOut, false, field + '.logOut')
    checkString(d.linkedWallets, false, field + '.linkedWallets')
    return new TopBarProxy(d)
  }
  private constructor(d: any) {
    this.disconnect = d.disconnect
    this.showMagicWallet = d.showMagicWallet
    this.connectWallet = d.connectWallet
    this.logOut = d.logOut
    this.linkedWallets = d.linkedWallets
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, 'non-nullable object', false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'string' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'string', nullable)
  }
}
function errorHelper(
  field: string,
  d: any,
  type: string,
  nullable: boolean
): never {
  if (nullable) {
    type += ', null, or undefined'
  }
  throw new TypeError(
    'Expected ' +
      type +
      ' at ' +
      field +
      ' but found:\n' +
      JSON.stringify(d) +
      '\n\nFull object:\n' +
      JSON.stringify(obj)
  )
}
