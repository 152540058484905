import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as API from '../api'
import styles from '../styles/containers.module.css'
import { Spinner } from '@verisart/shared'
import Claim from '../components/organisms/Claim'
import useGetCertificate from '../lib/hooks/useGetCertificate'
import useClaim from '../lib/hooks/useClaim'
import {
  ConnectWalletError,
  isConnectWalletError,
  useConnectWallet,
} from '@verisart/nft/src'
import ConnectMetamaskDialog from '../components/molecules/ConnectMetamaskDialog'
import { ComputedCertificateFeature } from '@verisart/shared'
import assert from 'assert'
import TopBar from 'src/components/organisms/TopBar'

/**
 * Note this is the claim page for "custodial NFTs" - things we ony had for a short period of time.
 * See https://www.notion.so/verisart/Shopify-Custodial-NFT-feature-should-be-retired-da038363d6894fd1a0ed931c617e2dd3
 *
 * @deprecated
 */
const LegacyClaimPage = () => {
  const [showConnect, setShowConnect] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const {
    certificate,
    isLoading: isCertificateLoading,
    nft,
  } = useGetCertificate(id as string)

  const [connectError, setConnectError] = useState<
    ConnectWalletError | undefined | string
  >(undefined)
  const {
    connecting,
    connectWallet,
    account: activeWallet,
    loading: isLinkedWalletCheckLoading,
    platform,
  } = useConnectWallet(API.customer.axios, (e) => {
    setConnectError(e)
  })

  assert(nft)

  const {
    claim,
    claimed,
    error: claimError,
    loading: claiming,
  } = useClaim(nft, activeWallet)

  return (
    <div
      className={`ver-w-full ver-flex ver-flex-col ver-mb-20 ${styles.container}`}
    >
      <TopBar />
      <div className="ver-flex ver-flex-grow ver-items-center ver-justify-center">
        {isCertificateLoading || isLinkedWalletCheckLoading || !certificate ? (
          <Spinner />
        ) : (
          <Claim
            activeWallet={activeWallet}
            assetDetails={{
              ...certificate,
              quantity: null,
              nftTestnet: certificate?.computedFeatures.includes(
                ComputedCertificateFeature.NFT_TESTNET
              ),
              signedMinting: false,
              claimed: false,
            }}
            claimed={claimed}
            loading={connecting || claiming}
            connectError={
              isConnectWalletError(connectError)
                ? connectError.message
                : connectError
            }
            claimError={claimError}
            onBack={() => {
              navigate(-1)
            }}
            onConnect={() => setShowConnect(true)}
            onClaim={claim}
            platform={platform}
          />
        )}
      </div>
      <ConnectMetamaskDialog
        show={showConnect}
        hide={() => setShowConnect(false)}
        connectWallet={connectWallet}
      />
    </div>
  )
}

export default LegacyClaimPage
