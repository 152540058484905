import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 186 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="93" cy="93" r="93" fill="white" />
    <path
      d="M127.048 66.25L96.872 131.75H90.7L75.8095 98.374L85.4766 96.8962L93.0291 113.475L94.1472 115.929L95.2973 113.489L117.57 66.25H127.048Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M72.2885 91.6237L59.9639 65.25H70.7504L82.1715 90.1222L72.2885 91.6237Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M9.37801 84.5331L20.2335 85.6136L20.0377 87.5801L9.18228 86.4996L9.37801 84.5331Z"
      fill="black"
    />
    <path
      d="M15.0071 71.192C14.544 71.1254 14.1413 71.239 13.799 71.533C13.4575 71.8234 13.2144 72.2526 13.0698 72.8204C12.9682 73.2196 12.9412 73.5773 12.9889 73.8936C13.0366 74.2099 13.1444 74.4701 13.3123 74.6741C13.4802 74.8781 13.6962 75.0155 13.9603 75.0865C14.1806 75.1426 14.3843 75.1413 14.5714 75.0827C14.7595 75.0206 14.9326 74.9163 15.0909 74.7697C15.2457 74.6223 15.3881 74.4515 15.5182 74.2574C15.6482 74.0633 15.7667 73.8645 15.8735 73.6608L16.3737 72.7328C16.5682 72.3573 16.7916 72.0056 17.0436 71.6777C17.2966 71.3463 17.5808 71.0651 17.8961 70.8339C18.2124 70.5993 18.5628 70.4394 18.9473 70.3541C19.3318 70.2688 19.7529 70.2844 20.2106 70.4009C20.83 70.5587 21.3352 70.8559 21.726 71.2926C22.1134 71.7284 22.3633 72.2794 22.4758 72.9456C22.5857 73.6076 22.5329 74.3618 22.3173 75.2084C22.1078 76.0309 21.7987 76.7125 21.3898 77.2533C20.9817 77.7907 20.49 78.1675 19.9146 78.3838C19.34 78.5966 18.6982 78.6273 17.9892 78.4761L18.469 76.592C18.8443 76.6582 19.1795 76.6226 19.4746 76.4853C19.7698 76.3479 20.0183 76.1345 20.2203 75.8452C20.4231 75.5524 20.5754 75.2064 20.677 74.8072C20.7831 74.3908 20.814 74.0102 20.7699 73.6655C20.7232 73.3165 20.6108 73.024 20.4327 72.7881C20.2511 72.5513 20.0094 72.3926 19.7074 72.3121C19.4312 72.2454 19.1835 72.2684 18.9642 72.3812C18.7415 72.493 18.5336 72.6764 18.3404 72.9314C18.1446 73.182 17.9504 73.4843 17.7576 73.8383L17.1416 74.9622C16.6962 75.7759 16.182 76.3778 15.5989 76.768C15.0132 77.1539 14.3419 77.2504 13.5848 77.0576C12.9619 76.899 12.4594 76.5914 12.0773 76.135C11.696 75.6751 11.4508 75.1198 11.3417 74.4691C11.2292 73.8174 11.2671 73.1217 11.4555 72.3818C11.6466 71.6316 11.9448 71.0076 12.35 70.5098C12.7527 70.0078 13.2258 69.6537 13.7693 69.4476C14.3094 69.2407 14.8784 69.2079 15.4764 69.3492L15.0071 71.192Z"
      fill="black"
    />
    <path
      d="M20.3039 57.5084C19.8603 57.3598 19.4437 57.3994 19.0542 57.6272C18.6662 57.8518 18.3502 58.2305 18.1061 58.7632C17.9346 59.1377 17.8439 59.4848 17.8342 59.8045C17.8244 60.1242 17.8839 60.3995 18.0125 60.6303C18.1411 60.8611 18.329 61.035 18.5761 61.1521C18.7827 61.2468 18.9834 61.2821 19.178 61.2579C19.3741 61.2306 19.5632 61.159 19.7451 61.0432C19.9239 60.9258 20.0946 60.7834 20.2573 60.6157C20.42 60.4481 20.5722 60.2737 20.7138 60.0925L21.3722 59.2691C21.6309 58.9346 21.9137 58.6286 22.2204 58.3511C22.5287 58.0705 22.8586 57.8447 23.2103 57.6738C23.5635 57.4997 23.9369 57.4051 24.3305 57.3901C24.724 57.3751 25.1355 57.466 25.5649 57.6627C26.146 57.9289 26.5897 58.3118 26.896 58.8114C27.199 59.3096 27.3461 59.8965 27.3374 60.5721C27.3269 61.2431 27.1397 61.9756 26.7759 62.7699C26.4224 63.5415 25.9961 64.1567 25.4969 64.6154C24.9992 65.071 24.4479 65.3536 23.843 65.4632C23.2396 65.5696 22.6027 65.4848 21.9323 65.2089L22.742 63.4413C23.0993 63.5737 23.4354 63.5988 23.7504 63.5166C24.0654 63.4343 24.3482 63.269 24.5987 63.0205C24.8507 62.7688 25.0625 62.4557 25.2341 62.0811C25.413 61.6905 25.5117 61.3217 25.5301 60.9746C25.5467 60.6229 25.4885 60.315 25.3556 60.051C25.2194 59.7855 25.01 59.586 24.7273 59.4527C24.4676 59.3376 24.2197 59.3158 23.9838 59.3874C23.7447 59.4576 23.5072 59.6007 23.2715 59.8169C23.034 60.0284 22.7887 60.291 22.5356 60.6048L21.7282 61.6C21.1441 62.3207 20.5303 62.8207 19.8868 63.1001C19.2414 63.3748 18.5636 63.3494 17.8534 63.024C17.269 62.7564 16.8298 62.3637 16.5356 61.8462C16.243 61.3255 16.1013 60.7352 16.1106 60.0754C16.1166 59.4142 16.2786 58.7365 16.5966 58.0424C16.919 57.3385 17.3242 56.7781 17.8121 56.361C18.2983 55.9392 18.8272 55.6757 19.3988 55.5704C19.9672 55.4636 20.5329 55.5333 21.0959 55.7795L20.3039 57.5084Z"
      fill="black"
    />
    <path
      d="M26.1394 42.439L27.2905 40.8326L33.0837 44.984C33.7188 45.4391 34.1698 45.9894 34.4367 46.6351C34.7057 47.2778 34.7825 47.9663 34.667 48.7005C34.5486 49.4326 34.2309 50.1595 33.7138 50.8812C33.1946 51.6057 32.6074 52.1417 31.9522 52.4891C31.294 52.8344 30.6174 52.983 29.9223 52.9349C29.2272 52.8868 28.5621 52.6352 27.9271 52.1802L22.1338 48.0288L23.2849 46.4224L28.944 50.4776C29.3134 50.7424 29.7004 50.8974 30.1049 50.9426C30.5115 50.9849 30.9035 50.9163 31.2811 50.7369C31.6558 50.5554 31.989 50.2612 32.2806 49.8542C32.5723 49.4472 32.7438 49.0371 32.7952 48.624C32.8458 48.2059 32.7847 47.8126 32.6119 47.4441C32.439 47.0756 32.1679 46.759 31.7984 46.4942L26.1394 42.439Z"
      fill="black"
    />
    <path
      d="M40.3187 42.5616L32.8284 34.6304L37.9867 29.7588L39.1242 30.9632L35.4026 34.478L37.4361 36.6311L40.8905 33.3688L42.0279 34.5732L38.5735 37.8355L40.618 40.0003L44.3706 36.4563L45.508 37.6607L40.3187 42.5616Z"
      fill="black"
    />
    <path
      d="M50.337 33.8782L44.3894 24.733L47.8189 22.5027C48.5214 22.0458 49.1902 21.7845 49.8253 21.7188C50.4633 21.6512 51.0477 21.7583 51.5783 22.0402C52.11 22.3171 52.5656 22.7473 52.9451 23.3308C53.3265 23.9172 53.5325 24.5076 53.5632 25.1019C53.5949 25.6913 53.4461 26.2604 53.1168 26.8092C52.7855 27.355 52.2686 27.8564 51.5661 28.3133L49.1235 29.9019L48.229 28.5265L50.4483 27.0832C50.8591 26.816 51.1587 26.5407 51.3471 26.2572C51.5336 25.9707 51.6177 25.6766 51.5995 25.375C51.5823 25.0685 51.4682 24.753 51.2571 24.4285C51.0461 24.104 50.8021 23.8688 50.525 23.7228C50.2459 23.5739 49.9367 23.525 49.5973 23.5763C49.256 23.6247 48.8784 23.7834 48.4646 24.0525L46.9464 25.0399L51.9937 32.8008L50.337 33.8782ZM52.3664 26.6618L57.3254 29.3333L55.4767 30.5356L50.5579 27.8379L52.3664 26.6618Z"
      fill="black"
    />
    <path
      d="M76.0833 14.5661L74.1667 15.1096C74.0229 14.8107 73.8432 14.5609 73.6275 14.36C73.4108 14.1556 73.1689 13.9991 72.9018 13.8902C72.6348 13.7814 72.349 13.724 72.0447 13.718C71.7427 13.7077 71.4312 13.748 71.1101 13.8391C70.5395 14.0009 70.0746 14.2877 69.7152 14.6997C69.3549 15.1082 69.128 15.6229 69.0346 16.2436C68.9402 16.861 69.0049 17.5643 69.2287 18.3535C69.4563 19.1563 69.7728 19.7974 70.1782 20.2767C70.586 20.7517 71.0483 21.0672 71.565 21.2234C72.0842 21.3752 72.6274 21.3706 73.1945 21.2098C73.5088 21.1207 73.791 20.9964 74.0411 20.8369C74.2936 20.673 74.5059 20.4781 74.6781 20.2521C74.8536 20.0251 74.9827 19.7708 75.0652 19.489C75.1511 19.2063 75.1795 18.9029 75.1504 18.579L77.07 18.0457C77.1397 18.5649 77.1169 19.077 77.0018 19.5821C76.89 20.0863 76.6903 20.56 76.4026 21.0033C76.1139 21.4432 75.74 21.8298 75.2808 22.163C74.8207 22.4928 74.278 22.7463 73.6528 22.9236C72.7303 23.1852 71.8464 23.2051 71.001 22.9834C70.1557 22.7617 69.4103 22.3068 68.7648 21.6187C68.1194 20.9307 67.6368 20.0229 67.3172 18.8955C66.9965 17.7646 66.9325 16.7382 67.125 15.8162C67.3166 14.8907 67.714 14.1118 68.3172 13.4794C68.9204 12.8469 69.6798 12.4009 70.5954 12.1413C71.1796 11.9756 71.7461 11.9036 72.2948 11.9252C72.8435 11.9468 73.3594 12.0607 73.8424 12.2671C74.3244 12.47 74.7567 12.7645 75.1393 13.1506C75.5244 13.5323 75.8391 14.0042 76.0833 14.5661Z"
      fill="black"
    />
    <path
      d="M83.7256 20.6277L82.8093 9.75719L89.8794 9.16125L90.0186 10.812L84.9177 11.242L85.1665 14.1931L89.9011 13.7941L90.0402 15.4448L85.3056 15.8439L85.5557 18.811L90.699 18.3775L90.8382 20.0282L83.7256 20.6277Z"
      fill="black"
    />
    <path
      d="M96.9718 20.0782L98.0186 9.21944L102.091 9.61199C102.925 9.69241 103.611 9.9048 104.148 10.2492C104.689 10.5939 105.08 11.0418 105.319 11.5929C105.562 12.1408 105.651 12.7611 105.584 13.4539C105.517 14.1503 105.31 14.7404 104.964 15.2243C104.621 15.705 104.15 16.0574 103.551 16.2815C102.952 16.502 102.235 16.572 101.401 16.4916L98.5004 16.212L98.6578 14.579L101.293 14.833C101.781 14.88 102.187 14.8514 102.511 14.747C102.835 14.6391 103.084 14.4616 103.258 14.2143C103.435 13.9639 103.543 13.646 103.58 13.2607C103.617 12.8754 103.573 12.5394 103.447 12.2525C103.321 11.9622 103.11 11.7313 102.813 11.5599C102.515 11.385 102.121 11.2739 101.63 11.2265L99.8272 11.0527L98.9389 20.2678L96.9718 20.0782ZM103.056 15.6986L105.27 20.8781L103.075 20.6665L100.908 15.4916L103.056 15.6986Z"
      fill="black"
    />
    <path
      d="M112.165 12.8796L112.617 11.2858L120.991 13.6602L120.539 15.254L117.295 14.3342L114.771 23.2357L112.885 22.701L115.409 13.7994L112.165 12.8796Z"
      fill="black"
    />
    <path
      d="M129.04 17.0507L124.528 26.9828L122.729 26.1653L127.241 16.2333L129.04 17.0507Z"
      fill="black"
    />
    <path
      d="M130.583 30.0931L136.476 20.9124L142.357 24.6873L141.462 26.0815L137.244 23.374L135.644 25.8664L139.459 28.3149L138.564 29.7091L134.749 27.2606L132.246 31.1606L130.583 30.0931Z"
      fill="black"
    />
    <path
      d="M149.353 30.3506L142.203 38.5896L140.711 37.2943L147.861 29.0553L149.353 30.3506Z"
      fill="black"
    />
    <path
      d="M158.674 45.772L157.4 44.2404C157.615 43.9878 157.771 43.7222 157.867 43.4436C157.966 43.1627 158.011 42.8781 158.002 42.5898C157.993 42.3015 157.93 42.0171 157.812 41.7364C157.699 41.4562 157.536 41.1878 157.322 40.9312C156.943 40.4753 156.492 40.1666 155.97 40.0052C155.45 39.8415 154.888 39.8428 154.282 40.0091C153.68 40.1732 153.063 40.5175 152.433 41.0421C151.791 41.5758 151.333 42.1251 151.06 42.69C150.791 43.2554 150.69 43.8059 150.757 44.3416C150.828 44.8777 151.053 45.3724 151.43 45.8256C151.639 46.0767 151.867 46.2843 152.114 46.4482C152.366 46.6125 152.631 46.7276 152.907 46.7933C153.186 46.8617 153.47 46.8765 153.761 46.8377C154.055 46.8016 154.344 46.7045 154.628 46.5466L155.894 48.085C155.447 48.3592 154.97 48.5461 154.462 48.6457C153.956 48.748 153.441 48.7575 152.92 48.6743C152.4 48.5888 151.895 48.4038 151.405 48.1192C150.917 47.8323 150.465 47.439 150.049 46.9394C149.436 46.2023 149.059 45.4024 148.919 44.5398C148.779 43.6771 148.893 42.8113 149.26 41.9423C149.627 41.0733 150.261 40.2641 151.162 39.5146C152.066 38.763 152.978 38.2882 153.899 38.0903C154.822 37.8902 155.696 37.9375 156.518 38.2324C157.341 38.5273 158.057 39.0406 158.665 39.7723C159.054 40.2391 159.349 40.7277 159.552 41.2381C159.755 41.7484 159.86 42.2661 159.867 42.7913C159.877 43.3142 159.783 43.8288 159.585 44.3351C159.393 44.8419 159.089 45.3209 158.674 45.772Z"
      fill="black"
    />
    <path
      d="M156.911 56.4235L155.908 54.568L167.331 52.7582L168.491 54.9042L160.724 63.4756L159.721 61.62L165.964 54.9327L165.923 54.8577L156.911 56.4235ZM160.707 54.4502L163.443 59.5108L162.047 60.2657L159.311 55.2051L160.707 54.4502Z"
      fill="black"
    />
    <path
      d="M171.087 64.6541L172.665 64.1496L175.316 72.4401L173.738 72.9446L172.711 69.7329L163.898 72.5504L163.301 70.6833L172.114 67.8658L171.087 64.6541Z"
      fill="black"
    />
    <path
      d="M165.916 80.717L176.734 79.3111L177.648 86.3471L176.005 86.5606L175.346 81.4843L172.409 81.866L173.021 86.5778L171.378 86.7913L170.766 82.0795L167.813 82.4632L168.478 87.5818L166.835 87.7953L165.916 80.717Z"
      fill="black"
    />
    <path
      d="M48.2919 151.975L45.9605 160.713L46.054 160.774L53.1305 155.146L54.9483 156.337L45.7524 163.353L43.7119 162.016L46.4786 150.787L48.2919 151.975Z"
      fill="black"
    />
    <path
      d="M55.0513 168.606L59.3198 158.567L65.8493 161.343L65.2011 162.868L60.4903 160.865L59.3314 163.59L63.704 165.449L63.0558 166.974L58.6832 165.115L57.5181 167.855L62.2682 169.874L61.62 171.399L55.0513 168.606Z"
      fill="black"
    />
    <path
      d="M69.3104 173.977L71.7946 163.354L75.778 164.286C76.594 164.477 77.2455 164.779 77.7325 165.192C78.2229 165.605 78.55 166.101 78.7139 166.679C78.882 167.255 78.8868 167.881 78.7283 168.559C78.569 169.24 78.2855 169.798 77.8778 170.231C77.4744 170.662 76.9606 170.948 76.3366 171.09C75.7133 171.229 74.9937 171.203 74.1776 171.012L71.3405 170.349L71.7141 168.751L74.2919 169.354C74.7691 169.466 75.1752 169.491 75.5102 169.431C75.846 169.367 76.1165 169.225 76.3215 169.003C76.5309 168.778 76.6796 168.477 76.7678 168.1C76.8559 167.724 76.8568 167.385 76.7703 167.084C76.6847 166.779 76.5059 166.522 76.234 166.313C75.9629 166.1 75.587 165.937 75.1063 165.825L73.3428 165.412L71.2347 174.427L69.3104 173.977ZM75.9236 170.447L77.4277 175.875L75.2804 175.373L73.823 169.956L75.9236 170.447Z"
      fill="black"
    />
    <path
      d="M87.1524 166.045L86.2137 176.914L84.2449 176.744L85.1835 165.875L87.1524 166.045Z"
      fill="black"
    />
    <path
      d="M99.8998 169.036C99.8234 168.575 99.5935 168.225 99.2099 167.987C98.8298 167.75 98.3473 167.647 97.7623 167.681C97.3511 167.705 97.0019 167.787 96.7148 167.928C96.4277 168.069 96.2122 168.25 96.0685 168.472C95.9247 168.694 95.8589 168.941 95.871 169.214C95.8841 169.441 95.9468 169.635 96.0592 169.796C96.1751 169.956 96.3269 170.09 96.5144 170.196C96.7017 170.3 96.9075 170.384 97.1318 170.449C97.3561 170.514 97.5815 170.567 97.8079 170.608L98.8436 170.804C99.2604 170.876 99.6631 170.983 100.052 171.124C100.444 171.265 100.798 171.451 101.114 171.682C101.433 171.913 101.691 172.198 101.889 172.539C102.086 172.88 102.198 173.286 102.225 173.758C102.262 174.396 102.131 174.967 101.833 175.472C101.535 175.973 101.085 176.377 100.484 176.686C99.8858 176.99 99.1508 177.168 98.2787 177.218C97.4313 177.267 96.6882 177.178 96.0491 176.951C95.4136 176.725 94.9059 176.37 94.5259 175.886C94.1496 175.403 93.9264 174.8 93.8565 174.079L95.7975 173.967C95.8477 174.345 95.9828 174.654 96.2029 174.894C96.423 175.134 96.7015 175.306 97.0383 175.411C97.3787 175.516 97.7545 175.557 98.1658 175.533C98.5948 175.508 98.9669 175.423 99.2822 175.277C99.6008 175.127 99.8457 174.931 100.017 174.69C100.188 174.446 100.266 174.167 100.252 173.855C100.232 173.572 100.135 173.343 99.9612 173.168C99.7873 172.989 99.5497 172.846 99.2483 172.739C98.9503 172.628 98.6034 172.534 98.2077 172.457L96.9503 172.21C96.0401 172.031 95.3109 171.722 94.7629 171.284C94.2182 170.843 93.9234 170.232 93.8785 169.452C93.8416 168.81 93.983 168.238 94.3028 167.736C94.626 167.234 95.0814 166.832 95.6688 166.532C96.256 166.228 96.9307 166.054 97.6929 166.01C98.4658 165.965 99.1507 166.061 99.7476 166.297C100.348 166.529 100.828 166.873 101.189 167.329C101.549 167.782 101.752 168.314 101.798 168.927L99.8998 169.036Z"
      fill="black"
    />
    <path
      d="M110.973 175.458L108.922 175.95L110.111 164.446L112.484 163.877L118.769 173.588L116.717 174.08L111.861 166.327L111.778 166.347L110.973 175.458ZM110.043 171.283L115.637 169.941L116.007 171.484L110.413 172.826L110.043 171.283Z"
      fill="black"
    />
    <path
      d="M125.227 171.315L120.844 161.326L124.59 159.682C125.357 159.345 126.059 159.196 126.697 159.234C127.337 159.271 127.896 159.472 128.374 159.836C128.854 160.196 129.234 160.694 129.513 161.331C129.794 161.972 129.902 162.588 129.836 163.179C129.771 163.766 129.532 164.304 129.118 164.792C128.702 165.276 128.111 165.687 127.344 166.024L124.675 167.195L124.016 165.692L126.44 164.629C126.889 164.432 127.229 164.209 127.461 163.96C127.692 163.707 127.823 163.431 127.854 163.13C127.886 162.825 127.825 162.495 127.67 162.141C127.514 161.786 127.311 161.514 127.062 161.325C126.811 161.133 126.513 161.035 126.17 161.03C125.825 161.022 125.427 161.118 124.975 161.316L123.317 162.044L127.037 170.521L125.227 171.315ZM128.401 164.524L132.861 167.966L130.841 168.852L126.426 165.391L128.401 164.524Z"
      fill="black"
    />
    <path
      d="M133.397 157.17L132.481 155.789L139.734 150.977L140.649 152.357L137.84 154.222L142.955 161.931L141.322 163.015L136.206 155.305L133.397 157.17Z"
      fill="black"
    />
  </svg>
)

export default SvgComponent
