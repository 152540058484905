import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .5C8.982.5 7.95.663 6.904.989A12.94 12.94 0 0 0 4.277 2.19c-.706.408-1.399.876-2.077 1.406-.476.38-.924.78-1.345 1.201l-.468.49L0 5.713l.387.428.428.448c.38.38.794.746 1.242 1.1.611.502 1.242.957 1.894 1.364.815.489 1.63.883 2.444 1.181.964.367 1.921.584 2.872.652.217.027.461.04.733.04.272 0 .516-.013.733-.04.95-.068 1.908-.285 2.872-.652.815-.298 1.63-.692 2.444-1.181a18.02 18.02 0 0 0 1.894-1.365c.448-.353.862-.72 1.242-1.1l.428-.447.387-.428-.387-.428-.468-.489c-.421-.42-.87-.821-1.345-1.201a18.023 18.023 0 0 0-2.077-1.406A12.94 12.94 0 0 0 13.096.99 10.364 10.364 0 0 0 10 .5Zm0 1.303c1.263 0 2.566.306 3.91.917.435.72.652 1.5.652 2.342a4.536 4.536 0 0 1-4.053 4.542l-.509.02c-.109 0-.278-.007-.51-.02a4.502 4.502 0 0 1-2.056-.754 4.536 4.536 0 0 1-1.996-3.788c0-.842.21-1.616.631-2.322h-.02c1.358-.624 2.675-.937 3.951-.937Zm0 1.304c-.543 0-1.005.19-1.385.57-.38.38-.57.842-.57 1.385s.19 1.005.57 1.385c.38.38.842.57 1.385.57s1.005-.19 1.385-.57c.38-.38.57-.842.57-1.385s-.19-1.005-.57-1.385c-.38-.38-.842-.57-1.385-.57Zm-5.703.61c-.108.463-.163.91-.163 1.345 0 1.127.3 2.166.897 3.116a15.134 15.134 0 0 1-3.157-2.464 15.437 15.437 0 0 1 2.423-1.996Zm11.406 0c.91.612 1.717 1.277 2.423 1.997-.964.977-2.016 1.799-3.157 2.464.598-.95.897-1.989.897-3.116 0-.434-.055-.882-.163-1.344Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgComponent
