import * as API from '../../api'
import { useCallback, useState } from 'react'
import { AxiosError } from 'axios'
import {
  mintManifoldSignableTransaction,
  mintSignableTransaction,
  mintArtblocksSignableTransaction,
  mintHighlightSignableTransaction,
  Blockchain,
  mintHighlightChooseSignableTransaction,
} from '@verisart/nft/src'
import Web3 from 'web3'
import assert, { fail } from 'assert'
import { MintSignature } from '../../api'
import { useAccount } from 'wagmi'
import {
  ensureBlockchain,
  web3FromConnector,
} from '@verisart/nft/src/useConnectWallet'

interface ErrorData {
  message?: string
}

const isErrorData = (data: unknown): data is ErrorData => {
  return typeof data === 'object'
}

const handleSignedTransaction = async (
  web3: Web3,
  userWallet: string,
  mintSignature: MintSignature
) => {
  // eslint-disable-next-line no-console
  console.info(`Sending sign TX`)

  const {
    contractAddress,
    extensionAddress,
    mintTo,
    signature,
    uris,
    tokenNonce,
    chosenRoyaltyAddress,
    chosenBps,
    artBlocksProjectId,
    contractType,
    highlightId,
    blockchain,
    highlightChosenTokens,
    artBlocksHash,
  } = mintSignature

  const royaltyAddresses: string[] =
    !chosenRoyaltyAddress || chosenBps === 0 ? [] : [chosenRoyaltyAddress]
  const royaltyBpss: number[] = !chosenBps || chosenBps === 0 ? [] : [chosenBps]

  switch (contractType) {
    case 'HIGHLIGHT':
      assert(highlightId, 'highlight vector id is missing')
      assert(extensionAddress === API.HIGHLIGHT_MINT_MANAGER[blockchain])
      assert(
        blockchain === Blockchain.ETHEREUM ||
          blockchain === Blockchain.ETHEREUM_SEPOLIA ||
          blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA,
        'Currently only support highlight on Ethereum and Sepolia'
      )

      if (highlightChosenTokens !== null) {
        assert(highlightChosenTokens.length != 0)
        return await mintHighlightChooseSignableTransaction(
          web3,
          userWallet,
          extensionAddress,
          mintTo,
          highlightId,
          highlightChosenTokens,
          signature,
          API.HIGHLIGHT_MINT_FEE_GWEI[blockchain]
        )
      } else {
        return await mintHighlightSignableTransaction(
          web3,
          userWallet,
          extensionAddress,
          mintTo,
          highlightId,
          1,
          signature,
          API.HIGHLIGHT_MINT_FEE_GWEI[blockchain]
        )
      }
    case 'ART_BLOCKS': {
      assert(artBlocksProjectId != null, 'projectId is missing')
      const version =
        extensionAddress == API.ART_BLOCKS_INTEGRATION_ADDRESS_V1
          ? '1.0'
          : extensionAddress == API.ART_BLOCKS_INTEGRATION_ADDRESS_V2
          ? '2.0'
          : fail('invalid art blocks minter contract')
      return await mintArtblocksSignableTransaction(
        web3,
        userWallet,
        mintTo,
        artBlocksProjectId,
        contractAddress,
        extensionAddress,
        tokenNonce,
        signature,
        artBlocksHash,
        version
      )
    }

    case 'MANIFOLD':
      assert(uris?.[0], 'URIs are missing')
      return await mintManifoldSignableTransaction(
        web3,
        contractAddress,
        extensionAddress,
        mintTo,
        uris[0],
        tokenNonce,
        userWallet,
        royaltyAddresses,
        royaltyBpss,
        signature
      )

    case 'REGULAR':
      assert(uris?.[0], 'URIs are missing')
      return await mintSignableTransaction(
        web3,
        contractAddress,
        mintTo,
        uris[0],
        tokenNonce,
        userWallet,
        royaltyAddresses,
        royaltyBpss,
        signature
      )

    default:
      throw new Error(`Unexpected contract type ${contractType}`)
  }
}

const useClaimMintSignable = () => {
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>()
  const [claimed, setClaimed] = useState<boolean>()

  const { connector } = useAccount()

  const claimSignable = useCallback(
    async (mintSignableOrderId: string, userWallet: string) => {
      setLoading(true)
      setError(undefined)

      assert(connector)

      try {
        // call mintSignature API
        const mintSignature = await API.customer.claimMintSignable(
          mintSignableOrderId,
          userWallet
        )

        const { blockchain, mintTo, uris } = mintSignature.data

        assert(mintTo === userWallet, 'mintTo does not match')
        assert(!uris || uris.length === 1, 'uris are set but not length 1')

        await ensureBlockchain(blockchain)

        // make the transaction
        const transaction = await handleSignedTransaction(
          await web3FromConnector(connector, blockchain),
          userWallet,
          mintSignature.data
        )
        // eslint-disable-next-line no-console
        console.info('Got TX hash', transaction)

        await API.customer.claimMintSignableSetTransaction(
          mintSignableOrderId,
          transaction
        )
        setClaimed(true)
      } catch (e: any) {
        console.error('Failed to claim mint signable', e)
        if (
          e.message.includes('User rejected') ||
          e.message.includes('User denied')
        ) {
          setError('User rejected transaction')
        } else if (e.error?.code === 4001) {
          if (e.error?.message === 'A request is already in progress') {
            setError('A request is already in progress')
          }
          setError('Metamask user cancelled request')
        } else if (e.code === 'NETWORK_ERROR') {
          setError('Network Error: Please check your internet connection.')
        } else {
          console.error('Unexpected error in useClaimSignable', e)
          const axiosError = e as AxiosError
          if (
            axiosError?.response?.status === 400 &&
            isErrorData(axiosError?.response?.data)
          ) {
            setError(
              `Sorry, something went wrong. Here is some details: ${axiosError.response.data.message}`
            )
          } else {
            setError(
              `Sorry, something went wrong. Here is some details: ${
                e.message ?? e
              }`
            )
          }
        }
      } finally {
        setLoading(false)
      }
    },
    [connector]
  )

  return { claimSignable, claimed, error, loading }
}

export default useClaimMintSignable
