import React, { useCallback, useState } from 'react'
import LoginRequestCode, { SessionEmail } from './LoginRequestCode'
import LoginEnterCode from './LoginEnterCode'

type LoginFlowProps = {
  email: string | null
}

const LoginFlow: React.FC<LoginFlowProps> = ({ email }) => {
  const [loginCodeSession, setLoginCodeSession] = useState<
    SessionEmail | undefined
  >(undefined)

  const sendAnotherCode = useCallback(
    () => setLoginCodeSession(undefined),
    [setLoginCodeSession]
  )

  return (
    <div className="ver-flex ver-justify-center ver-items-center ver-mt-40 ver-mb-40">
      {!loginCodeSession ? (
        <LoginRequestCode
          setLoginCodeSession={setLoginCodeSession}
          email={email}
        />
      ) : (
        <LoginEnterCode
          loginCodeSession={loginCodeSession}
          sendAnotherCode={sendAnotherCode}
        />
      )}
    </div>
  )
}

export default LoginFlow
