import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.219.781.78 2.22 9.562 11l-8.78 8.781 1.437 1.438L11 12.437l8.781 8.782 1.438-1.438L12.437 11l8.782-8.781L19.78.78 11 9.562 2.219.783Z"
      fill="currentColor"
    />
    <path
      d="m2.219.781.177-.177-.177-.176-.177.176.177.177ZM.78 2.22l-.177-.177-.176.177.176.177.177-.177ZM9.562 11l.177.177.177-.177-.177-.177-.177.177Zm-8.78 8.781-.178-.176-.176.176.176.177.177-.177Zm1.437 1.438-.177.176.177.177.177-.177-.177-.176ZM11 12.437l.177-.176-.177-.177-.177.177.177.177Zm8.781 8.782-.176.176.176.177.177-.177-.177-.176Zm1.438-1.438.176.177.177-.177-.177-.176-.176.176ZM12.437 11l-.176-.177-.177.177.177.177.177-.177Zm8.782-8.781.176.177.177-.177-.177-.177-.176.177ZM19.78.78l.177-.177-.177-.176-.176.176.176.177ZM11 9.562l-.177.177.177.177.177-.177L11 9.562ZM2.042.604.604 2.042l.354.354L2.396.958 2.042.604ZM.604 2.396l8.782 8.78.353-.353L.958 2.042l-.354.354Zm8.782 8.427L.604 19.605l.354.353 8.781-8.781-.353-.354ZM.604 19.958l1.438 1.438.354-.354-1.438-1.438-.354.354Zm1.792 1.438 8.78-8.782-.353-.353-8.781 8.781.354.353Zm8.427-8.782 8.782 8.781.353-.353-8.781-8.781-.354.353Zm9.135 8.781 1.438-1.437-.354-.353-1.438 1.437.354.353Zm1.438-1.79-8.782-8.782-.353.354 8.781 8.781.353-.353Zm-8.782-8.428 8.781-8.781-.353-.354-8.781 8.781.353.354Zm8.781-9.135L19.958.604l-.353.354 1.437 1.438.353-.354ZM19.605.604l-8.782 8.782.354.353L19.958.958l-.353-.354Zm-8.428 8.782L2.396.604l-.354.354 8.781 8.781.354-.353Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
