import React from 'react'
import t from '../../lib/translation'

type TestNFTNotification = {
  size: 'sm' | 'md'
}

// TODO: Merge into a common UI package as is used in both website and shopify-customer
const TestNFTNotification: React.FC<TestNFTNotification> = ({ size }) => {
  return (
    <div className="ver-relative ver-h-0 ver-w-full">
      <div
        className={`${
          size === 'md' ? 'ver-h-20' : 'ver-h-10'
        } ver-text-surface ver-absolute ver-top-0 ver-left-0 ver-z-10 ver-flex ver-w-full ver-items-center ver-justify-center ver-text-base ver-text-white ver-opacity-80`}
        style={{ backgroundColor: 'rgba(178, 134, 52)' }}
      >
        {t.testNFT.title}
      </div>
    </div>
  )
}

export default TestNFTNotification
