import t from './translation'

export const alreadyTaken = (claimError: string) => {
  let errorMessage = t.claim.notification.body.claimError
  if (claimError && claimError.includes('already been claimed')) {
    errorMessage = t.claim.notification.body.alreadyClaimed
  } else if (claimError.includes('withdrawn already')) {
    errorMessage = t.claim.notification.body.alreadyWithdrawn
  } else if (claimError.includes('User rejected')) {
    errorMessage = t.claim.notification.body.userRejected
    return errorMessage
  }
}

export const userRejected = (claimError: string) => {
  if (claimError.includes('User rejected')) return true
  return false
}
