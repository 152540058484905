import { ArtistName } from '../types/Artist'
import {
  Dimensions,
  Unit,
  Duration,
  Edition,
  ComputedConfidenceLevel,
  ProductionYears,
} from '../types/Certificate'
import { ArtistAttributes } from '../types/Artist'
import t from './../libs/translation'
import { DateTime } from 'luxon'
import { Blockchain } from '@verisart/nft/src'
import { ObjectType } from '../types/Certificate'

function n(num: number | string): string {
  return num.toString()
}

export const dimensionString = (dimensions: Dimensions): string => {
  return `${dimensions.height} × ${dimensions.width}${
    dimensions.depth ? ` × ${dimensions.depth}` : ''
  } ${
    dimensions.unit === Unit.PIXEL
      ? 'px'
      : dimensions.unit === Unit.INCH
      ? 'inches'
      : dimensions.unit.substr(0, 2).toLowerCase()
  }`
}

export const durationString = (duration: Duration): string => {
  const mins = duration.hours * 60 + duration.minutes
  return `${mins}:${duration.seconds.toString().padStart(2, '0')} min`
}

export const editionString = (edition: Edition, short?: boolean): string => {
  // See https://docs.google.com/spreadsheets/d/15668sIkT4VuSKWkYKJje-Qby3l95Hpzsep2WQzS7rsM/edit#gid=0
  // !NOTE! The `core` project has some similar code in VerisartCertificateService which is required for
  // formatting emails. So if you make changes here, do the same there too.

  // Numbered edition
  if (edition.number && edition.number != -1) {
    if (!edition.apVolume) {
      if (edition.volume) {
        if (short) {
          return `${n(edition.number)}/${n(edition.volume)}`
        } else {
          return `Edition ${n(edition.number)}/${n(edition.volume)}`
        }
      } else {
        if (short) {
          return `${n(edition.number)}`
        } else {
          return `Edition ${n(edition.number)} / Open Edition`
        }
      }
    } else {
      if (edition.volume) {
        if (short) {
          return `${n(edition.number)}/${n(edition.volume)}`
        } else {
          return `Edition ${n(edition.number)}/${n(edition.volume)}, ${n(
            edition.apVolume
          )} AP${edition.apVolume > 1 ? 's' : ''}`
        }
      } else {
        if (short) {
          return `${n(edition.number)}`
        } else {
          return `Edition ${n(edition.number)} / Open Edition, ${n(
            edition.apVolume
          )} AP${edition.apVolume > 1 ? 's' : ''}`
        }
      }
    }
  }

  // Unnumbered edition
  if (edition.number && edition.number == -1) {
    if (!edition.apVolume) {
      if (edition.volume) {
        if (short) {
          return `Unnumbered`
        } else {
          return `Edition of ${n(edition.volume)}`
        }
      } else {
        return `Open Edition`
      }
    } else {
      if (edition.volume) {
        if (short) {
          return `Unnumbered`
        } else {
          return `Edition of ${n(edition.volume)}, ${n(edition.apVolume)} AP${
            edition.apVolume > 1 ? 's' : ''
          }`
        }
      } else {
        if (short) {
          return `Unnumbered`
        } else {
          return `Open Edition, ${n(edition.apVolume)} AP${
            edition.apVolume > 1 ? 's' : ''
          }`
        }
      }
    }
  }

  // Numbered AP
  if (edition.apNumber && edition.apNumber != -1) {
    if (!edition.apVolume) {
      if (edition.volume) {
        if (short) {
          return `AP ${n(edition.apNumber)}`
        } else {
          return `AP ${n(edition.apNumber)}, Edition of ${n(edition.volume)}`
        }
      } else {
        return `AP ${n(edition.apNumber)}`
      }
    } else {
      if (edition.volume) {
        if (short) {
          return `AP ${n(edition.apNumber)}/${n(edition.apVolume)}`
        } else {
          return `AP ${n(edition.apNumber)}/${n(
            edition.apVolume
          )}, Edition of ${n(edition.volume)}`
        }
      } else {
        return `AP ${n(edition.apNumber)}/${n(edition.apVolume)}`
      }
    }
  }

  // Unnumbered AP
  if (edition.apNumber && edition.apNumber == -1) {
    if (!edition.apVolume) {
      if (edition.volume) {
        if (short) {
          return `AP`
        } else {
          return `AP, Edition of ${n(edition.volume)}`
        }
      } else {
        return `AP`
      }
    } else {
      if (edition.volume) {
        if (short) {
          return `AP of ${n(edition.apVolume)}`
        } else {
          return `AP of ${n(edition.apVolume)}, Edition of ${n(edition.volume)}`
        }
      } else {
        return `AP of ${n(edition.apVolume)}`
      }
    }
  }

  throw Error("Don't know how to render edition string")
}

export function formatProductionYearsFromValues(
  productionYear: number,
  productionYearEnd?: number | null
): string {
  if (productionYearEnd) {
    return `${productionYear} - ${productionYearEnd}`
  }
  return productionYear.toString()
}

export function showProductionYears(
  productionYears: ProductionYears | null
): boolean {
  return productionYears ? productionYears.productionYear !== 0 : false
}

export function formatProductionYearsOrNull(
  productionYears: ProductionYears | null,
  showComma = false
): string | null {
  if (!showProductionYears(productionYears)) {
    return null
  }
  return productionYears
    ? `${showComma ? ', ' : ''} ${formatProductionYears(productionYears)}`
    : null
}

export function formatProductionYears(
  productionYears: ProductionYears
): string {
  return formatProductionYearsFromValues(
    productionYears.productionYear,
    productionYears.productionYearEnd
  )
}

export function formatArtistName(name: ArtistName): string {
  if (name?.lastName) {
    return `${name.firstName} ${name.lastName}`
  }
  return name?.firstName
}

export function capitalizeFirstLetter(value: string) {
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const certificateType = (confidenceLevel: ComputedConfidenceLevel) => {
  if (confidenceLevel === ComputedConfidenceLevel['UNVERIFIED_RECORD']) {
    return t('certificateTypes.UNVERIFIED_RECORD')
  } else if (confidenceLevel === ComputedConfidenceLevel['ISSUER_RECORD']) {
    return t('certificateTypes.ISSUER_RECORD')
  } else if (confidenceLevel === ComputedConfidenceLevel['COA']) {
    return t('certificateTypes.COA')
  } else if (confidenceLevel === ComputedConfidenceLevel['COA_PLUS']) {
    return t('certificateTypes.COA_PLUS')
  }

  return t('certificateTypes.UNVERIFIED_RECORD')
}

export function truncateString(
  str: string,
  firstCharCount: number,
  endCharCount: number
): string {
  if (str.length <= firstCharCount + endCharCount) return str

  let convertedStr = ''
  convertedStr += str.substring(0, firstCharCount)
  convertedStr += '…'
  convertedStr += str.substring(str.length - endCharCount, str.length)
  return convertedStr
}

export function truncateEthereumAddress(str: string): string {
  return truncateString(str, 6, 4)
}

export const presentBlockchainDate = (isoDateTime: string): string =>
  DateTime.fromISO(isoDateTime)
    .setZone('utc')
    .toFormat('LLL d yyyy HH:mm:ss ZZZZ')

export function formatArtistDetails(artist: ArtistAttributes): string {
  const name = formatArtistName(artist.name)
  const birth = artist.yearOfBirth ? ` (${artist.yearOfBirth})` : ''
  const alternativeNames =
    artist.alternativeNames.length > 0
      ? ` (${artist.alternativeNames.map(formatArtistName).join()})`
      : ''
  return `${name}${birth}${alternativeNames}`
}

export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const blockchainName = (blockchain: Blockchain) => {
  return t(`blockchainShortName.${blockchain}`)
}

export function formatObjectType(
  objectType: ObjectType | undefined
): string | null {
  if (!objectType) return null

  return objectType === 'NFT'
    ? objectType
    : capitalizeFirstLetter(objectType.replace('_', ' '))
}
