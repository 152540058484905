import React, { useState } from 'react'
import * as API from '../../../api'
import Heading from '../../atoms/Heading'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import { useForm } from 'react-hook-form'
import t from '../../../lib/translation'
import { SessionEmail } from './LoginRequestCode'

type FormTypes = {
  sixDigitCode: string
}

type LoginEnterCodeProps = {
  loginCodeSession: SessionEmail
  sendAnotherCode: () => void
}

const LoginEnterCode: React.FC<LoginEnterCodeProps> = ({
  loginCodeSession,
  sendAnotherCode,
}) => {
  const { register, handleSubmit } = useForm<FormTypes>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const doLogin = handleSubmit(async ({ sixDigitCode }) => {
    try {
      setError(undefined)
      setLoading(true)
      await API.customer.completeLoginCode(
        loginCodeSession.session,
        sixDigitCode
      )

      // Nothing further is required here. If the login completes then API.customer will fire an event
      // which will be handled elsewhere.
    } catch (e: any) {
      if (e?.response?.data?.message === 'code.not.valid') {
        setError(t.login.submitCode.codeNotValid)
      } else {
        setError(t.login.submitCode.error)
      }
    } finally {
      setLoading(false)
    }
  })

  return (
    <form
      onSubmit={doLogin}
      className="ver-w-120 ver-flex ver-flex-col ver-justify-center ver-items-center ver-space-y-8"
    >
      <Heading variant="heading-1">{t.login.submitCode.title}</Heading>
      <Text>{`${t.login.submitCode.subtitle} ${loginCodeSession.email}`}</Text>
      <Input
        fullWidth
        type="text"
        pattern={'\\d{6}'}
        ref={register()}
        name="sixDigitCode"
        placeholder={t.login.submitCode.inputPlaceholder}
      />
      {error && (
        <Text variant="small">
          <span className="ver-text-error">{error}</span>
        </Text>
      )}
      <Button fullWidth loading={loading} type="submit" secondary>
        {t.login.submitCode.button}
      </Button>
      <a onClick={sendAnotherCode} className="ver-underline">
        <Text>{t.login.submitCode.sendAnotherCode}</Text>
      </a>
    </form>
  )
}

export default LoginEnterCode
