import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import * as API from '../api'
import EmptyLayout from './EmptyLayout'
import { Spinner } from '@verisart/shared'
import LoginFlow from '../components/organisms/Login/LoginFlow'
import assert from 'assert'
import { ShopifyCustomerAPIOptions } from '../api'

const apiConfiguration = (): ShopifyCustomerAPIOptions => {
  const shopDomain = window.verisartAppConfig?.shopDomain
  const customerId = window.verisartAppConfig?.customerId
  const customerSecret = window.verisartAppConfig?.customerSecret

  assert(shopDomain, 'shopDomain is required')

  return {
    shopDomain,
    customerId,
    customerSecret,
    baseUrl: process.env.REACT_APP_VERISART_API_URL ?? '',
  }
}

const RootLayout = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [initializing, setInitializing] = useState(true)
  const [hasSession, setHasSession] = useState(false)
  const [hasDoneInit, setHasDoneInit] = useState(false)
  const [email, setEmail] = useState<string | null>(null)

  const config = useMemo(() => apiConfiguration(), [])

  useEffect(() => {
    const go = async () => {
      // If store not minting activated redirect to homepage
      if (
        process.env.NODE_ENV === 'production' &&
        window.verisartAppConfig?.dashboardDisabled === 'true'
      ) {
        window.location.href = '/'
        return
      }

      // This is needed because we're changing the search params via setSearchParams().
      // Doing so would cause a re-running of this useEffect() because of our dependency on
      // searchParams. We use this state to skip the second render
      if (hasDoneInit) {
        return
      }
      setHasDoneInit(true)

      assert(config.shopDomain, 'shopDomain is required')
      API.initializeShopifyCustomerAPI(config)

      // Register to be notified about session expiry
      API.customer.registerSessionStatusCallback(setHasSession)

      // Get the auth code (note we remove it from the URL for security reasons (e.g. prevent a user accidentally copying and pasting))
      const authCode = searchParams.get('authCode')
      searchParams.delete('authCode')
      const email = searchParams.get('email')
      searchParams.delete('email')
      if (email) {
        setEmail(decodeURIComponent(email))
      }
      setSearchParams(searchParams, { replace: true })
      // Try to refresh the token from the authCode query param
      if (authCode) {
        await API.customer.tryRefreshTokenFromMagicLinkCode(authCode)
      }

      // Fallback to refreshing from customer secret
      await API.customer.tryRefreshSessionFromCustomerSecret()

      setHasSession(API.customer.hasSession())

      try {
        // Workaround for VER-6985: The shopify admin bar (something Shopify injects into all pages when you
        // are logged in as the merchant of a store) seems to be broken on our customer dashboard pages
        // and appears at the top left, blocking real items. This is a hack to hide it.
        const element = document.getElementById('admin-bar-iframe')
        // Note we're being slightly defensive by only doing if `bottom: 0` is missing from the style.
        // This is based on the current implementation of the admin bar, but could change in the future.
        if (
          element &&
          !element.attributes['style']?.value?.includes('bottom: 0')
        ) {
          element.attributes['style'].value = 'display: none;'
        }
      } catch (e) {
        // Nothing
      }

      setInitializing(false)
    }
    go()
  }, [config, hasDoneInit, searchParams, setSearchParams])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  if (!config.shopDomain) {
    return <p>Error: No shop specified!</p>
  }

  if (initializing) {
    return (
      <EmptyLayout>
        <div className="ver-w-full ver-text-center">
          <Spinner />
        </div>
      </EmptyLayout>
    )
  }

  if (!hasSession) {
    return <LoginFlow email={email} />
  }

  return <Outlet />
}

export default RootLayout
