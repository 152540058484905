import copyTextToClipboard from 'copy-text-to-clipboard'
import React from 'react'
import { useCallback, useState } from 'react'
import { ReactComponent as IcoCopy } from './ico-copy.svg'
import { ReactComponent as IcoCopyTick } from './ico-copy-tick.svg'
import globalStyle from '../../globals.module.css'
import t from '../../../lib/translation'

interface CopyFieldProps {
  value: string
  copiedConfirmation?: string
}

const CopyField: React.FC<CopyFieldProps> = ({
  children,
  value,
  copiedConfirmation,
}) => {
  const [copied, setCopied] = useState(false)
  const handleCopy = useCallback(() => {
    if (value) {
      setCopied(copyTextToClipboard(value))
    }
  }, [value])

  return (
    <button className="ver-flex ver-items-center" onClick={handleCopy}>
      {children}
      <span className="ver-ml-2">
        {copied ? (
          <>
            <IcoCopyTick className={'ver-inline'} />
            <p className={'ver-inline ver-ml-2'}>{copiedConfirmation}</p>
          </>
        ) : (
          <div className={`${globalStyle['tooltip']} ver-block-inline`}>
            <IcoCopy />
            <span
              className={`${globalStyle['tooltiptext']} ver-bg-outline ver-text-sm`}
            >
              {t.utility.copy.title}
            </span>
          </div>
        )}
      </span>
    </button>
  )
}

export default CopyField
