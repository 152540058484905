import config from '../lib/config'
import React, { useEffect } from 'react'
import { Spinner } from '@verisart/shared'
import * as API from '../api'
import { useParams } from 'react-router-dom'
import EmptyLayout from '../layouts/EmptyLayout'

export async function getAddOnRedirectLink(
  certificateId: string
): Promise<string> {
  const token = await API.customer.getPurchaseToken()
  return `${config.verisartBaseUrl}/works/${certificateId}?purchaseToken=${token}&showAddOn=true`
}

const AddOnRedirect: React.FC = () => {
  const { id: certificateId } = useParams<'id'>()
  useEffect(() => {
    ;(async () => {
      if (certificateId) {
        window.location.href = await getAddOnRedirectLink(certificateId)
      }
    })()
  }, [certificateId])

  return (
    <EmptyLayout>
      <div className="ver-w-full ver-text-center">
        <Spinner />
      </div>
    </EmptyLayout>
  )
}

export default AddOnRedirect
