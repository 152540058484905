import Text from './Text'
import ShortAddressViewer from './ShortAddressViewer'
import { VersionType } from '../organisms/Claim'
import t from '../../lib/translation'
import { Blockchain, scannerBaseUrl } from '@verisart/nft/src'
import { ReactComponent as IconExternalLink } from '../../icons/ico-external-link.svg'

interface VersionLineProps {
  address: string
  timestamp: string
  blockchain: Blockchain
  type: VersionType
  sameOwner?: boolean
  transactionId?: string | null
}

const VersionLine: React.FC<VersionLineProps> = ({
  address,
  timestamp,
  blockchain,
  type,
  sameOwner = false,
  transactionId,
}) => {
  const format = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
    timeStyle: undefined,
  })

  const transactionLink = (blockchain: Blockchain, transactionId: string) => {
    return scannerBaseUrl(blockchain) + `/tx/${transactionId}`
  }

  const text = type === 'CREATE' ? t.works.create : t.works.transfer
  const connector = sameOwner || type === 'TRANSFER' ? 'to' : 'by'

  return (
    <li className={'ver-flex ver-whitespace-pre-wrap'}>
      <Text variant="large">
        <span className="var-whitespace-nowrap">
          {`${text} `}
          {format.format(Date.parse(timestamp))}
          {` ${connector} `}
        </span>
        <ShortAddressViewer address={address} blockchain={blockchain} />
        {transactionId && (
          <div className="ver-inline-block ver-ml-2 ver-cursor-pointer">
            <a href={transactionLink(blockchain, transactionId)}>
              <IconExternalLink />
            </a>
          </div>
        )}
      </Text>
    </li>
  )
}

export default VersionLine
