import { Action as NavigationType } from 'history'
import { useCallback } from 'react'
import { useNavigate, useNavigationType } from 'react-router-dom'

/**
 * Attempts to pop back from the router navigation stack.
 *
 * If there is nothing in the navigation stack then the fallback path is used as the next path to navigate to.
 */
export const useNavigateBack = () => {
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  return useCallback(
    (fallback: string) => {
      if (navigationType === NavigationType.Push) {
        navigate(-1)
      } else {
        navigate(fallback, { replace: true })
      }
    },
    [navigate, navigationType]
  )
}
