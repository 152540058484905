import { generatePath } from 'react-router-dom'

/**
 * Note this is a super old API for "custodial NFTs" - things we ony had for a short period of time.
 * See https://www.notion.so/verisart/Shopify-Custodial-NFT-feature-should-be-retired-da038363d6894fd1a0ed931c617e2dd3
 * @deprecated
 */
export const claimPath = `/claim/:id`

export const mintPath = `/mint/:id`

export const worksPath = `/works/:id`
export const testWalletPath = `/testWallet`

export const addOnPath = `/addOn/:id`

export const generateClaimPath = (id: string) => generatePath(claimPath, { id })

export const generateMintPath = (id: string) => generatePath(mintPath, { id })

export const generateWorksPath = (id: string) => generatePath(worksPath, { id })
