import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.875 0a4.71 4.71 0 0 0-2.793.902A5.126 5.126 0 0 0 2.32 3.18l1.268.515A3.738 3.738 0 0 1 4.877 2.02a3.36 3.36 0 0 1 1.998-.645c.616 0 1.189.158 1.719.473.53.3.945.716 1.246 1.246.315.53.473 1.103.473 1.719v2.062H0v11h13.75v-11h-2.063V4.812c0-.859-.221-1.654-.665-2.384A4.727 4.727 0 0 0 9.26.666 4.507 4.507 0 0 0 6.875 0Z"
      fill="#000"
    />
  </svg>
)

export default SvgComponent
