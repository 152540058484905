import Text from './Text'
import { Blockchain, scannerBaseUrl } from '@verisart/nft/src'

interface ShortAddressViewerProps {
  address: string
  blockchain: Blockchain
}

const ShortAddressViewer: React.FC<ShortAddressViewerProps> = ({
  address,
  blockchain,
}) => {
  const addressStart = address.substr(0, 5)
  const addressEnd = address.substr(address.length - 4)
  const suffix = `/address/${address}`
  return (
    <a
      target={'_blank'}
      rel="noreferrer"
      href={`${scannerBaseUrl(blockchain) + suffix}`}
    >
      <Text bold variant="large">
        {addressStart}...{addressEnd}
      </Text>
    </a>
  )
}

export default ShortAddressViewer
