import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.875 0c-.86 0-1.661.222-2.406.666a4.944 4.944 0 0 0-1.762 1.762 4.62 4.62 0 0 0-.644 2.385v2.062H0v11h13.75v-11h-2.063V4.812c0-.859-.221-1.654-.665-2.384A4.727 4.727 0 0 0 9.26.666 4.507 4.507 0 0 0 6.875 0Zm0 1.375c.616 0 1.189.158 1.719.473.53.3.945.716 1.246 1.246.315.53.473 1.103.473 1.719v2.062H3.436V4.812c0-.615.15-1.188.452-1.718.315-.53.737-.946 1.267-1.246a3.304 3.304 0 0 1 1.719-.473Z"
      fill="#7514D7"
    />
  </svg>
)

export default SvgComponent
