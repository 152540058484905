import React, { useState } from 'react'
import * as API from '../../../api'
import Heading from '../../atoms/Heading'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import { useForm } from 'react-hook-form'
import Input from '../../atoms/Input'
import t from '../../../lib/translation'

type FormTypes = {
  email: string
}

export type SessionEmail = {
  session: string
  email: string
}

type LoginRequestCodeProps = {
  setLoginCodeSession: (session: SessionEmail) => void
  email: string | null
}

const LoginRequestCode: React.FC<LoginRequestCodeProps> = ({
  setLoginCodeSession,
  email,
}) => {
  const { register, handleSubmit } = useForm<FormTypes>({
    defaultValues: { email: email || undefined },
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const doRequestCode = handleSubmit(async ({ email }) => {
    try {
      setError(undefined)
      setLoading(true)
      setLoginCodeSession({
        email: email,
        session: await API.customer.requestLoginCode(email),
      })
    } catch (e: any) {
      if (e.response?.data?.message === 'too.many.requests') {
        setError(t.login.requestCode.tooManyRequests)
      } else if (e.response?.data?.message === 'too.many.attempts') {
        setError(t.login.requestCode.tooManyAttempts)
      } else if (e.response?.data?.message === 'user.not.found') {
        setError(t.login.requestCode.unknownUser)
      } else {
        setError(t.login.requestCode.error)
      }
    } finally {
      setLoading(false)
    }
  })

  return (
    <form
      onSubmit={doRequestCode}
      className="ver-w-120 ver-flex ver-flex-col ver-justify-center ver-items-center ver-space-y-8"
    >
      <Heading variant="heading-1">{t.login.requestCode.title}</Heading>
      <div className="ver-text-center">
        <Text>{t.login.requestCode.subtitle}</Text>
      </div>
      <Input
        fullWidth={true}
        type="email"
        ref={register()}
        name="email"
        placeholder={t.login.requestCode.inputPlaceholder}
      />
      {error && (
        <Text variant="small">
          <span className="ver-text-error">{error}</span>
        </Text>
      )}
      <Button fullWidth loading={loading} type="submit" secondary>
        {t.login.requestCode.button}
      </Button>
    </form>
  )
}

export default LoginRequestCode
