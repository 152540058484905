import React from 'react'
import { TranslationProxy } from './translationProxy'
import merge from 'lodash.merge'

const placeholderRegex = /({[\d|\w]+})/

export const formatString = (
  value: string,
  ...values: Array<React.ReactChild>
): React.ReactNode[] | string => {
  let hasObject = false
  const result = value
    .split(placeholderRegex)
    .filter((textPart: string) => !!textPart)
    .map((textPart: string, index: number) => {
      if (textPart.match(placeholderRegex)) {
        const matchedKey = parseInt(textPart.slice(1, -1), 10)
        let valueForPlaceholder: React.ReactChild = values[matchedKey]

        if (React.isValidElement(valueForPlaceholder)) {
          hasObject = true
          valueForPlaceholder = {
            ...valueForPlaceholder,
            key: index.toString(),
          }
          return React.Children.toArray(valueForPlaceholder)
        }

        return valueForPlaceholder
      }
      return textPart
    })

  if (hasObject) return result
  return result.join('')
}

const translationSource = document.getElementById(
  'verisart-shopify-customer-strings'
)
if (!translationSource?.innerText) {
  throw new Error('No translations found')
}

//This is a copy from the logic in token-gate project
const extractJsonString = (element: HTMLElement) => {
  let source: string
  if (element.dataset['format'] === 'shopify-t-json') {
    // The Shopify localisation string arrives formatted like a ruby hash which is not parsable JSON.
    // `{{ 'verisart_customer_dashboard' | t }}` outputs the value of the object 'app' as an escaped string of the ruby hash meaning that
    // the `json` filter turns this into a string.
    // To handle this load the string as JSON, load that parsed string into a textarea to unescape it and replace
    // the arrows in the resulting source with `:` now the string can be parsed as an actual object.
    const decoder = document.createElement('textarea')
    decoder.innerHTML =
      element.innerText.startsWith('"') && element.innerText.endsWith('"')
        ? JSON.stringify(element.innerText)
        : element.innerText
    const matches = new Set<string>()
    // Match the following patterns indicating a key value separator
    // `"=>"`, `"=>[` or `"=>{`, these will be replaced with `":"`, `":[` or `":{` as required
    for (const match of decoder.value.matchAll(/"(=>)[[{"]/g)) {
      matches.add(match[0])
    }
    source = decoder.value
    matches.forEach((match) => {
      source = source?.replaceAll(match, match.replace('=>', ':'))
    })
    decoder.remove()
  } else {
    source = element.innerText
  }
  return source
}

const overrideSources: Record<string, any>[] = []
const element = document.querySelector(
  "[data-target='verisart-customer-dashboard']"
)
if (element && element instanceof HTMLScriptElement) {
  const overrideSource = extractJsonString(element)
  try {
    if (overrideSource && !overrideSource.includes('Translation missing')) {
      overrideSources.push(JSON.parse(overrideSource))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error ', e)
  }
}

let translations: TranslationProxy
if (overrideSources.length > 0) {
  try {
    // Merge the override sources into the extension's translation source and create the proxy
    translations = TranslationProxy.Create(
      merge(JSON.parse(translationSource.innerText), ...overrideSources)
    )
    // eslint-disable-next-line no-console
    console.info(
      '[verisart-customer-dashboard]',
      'extension translations overridden'
    )
  } catch (e) {
    console.error(e)
    console.error(
      '[verisart-customer-dashboard]',
      'failed to merge override translation sources, see error above for more details'
    )
    translations = TranslationProxy.Parse(translationSource.innerText)
  }
} else {
  // If no override sources are found then parse the source string and create the proxy
  translations = TranslationProxy.Parse(translationSource.innerText)
  // eslint-disable-next-line no-console
  console.info('[verisart-customer-dashboard]', 'translations loaded')
}

export default translations
