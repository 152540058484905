import React from 'react'

import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { polygon, sepolia, base } from 'viem/chains'
import { UniversalWalletConnector } from '@magiclabs/wagmi-connector'

let config: any

/**
 * Called to init wagmi.
 *
 * This code lives in a helper library and each parent app has a different way of injecting config hence
 * this function is called from the parent app.
 *
 * You MUST call this before <ConnectWalletContextProvider> is rendered.
 */
export const initWagmi = (
  alchemyAPIKey: string,
  walletconnectProjectId: string,
  magicLinkKey: string | null
) => {
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet, polygon, sepolia, base],
    [alchemyProvider({ apiKey: alchemyAPIKey }), publicProvider()]
  )

  const universalWalletConnector = magicLinkKey
    ? new UniversalWalletConnector({
        chains: [mainnet, polygon, sepolia, base],
        options: {
          apiKey: magicLinkKey,
          networks: [
            {
              rpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/' + alchemyAPIKey,
              chainId: 1,
            },
            {
              rpcUrl: 'https://eth-sepolia.g.alchemy.com/v2/' + alchemyAPIKey,
              chainId: 11155111,
            },
            {
              rpcUrl:
                'https://polygon-mainnet.g.alchemy.com/v2/' + alchemyAPIKey,
              chainId: 137,
            },
            {
              rpcUrl: 'https://base-mainnet.g.alchemy.com/v2/' + alchemyAPIKey,
              chainId: 8453,
            },
          ],
        },
      })
    : undefined

  config = createConfig({
    autoConnect: false,
    connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'Verisart',
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: walletconnectProjectId,
          qrModalOptions: {
            themeVariables: {
              // Ensure the modal appears above our modal
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--w3m-z-index': '9999999',

              // So weirdly, the TS bindings have the new CSS variable name (see above)
              // but for some reason this wasn't working yet. We're setting the old one here too to be safe.
              '--wcm-z-index': '9999999',
            },
          },
        },
      }),
      ...(universalWalletConnector ? [universalWalletConnector] : []),
    ],
    publicClient,
    webSocketPublicClient,
  })
}

const ConnectWalletContextProvider: React.FC = ({ children }) => {
  return <WagmiConfig config={config}>{children}</WagmiConfig>
}

export default ConnectWalletContextProvider
