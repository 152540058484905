import * as React from 'react'

const SvgComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8749 19.504C10.6353 19.504 10.402 19.4271 10.2093 19.2846C10.1986 19.2772 10.1883 19.2692 10.1784 19.2607L5.89494 15.754H2.62494C2.32657 15.754 2.04042 15.6355 1.82944 15.4245C1.61847 15.2135 1.49994 14.9274 1.49994 14.629V9.379C1.49994 9.08063 1.61847 8.79448 1.82944 8.5835C2.04042 8.37252 2.32657 8.254 2.62494 8.254H5.89447L10.1774 4.74728C10.1873 4.73879 10.1977 4.73082 10.2084 4.72337C10.3759 4.60015 10.5743 4.52577 10.7816 4.50852C10.9889 4.49126 11.1969 4.5318 11.3825 4.62562C11.5681 4.71945 11.724 4.86289 11.8331 5.04002C11.9421 5.21714 11.9998 5.42102 11.9999 5.629V18.379C11.9999 18.6774 11.8814 18.9635 11.6704 19.1745C11.4595 19.3855 11.1733 19.504 10.8749 19.504ZM14.9999 15.754C14.872 15.754 14.7461 15.7212 14.6344 15.6588C14.5226 15.5964 14.4287 15.5064 14.3616 15.3975C14.2945 15.2885 14.2563 15.1642 14.2508 15.0363C14.2453 14.9084 14.2726 14.7813 14.3301 14.667C14.7749 13.7824 15.0004 12.8857 15.0004 12.004C15.0004 11.0956 14.782 10.2256 14.3324 9.34478C14.2463 9.1681 14.233 8.96468 14.2953 8.77827C14.3577 8.59187 14.4907 8.43739 14.6658 8.34806C14.8409 8.25874 15.044 8.24172 15.2315 8.30066C15.419 8.3596 15.5759 8.48979 15.6684 8.66322C16.2276 9.75962 16.4999 10.8527 16.4999 12.004C16.4999 13.1224 16.2187 14.2451 15.6703 15.341C15.6078 15.4652 15.512 15.5696 15.3936 15.6425C15.2753 15.7155 15.139 15.7541 14.9999 15.754Z"
      fill="white"
    />
    <path
      d="M17.25 18.0015C17.1183 18.0015 16.989 17.9669 16.8749 17.9011C16.7609 17.8353 16.6662 17.7406 16.6003 17.6266C16.5345 17.5126 16.4998 17.3832 16.4998 17.2516C16.4998 17.1199 16.5345 16.9905 16.6003 16.8765C17.4867 15.3339 18 14.0411 18 12.0015C18 9.93105 17.4876 8.64714 16.6022 7.12933C16.5051 6.95769 16.4795 6.7547 16.5309 6.56433C16.5824 6.37395 16.7067 6.2115 16.8771 6.11214C17.0474 6.01279 17.2501 5.98453 17.4411 6.03349C17.6322 6.08244 17.7962 6.20467 17.8978 6.37371C18.8869 8.06917 19.5 9.60199 19.5 12.0015C19.5 14.3654 18.8878 15.9057 17.9001 17.6265C17.8343 17.7406 17.7395 17.8354 17.6253 17.9012C17.5112 17.967 17.3817 18.0016 17.25 18.0015V18.0015Z"
      fill="white"
    />
    <path
      d="M19.5 20.2525C19.3649 20.2524 19.2324 20.2158 19.1164 20.1467C19.0004 20.0775 18.9052 19.9783 18.8409 19.8595C18.7767 19.7407 18.7456 19.6068 18.7512 19.4719C18.7567 19.3369 18.7985 19.206 18.8723 19.0928C20.1492 17.1339 21 15.1788 21 12.0025C21 8.88533 20.1478 6.91095 18.8676 4.90611C18.8146 4.823 18.7785 4.73028 18.7613 4.63322C18.7442 4.53616 18.7463 4.43667 18.7676 4.34043C18.7889 4.24419 18.8289 4.15309 18.8854 4.07232C18.9419 3.99155 19.0137 3.9227 19.0968 3.8697C19.1799 3.8167 19.2727 3.78059 19.3697 3.76342C19.4668 3.74626 19.5663 3.74838 19.6625 3.76966C19.7588 3.79095 19.8499 3.83098 19.9306 3.88747C20.0114 3.94397 20.0802 4.01582 20.1333 4.09892C21.5536 6.32548 22.5 8.52158 22.5 12.0025C22.5 15.0377 21.8128 17.3289 20.1281 19.9122C20.06 20.0167 19.9668 20.1025 19.8572 20.162C19.7475 20.2214 19.6247 20.2525 19.5 20.2525V20.2525Z"
      fill="white"
    />
  </svg>
)

export default SvgComponent
