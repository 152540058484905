import { MouseEventHandler } from 'react'
import { ReactComponent as IconBack } from '../../icons/ico-back.svg'
import t from '../../lib/translation'

interface BackButtonProps {
  onClick: MouseEventHandler
}

const BackButton: React.VFC<BackButtonProps> = ({ onClick }) => {
  return (
    <button
      className="ver-flex ver-items-center ver-justify-center ver-w-11 ver-h-11 ver-border ver-border-disabled ver-rounded-full"
      onClick={onClick}
    >
      <IconBack />
      <span className="ver-sr-only">{t.utility.back.title}</span>
    </button>
  )
}

export default BackButton
