import type { Axios } from 'axios'

export interface BlockchainClaimChallenge {
  challenge: string
  accountCommitment: string
}

export interface SignedBlockchainClaimChallenge {
  accountCommitment: string
  address: string
  challenge: string
  signature: string
  /**
   * Whether to force the claim, even if the address is already claimed by another account.
   */
  moveExisting: boolean
}
export enum ClaimPlatform {
  WEBSITE = 'WEBSITE',
  CUSTOMER_DASHBOARD = 'CUSTOMER_DASHBOARD',
}

export async function createClaimChallenge(
  webClient: Axios,
  address: string,
  claimPlatform: ClaimPlatform,
  shopDomain: string | null
): Promise<BlockchainClaimChallenge | null> {
  const res = await webClient.post('blockchainAddress/claim', {
    address,
    claimPlatform,
    shopDomain,
  })

  if (res.status === 202) {
    return null
  }

  return res.data
}

export async function completeClaimChallenge(
  webClient: Axios,
  signedChallenge: SignedBlockchainClaimChallenge
): Promise<void> {
  try {
    await webClient.post('blockchainAddress/claim/complete', signedChallenge)
  } catch (e) {
    throw {
      signedChallenge: signedChallenge,
      wrappedError: e,
    }
  }
}

export function isWrappedErrorWithSignature(
  e: any
): e is { signedChallenge: SignedBlockchainClaimChallenge; wrappedError: any } {
  return e.signedChallenge !== undefined && e.wrappedError !== undefined
}
