import { ReactComponent as TickIcon } from './ico-tick.svg'
import { ReactComponent as TickHourglass } from './ico-hourglass.svg'

type CardTagProps = {
  status: Status
}

type Status = 'success' | 'pending'

const CardTag: React.FC<CardTagProps> = ({ children, status }) => {
  return (
    <div
      data-test="claimedMessage"
      className="ver-flex ver-flex-row ver-items-center ver-px-1 ver-bg-outline ver-text-secondary ver-uppercase"
    >
      {status === 'success' && <TickIcon />}
      {status === 'pending' && <TickHourglass />}
      <div className="ver-ml-1">{children}</div>
    </div>
  )
}

export default CardTag
