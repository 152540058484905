import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavigateBack } from 'src/lib/hooks/useNavigateBack'
import BackButton from '../atoms/BackButton'
import { Spinner } from '@verisart/shared'
import Notification from '../molecules/Notification'
import t, { formatString } from '../../lib/translation'
import * as API from '../../api'
import {
  ConnectorNames,
  useConnectWallet,
  isConnectWalletError,
  useGetAccountBlockchainAddresses,
  Blockchain,
} from '@verisart/nft/src'
import { ensureBlockchain } from '@verisart/nft/src/useConnectWallet'
import { truncateEthereumAddress } from '@verisart/shared'
import { ReactComponent as MetaMask } from '../../icons/ico-metamask.svg'
import { ReactComponent as WalletConnect } from '../../icons/ico-walletconnect-logo.svg'
import { ReactComponent as CoinbaseIcon } from '../../icons/ico-coinbase.svg'
import { ReactComponent as MagicLink } from '../../icons/ico_magic.svg'
import { ReactComponent as IconArrowDown } from '../../icons/ico-arrow-down.svg'
import { ReactComponent as IconWallet } from '../../icons/ico-wallet.svg'
import { ReactComponent as IconLogout } from '../../icons/ico-logout.svg'
import Popover from 'react-popover'
import ConnectWalletDialog, {
  useConnectWalletReducer,
} from './ConnectWalletDialog'
import Button from '../atoms/Button'
import { getAddOnRedirectLink } from 'src/pages/AddOnRedirect'

interface TopBarProps {
  showBackButton?: boolean
  showAddOn?: boolean
  blockchain?: Blockchain
}

const TopBar: React.VFC<TopBarProps> = ({
  showBackButton,
  showAddOn,
  blockchain,
}) => {
  const navigateBack = useNavigateBack()
  const navigate = useNavigate()
  const [state, dispatch] = useConnectWalletReducer()
  const {
    showMagicWallet,
    disconnect,
    account: activeWallet,
    platform,
  } = useConnectWallet(API.customer.axios)

  const connecting = state.working
  const connectError =
    state.name === 'Failure' && state.connectError
      ? isConnectWalletError(state.connectError)
        ? state.connectError?.message
        : state.connectError
      : undefined

  const { data: accountData } = useGetAccountBlockchainAddresses(
    API.customer.axios
  )
  const linkedWalletAddresses =
    accountData?.blockchainAddresses.map((addressObj) => addressObj.address) ||
    []
  const accountEmail = accountData?.realUserEmail

  const [isWalletPopoverOpen, setWalletPopoverOpen] = useState(false)
  const [isAccountPopoverOpen, setAccountPopoverOpen] = useState(false)
  const [isLinkedWalletsOpen, setLinkedWalletsOpen] = useState(false)
  const [isMagicWalletLoading, setIsMagicWalletLoading] = useState(false)

  const platformIcons = {
    Injected: <MetaMask />,
    WalletConnect: <WalletConnect />,
    Coinbase: <CoinbaseIcon />,
    MagicLink: <MagicLink />,
  }

  const { id } = useParams<'id'>()

  return (
    <>
      <div className="ver-py-4 ver-px-7 ver-flex">
        {!showBackButton && (
          <BackButton
            onClick={(event) => {
              event.preventDefault()
              navigateBack('/')
            }}
          />
        )}

        <span className="ver-flex ver-flex-wrap-reverse ver-w-full ver-items-center ver-justify-end ver-gap-4">
          <span className="ver-flex ver-w-40 ver-items-center ver-justify-center">
            {showAddOn && (
              <div className="ver-w-44">
                <Button
                  fullWidth
                  onClick={async () =>
                    window.open(await getAddOnRedirectLink(id as string))
                  }
                >
                  {t.works.buyAddOn}
                </Button>
              </div>
            )}
          </span>

          <span className="ver-flex ver-w-44 ver-items-center ver-justify-center">
            {connecting ? (
              <Spinner />
            ) : (
              <>
                {!activeWallet && (
                  <button
                    className="ver-flex ver-items-center ver-gap-4"
                    type="button"
                    onClick={() => {
                      dispatch({ type: 'ChoosePlatform' })
                    }}
                  >
                    <IconWallet />
                    <span className="ver-text-base hover:ver-underline ver-underline-offset-4">
                      {t.topBar.connectWallet}
                    </span>
                  </button>
                )}
              </>
            )}

            {activeWallet && (
              <Popover
                isOpen={isWalletPopoverOpen}
                place="below"
                tipSize={0.01}
                onOuterAction={() => setWalletPopoverOpen(false)}
                style={{ backgroundColor: 'white' }}
                body={
                  <>
                    {platform == ConnectorNames.MagicLink && (
                      <div className="ver-flex ver-justify-center ver-mb-5">
                        {isMagicWalletLoading ? (
                          <Spinner />
                        ) : (
                          <button
                            className="ver-flex ver-items-center ver-gap-4 ver-font-medium hover:ver-underline ver-underline-offset-4"
                            type="button"
                            onClick={async () => {
                              try {
                                setIsMagicWalletLoading(true)
                                if (blockchain) {
                                  await ensureBlockchain(blockchain)
                                }
                                await showMagicWallet?.()
                              } catch (error) {
                                console.error(
                                  "Couldn't show your wallet information: ",
                                  error
                                )
                              } finally {
                                setIsMagicWalletLoading(false)
                              }
                            }}
                          >
                            <IconWallet />
                            {t.topBar.showMagicWallet}
                          </button>
                        )}
                      </div>
                    )}

                    <div>
                      <button
                        className="ver-flex ver-items-center ver-gap-4 ver-font-medium hover:ver-underline ver-underline-offset-4"
                        type="button"
                        onClick={async () => {
                          try {
                            await disconnect()
                            setWalletPopoverOpen(false)
                          } catch (error) {
                            console.error('Error disconnecting wallet: ', error)
                          }
                        }}
                      >
                        <IconLogout />
                        {t.topBar.disconnect}
                      </button>
                    </div>
                  </>
                }
                className="ver-border ver-border-gray-300 ver-py-2 ver-px-6 ver-rounded-md ver-z-20"
              >
                <button
                  className="ver-flex ver-items-center ver-gap-2"
                  type="button"
                  onClick={() => {
                    setWalletPopoverOpen(!isWalletPopoverOpen)
                  }}
                >
                  <span>{platformIcons[platform ?? '']}</span>
                  <span className="ver-text-base ver-font-medium hover:ver-underline ver-underline-offset-4">
                    {truncateEthereumAddress(activeWallet)}
                  </span>
                  <IconArrowDown
                    className={`ver-transform  ${
                      isWalletPopoverOpen ? 'ver-rotate-180' : 'ver-rotate-0'
                    } ver-w-3`}
                  />
                </button>
              </Popover>
            )}
          </span>

          <span className="ver-flex ver-w-10 ver-items-center ver-justify-center">
            {accountData && (
              <Popover
                isOpen={isAccountPopoverOpen}
                place="below"
                tipSize={0.01}
                onOuterAction={() => setAccountPopoverOpen(false)}
                style={{ backgroundColor: 'white' }}
                body={
                  <>
                    <div className="ver-text-base ver-font-semibold">
                      {accountEmail}
                    </div>

                    <div className="ver-mt-4 ver-text-sm">
                      <span
                        className="ver-flex ver-cursor-pointer"
                        onClick={() => {
                          setLinkedWalletsOpen(!isLinkedWalletsOpen)
                        }}
                      >
                        {t.topBar.linkedWallets}
                        <IconArrowDown
                          className={`ver-transform  ${
                            isLinkedWalletsOpen
                              ? 'ver-rotate-180'
                              : 'ver-rotate-0'
                          } ver-ml-2 ver-mt-2 ver-w-3`}
                        />
                      </span>
                      {isLinkedWalletsOpen && (
                        <>
                          {linkedWalletAddresses.map((address, index) => (
                            <div key={index}>
                              {truncateEthereumAddress(address)}
                            </div>
                          ))}
                        </>
                      )}
                    </div>

                    <button
                      className="ver-flex ver-items-center ver-gap-4 ver-mt-5 ver-font-medium hover:ver-underline ver-underline-offset-4"
                      type="button"
                      onClick={() => {
                        API.customer.setSessionToken(null, '')
                        navigate('/')
                      }}
                    >
                      <IconLogout />
                      {t.topBar.logOut}
                    </button>
                  </>
                }
                className="ver-border ver-border-gray-300 ver-py-2 ver-px-6 ver-rounded-md ver-z-20"
              >
                <div
                  onClick={() => {
                    setAccountPopoverOpen(!isAccountPopoverOpen)
                  }}
                  className="ver-h-10 ver-w-10 ver-cursor-pointer ver-rounded-full ver-bg-gradient-to-b ver-from-[#c1c1f7] ver-via-[#fdc862] ver-to-[#bbe9c5]"
                >
                  &nbsp;
                </div>
              </Popover>
            )}
          </span>
        </span>
      </div>

      <div className="ver-flex ver-w-full ver-items-center ver-justify-end">
        {connectError && !activeWallet && (
          <Notification
            title={t.claim.notification.title.connectError}
            body={formatString(connectError)}
            style="error"
            additionalClasses={'ver-w-96 ver-justify-center ver-mb-5 ver-mr-10'}
          />
        )}
      </div>

      <ConnectWalletDialog state={state} dispatch={dispatch} />
    </>
  )
}

export default TopBar
