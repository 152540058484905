import * as API from '../../api'
import { useCallback, useState } from 'react'
import { AxiosError } from 'axios'
import {
  ConnectorNames,
  ensureBlockchain,
} from '@verisart/nft/src/useConnectWallet'
import { Blockchain } from '@verisart/nft/src'

interface ErrorData {
  message?: string
}

const isErrorData = (data: unknown): data is ErrorData => {
  return typeof data === 'object'
}

const useClaimMint = (
  superLazyMintOrderId?: string,
  mintTo?: string,
  blockchain?: Blockchain,
  platform?: ConnectorNames
) => {
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>()
  const [claimed, setClaimed] = useState<boolean>()

  const claimMint = useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      if (superLazyMintOrderId && mintTo) {
        await API.customer.claimMintNft(superLazyMintOrderId, mintTo)
        if (blockchain && platform == ConnectorNames.MagicLink) {
          await ensureBlockchain(blockchain)
        }
        setClaimed(true)
      }
    } catch (e: unknown) {
      const axiosError = e as AxiosError
      if (
        axiosError?.response?.status === 400 &&
        isErrorData(axiosError?.response?.data)
      ) {
        setError(axiosError.response.data.message)
      }
    } finally {
      setLoading(false)
    }
  }, [superLazyMintOrderId, mintTo, blockchain, platform])

  return { claimMint, claimed, error, loading }
}

export default useClaimMint
