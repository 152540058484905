import _ from 'lodash'

export type NoteForm = {
  note: string
}

export type NoteWithPrivate = {
  note: string
  isPrivate: boolean
}

export function flattenNotesRecord(notes: Record<string, string>): string[] {
  return _(notes)
    .toPairs()
    .map(([key, value]) => [parseInt(key), value])
    .sortBy((i) => i[0])
    .value()
    .map((it) => it[1].toString())
}

export function notesRecordsToForm(
  privateNotes: Record<string, string> | undefined | null,
  publicNotes: Record<string, string> | undefined | null
): NoteWithPrivate[] {
  const privateFlat = flattenNotesRecord(privateNotes || {}).map((note) => ({
    note,
    isPrivate: true,
  }))
  const publicFlat = flattenNotesRecord(publicNotes || {}).map((note) => ({
    note,
    isPrivate: false,
  }))
  return publicFlat.concat(privateFlat)
}

export function notesRecordsToFormSimple(
  notes: Record<string, string> | undefined | null
): NoteForm[] {
  return flattenNotesRecord(notes || {}).map((note) => ({ note }))
}

export function formNotesToRecord(
  notes: NoteForm[] | undefined
): Record<string, string> {
  if (!notes) {
    return {}
  }
  return _.fromPairs(
    notes
      .filter((note) => note.note && note.note.trim().length > 0)
      .map((note, idx) => [idx.toString(), note.note])
  )
}
