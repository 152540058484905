import {
  Blockchain,
  ConnectWalletError,
  isConnectWalletError,
  ownerOf721,
  getApproved,
  safeTransferFrom721,
  isApprovedForAll,
  useConnectWalletNoLinking,
  ConnectorNames,
} from '@verisart/nft/src'
import { useCallback, useEffect, useState } from 'react'
import Notification from './Notification'
import ConnectMetamaskDialog from './ConnectMetamaskDialog'
import t, { formatString } from 'src/lib/translation'
import Dialog from '../atoms/Dialog'
import Button from '../atoms/Button'
import { Spinner } from '@verisart/shared'
import Tick from '@verisart/shared/src/icons/tick-black'
import {
  trackNftTransferButtonClicked,
  trackNftTransferCompleted,
  trackNftTransferStarted,
} from 'src/lib/mixpanel'
import SquareButton from '../atoms/SquareButton'
import {
  ensureBlockchain,
  web3FromConnector,
} from '@verisart/nft/src/useConnectWallet'

const TransferNFT: React.FC<{
  blockchain: Blockchain
  contractAddress: string
  tokenId: string
}> = ({ blockchain, contractAddress, tokenId }) => {
  const [showConnectWalletDialog, setShowConnectWalletDialog] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [transferAddress, setTransferAddress] = useState('')
  const [ownerError, setOwnerError] = useState<string | undefined>(undefined)
  const [transferProcessStarted, setTransferProcessStarted] = useState(false)
  const [transferInProgress, setTransferInProgress] = useState(false)
  const [transferComplete, setTransferComplete] = useState(false)
  const [transactionError, setTransactionError] = useState<string | undefined>(
    undefined
  )

  const [connectError, setConnectError] = useState<
    ConnectWalletError | undefined | string
  >(undefined)

  // Note, unusually we're using useConnectWalletNoLinking here instead of useConnectWallet
  // because we don't need to link the wallet to the customer account, we just want to transfer
  const {
    connector,
    connectWalletNoLinking,
    disconnect,
    account: activeWallet,
    platform: p,
  } = useConnectWalletNoLinking()

  const connectWalletNoLinkingWrapper = useCallback(
    async (connectorName: ConnectorNames) => {
      try {
        setConnectError(undefined)
        await connectWalletNoLinking(connectorName, blockchain)
        await ensureBlockchain(blockchain)
        setShowTransferModal(true)
      } catch (e) {
        setConnectError(e)
      }
    },
    [connectWalletNoLinking, blockchain]
  )

  useEffect(() => {
    const checkCanTransfer = async () => {
      try {
        if (showTransferModal && connector && activeWallet) {
          await ensureBlockchain(blockchain)
          const web3 = await web3FromConnector(connector, blockchain)
          const owner = await ownerOf721(web3, contractAddress, tokenId)
          const approvedWallet = await getApproved(
            web3,
            contractAddress,
            tokenId
          )
          const isApproved = await isApprovedForAll(
            web3,
            contractAddress,
            owner,
            activeWallet
          )
          if (
            activeWallet?.toLowerCase() === owner.toLowerCase() ||
            approvedWallet?.toLowerCase() === activeWallet.toLowerCase() ||
            isApproved === true
          ) {
            setOwnerError(undefined)
          } else {
            setOwnerError('Connected Wallet does not own the NFT')
          }
        }
      } catch (e) {
        const error: any = new Error(
          `Error checking if wallet can transfer NFT`
        )
        error.cause = e
        throw error
      }
    }

    checkCanTransfer()
  }, [
    connector,
    contractAddress,
    tokenId,
    activeWallet,
    blockchain,
    connectError,
    showTransferModal,
  ])

  const transferNFT = async () => {
    if (connector && activeWallet) {
      try {
        setTransferProcessStarted(true)
        setTransactionError(undefined)
        await ensureBlockchain(blockchain)
        await safeTransferFrom721(
          await web3FromConnector(connector, blockchain),
          contractAddress,
          tokenId,
          activeWallet,
          transferAddress,
          () => {
            setTransferProcessStarted(false)
            trackNftTransferStarted({
              walletType: p,
              fromAddress: activeWallet,
              toAddress: transferAddress,
            })
            setTransferInProgress(true)
          }
        )
        setTransferInProgress(false)
        setTransferComplete(true)
        trackNftTransferCompleted({
          walletType: p,
          fromAddress: activeWallet,
          toAddress: transferAddress,
        })
      } catch (e) {
        setTransferProcessStarted(false)
        setTransferInProgress(false)
        if (e.message.includes('invalid address')) {
          setTransactionError('Invalid address: ' + transferAddress)
        } else if (e.message.includes('User denied transaction signature')) {
          setTransactionError('User cancelled transaction')
        } else {
          setTransactionError('Please contact support at support@verisart.com')
        }
        throw new Error(e)
      }
    }
  }

  const onHideMetamaskDialog = useCallback(
    () => setShowConnectWalletDialog(false),
    []
  )

  const resetStates = () => {
    setShowTransferModal(false)
    setConnectError(undefined)
    setOwnerError(undefined)
    setTransferAddress('')
    setTransactionError(undefined)
    setTransferInProgress(false)
    setTransferComplete(false)
    setTransferProcessStarted(false)
  }

  return (
    <div>
      <SquareButton
        title={t.works.transferNFT}
        onClick={async () => {
          if (!activeWallet) {
            setShowConnectWalletDialog(true)
          } else {
            try {
              await ensureBlockchain(blockchain)
              setShowTransferModal(true)
              trackNftTransferButtonClicked({
                walletType: p,
                fromAddress: activeWallet,
              })
            } catch (e) {
              setConnectError(e)
            }
          }
        }}
      />
      {showTransferModal && (
        <Dialog
          show={true}
          onDismiss={() => {
            resetStates()
          }}
          showCloseButton
          disableBackgroundClose={true}
        >
          <div className="ver-min-w-[50rem]">
            {transactionError && (
              <div className="ver-pb-4 ver-max-w-5xl">
                <Notification
                  body={transactionError}
                  style="warning"
                  additionalClasses={'ver-mt-5'}
                />
              </div>
            )}
            {connectError && !activeWallet && (
              <div className="ver-pb-4">
                <Notification
                  title={t.claim.notification.title.connectError}
                  body={formatString(
                    isConnectWalletError(connectError)
                      ? connectError.message
                      : connectError
                  )}
                  style="error"
                  additionalClasses={'ver-mt-5'}
                />
              </div>
            )}
            {ownerError && (
              <div className="ver-pb-4 ver-max-w-5xl">
                <Notification
                  body={ownerError}
                  style="error"
                  additionalClasses={'ver-mt-5'}
                />
              </div>
            )}
            {(!ownerError || !connectError) && (
              <>
                <p className="ver-pb-4">{t.works.transferNFTHelpText}</p>
                <div className="ver-items-center">
                  <input
                    disabled={!!ownerError || transferInProgress}
                    type="text"
                    value={transferAddress}
                    pattern={'^0x[a-fA-F0-9]{40}$'}
                    onChange={(e) => setTransferAddress(e.target.value)}
                    placeholder="Wallet Address"
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      padding: '10px',
                    }}
                  />
                </div>
              </>
            )}
            {transferProcessStarted && (
              <>
                <div className="ver-flex ver-pt-4">
                  <Spinner />
                  <p className="ver-pl-5">
                    {t.works.transferNFTProcessStarted}
                  </p>
                </div>
              </>
            )}
            {transferInProgress && (
              <>
                <div className="ver-flex ver-pt-4">
                  <Spinner />
                  <p className="ver-pl-5">{t.works.transferNFTInProgress}</p>
                </div>
              </>
            )}
            {transferComplete && (
              <>
                <div className="ver-flex ver-pt-4">
                  <Tick />
                  <p className="ver-pl-5">{t.works.transferNFTSuccessful}</p>
                </div>
              </>
            )}
            <div className="ver-mt-5 ver-flex ver-flex-col ver-items-center">
              {!ownerError && !transferInProgress && !transferComplete && (
                <Button
                  disabled={transferProcessStarted}
                  fullWidth
                  onClick={() => {
                    if (!activeWallet) {
                      setShowConnectWalletDialog(true)
                    } else {
                      transferNFT()
                    }
                  }}
                >
                  {t.works.transferButton}
                </Button>
              )}

              <button
                className="ver-flex ver-items-center ver-p-2 ver-underline hover:ver-no-underline ver-font-medium"
                type="button"
                onClick={async () => {
                  await disconnect()
                  setShowConnectWalletDialog(true)
                  resetStates()
                }}
              >
                {t.works.disconnectButton}
              </button>
            </div>
          </div>
        </Dialog>
      )}
      <ConnectMetamaskDialog
        show={showConnectWalletDialog}
        hide={onHideMetamaskDialog}
        connectWallet={connectWalletNoLinkingWrapper}
        blockchain={blockchain}
      />
    </div>
  )
}

export default TransferNFT
