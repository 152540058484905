import { DashboardData } from '../../components/organisms/Dashboard'
import * as API from '../../api'
import { useQuery } from 'react-query'
import { generateWorksPath } from '../paths'
import { formatArtistName, toProductionYears } from '@verisart/shared'
import { PurchasedData } from '../../api'

export type UseGetPurchasedList = {
  items: DashboardData[]
  hasNFT: boolean
  totalCount: number
}

const useGetPurchasedList = (
  isPolling: boolean,
  page: number,
  size: number
): UseGetPurchasedList | undefined => {
  const { data } = useQuery(
    ['purchases', page, size],
    async () => {
      return API.customer.getPurchasedList(page, size)
    },
    {
      refetchInterval: isPolling && 10000,
    }
  )

  if (!data) return undefined

  const mapItems = (data: PurchasedData[]) => {
    let hasNFT = false

    const items: DashboardData[] = data.map((item): DashboardData => {
      if (item.blockchain !== null) {
        hasNFT = true
      }

      return {
        certificateId: item.certificateId ?? '',
        image: item.thumbnail,
        title: item.title,
        href: item.certificateId
          ? generateWorksPath(item.certificateId)
          : undefined,
        artistName: item.artist?.name ? formatArtistName(item.artist.name) : '',
        productionYears: toProductionYears(item),
        transferStatus: item.nftTransferStatus ?? undefined,
        blockchain: item.blockchain,
        quantity: item.quantity,
        dropVolume: item.dropVolume,
        superLazyMintOrderId: item.superLazyMintOrderId ?? '',
        mintStatus: item.mintStatus,
        nftTestnet: item.nftTestnet,
        placeholder: item.placeholder,
        mintingStartedAt: item.mintingStartedAt,
      }
    })

    return { items, hasNFT }
  }

  const { items, hasNFT } = mapItems(data.data)

  return {
    items,
    hasNFT,
    totalCount: parseInt(data.headers['item-count']),
  }
}

export default useGetPurchasedList
