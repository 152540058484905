import { AxiosInstance } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

export type BlockchainAddress = {
  address: string
}

export type AccountWithBlockchainAddresses = {
  blockchainAddresses: BlockchainAddress[]
  realUserEmail: string | null
}

const getAccountBlockchainAddresses = async (
  axios: AxiosInstance
): Promise<AccountWithBlockchainAddresses | undefined> => {
  try {
    const res = await axios.get(`/account/me`)
    return res.data
  } catch (e) {
    return undefined
  }
}

const useGetAccountBlockchainAddresses = (
  axios: AxiosInstance
): UseQueryResult<AccountWithBlockchainAddresses | undefined> => {
  return useQuery<AccountWithBlockchainAddresses | undefined>(
    ['account'],
    () => getAccountBlockchainAddresses(axios),
    {
      // So when we return from things like upgrading accounts etc we'll pick up new plans
      refetchOnWindowFocus: 'always',
    }
  )
}

export default useGetAccountBlockchainAddresses
