import { ReactComponent as ArrowLeft } from '../../icons/ico-paginate-arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../icons/ico-paginate-arrow-right.svg'

const PAGINATION_RANGE = 3
type PaginationProps = {
  setPage: (page: number) => void
  page: number
  itemCount: any
  pageSize: number
}

/**
 * TODO: This is a copy of the Pagination component from packages/website/components/certificate/Pagination.tsx
 */
const Pagination: React.FC<PaginationProps> = ({
  page,
  setPage,
  itemCount,
  pageSize,
}) => {
  const totalPages = Math.ceil(parseInt(itemCount) / pageSize)

  let start = 0
  const pages: number[] = []

  if (page < Math.floor(PAGINATION_RANGE / 2 + 1)) {
    start = 0
  } else if (page >= totalPages - Math.floor(PAGINATION_RANGE / 2)) {
    start = Math.floor(totalPages - PAGINATION_RANGE + 1) - 1
  } else {
    start = page - Math.floor(PAGINATION_RANGE / 2)
  }

  for (let i = start; i <= start + PAGINATION_RANGE - 1; i++) {
    if (i < totalPages) {
      pages.push(i)
    }
  }

  return (
    <div>
      <div className="ver-flex ver-items-center">
        <PaginationArrow
          left
          disabled={!(page > 0)}
          onClick={() => page > 0 && setPage(page - 1)}
        />
        {pages.map((number) => (
          <button
            className={`ver-text-base ${
              number === page
                ? 'ver-text-secondary-200 ver-font-bold'
                : 'ver-text-secondary-100'
            } ver-font-headings hover:ver-text-secondary-200 ver-h-10 ver-w-10 ver-cursor-pointer`}
            key={number}
            onClick={() => setPage(number)}
          >
            {number + 1}
          </button>
        ))}
        <PaginationArrow
          right
          disabled={!(page < totalPages - 1)}
          onClick={() => page < totalPages - 1 && setPage(page + 1)}
        />
      </div>
    </div>
  )
}

type PaginationArrowProps = {
  left?: boolean
  right?: boolean
  onClick: () => void
  disabled: boolean
}
const PaginationArrow = ({
  left,
  right,
  onClick,
  disabled,
}: PaginationArrowProps) => {
  return (
    <div
      className={
        `${
          disabled
            ? 'ver-text-disabled'
            : 'ver-text-app-secondary-variant hover:ver-bg-gray-150 ver-cursor-pointer'
        } ` +
        `${left ? 'ver-mr-2' : 'ver-ml-2'} ` +
        `ver-z-50 ver-flex ver-h-9 ver-w-9 ver-items-center ver-justify-center ver-rounded-full ver-border ver-border-solid ver-border-current md:ver-h-12 md:ver-w-12`
      }
      onClick={onClick}
    >
      {left && <ArrowLeft className={`ver-h-10 ver-w-10 ver-fill-current`} />}
      {right && <ArrowRight className={`ver-h-10 ver-w-10 ver-fill-current`} />}
    </div>
  )
}

export default Pagination
