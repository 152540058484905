import { useState } from 'react'
import style from './readstyle.module.css'
import Text from '../Text'
import t from '../../../lib/translation'

interface ReadMoreTextProps {
  text?: string
}

const MAX_CHAR_COUNT = 500

const ReadMoreText: React.FC<ReadMoreTextProps> = ({ text }) => {
  const choppedString = text?.substr(0, MAX_CHAR_COUNT).trim()
  const [isOpen, setIsOpen] = useState(false)

  const renderCTA = isOpen
    ? t.works.readMoreText.closeText
    : t.works.readMoreText.openText
  const renderText = isOpen ? text : choppedString

  if (!text) return <></>
  const charNumber = text.length
  if (charNumber < MAX_CHAR_COUNT) {
    return (
      <div className={`ver-whitespace-pre-line`}>
        <Text>{text}</Text>
      </div>
    )
  }

  return (
    <>
      <div className={`ver-relative ver-whitespace-pre-line`}>
        <Text>{renderText}</Text>
        <div className={`${isOpen ? '' : style['topBottomOverflowFade']}`}>
          {' '}
        </div>
      </div>
      <span
        className={'ver-font-bold ver-cursor-pointer'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text>{renderCTA}</Text>
      </span>
    </>
  )
}

export default ReadMoreText
