const baseImageFilterUrl = 'https://res.cloudinary.com/verisart/image/upload'
const baseVideoFilterUrl = 'https://res.cloudinary.com/verisart/video/upload'

/**
 * Resizes image using cloudinary
 *
 * @param url - url of the image
 * @param width - with of the resized image
 */
export const resizeImage = (
  url: string,
  width: number,
  offset?: number | null,
  ext?: string
): string => {
  if (isUrlSuitableForCloudinaryProcessing(url)) {
    const resizeMax = width
    return `${baseImageFilterUrl}/${createTransformation({
      resizeMax,
      offset,
      ext,
    })}/${urlForFolder(url)}`
  }

  return url
}

/**
 * Converts image to specified extension using cloudinary
 *
 * @param url - url of the image
 * @param ext - expected extension
 */
export const formatImage = (url: string, ext: string): string => {
  if (isUrlSuitableForCloudinaryProcessing(url)) {
    return `${baseImageFilterUrl}/${createTransformation({
      ext,
    })}/${urlForFolder(url)}`
  }

  return url
}

export const formatVideo = (
  url: string,
  ext: string,
  offset: number | 'auto' = 0
): string => {
  if (isUrlSuitableForCloudinaryProcessing(url)) {
    return `${baseVideoFilterUrl}/${createTransformation({
      ext,
      offset,
    })}/${urlForFolder(url)}`
  }

  return url
}

export const formatAndResizeImage = (
  url: string,
  ext: string,
  width: number
): string => {
  if (isContentfulUrl(url)) {
    return `${url}?fm=${ext}&w=${width}`
  }
  if (isUrlSuitableForCloudinaryProcessing(url)) {
    const resizeMax = width
    return `${baseImageFilterUrl}/${createTransformation({
      ext,
      resizeMax,
    })}/${urlForFolder(url)}`
  }

  return url
}

export const formatAndResizeVideo = (
  url: string,
  ext: string,
  width: number,
  offset: number | null = null,
  forceResizeHack?: boolean
): string => {
  if (isUrlSuitableForCloudinaryProcessing(url)) {
    const resizeMax = width
    return `${baseVideoFilterUrl}/${createTransformation({
      ext,
      resizeMax,
      offset,
      forceResizeHack,
    })}/${urlForFolder(url)}`
  }

  return url
}

interface TransformationOptions {
  ext?: string
  // Emulates functionality from Twicpic https://www.twicpics.com/documentation/api-transformations/#resize-max.
  resizeMax?: number
  offset?: number | 'auto' | null

  /**
   * Ensures that if the image is at the size `resizeMax`, we resize it to be 1px smaller, to force cloudinary to
   * re-encode it. This was required for VER-6779 where we found that if a video happened to be at the exact size
   * requested and we were converting to an image, cloudinary would not re-encode it and the image would be
   * messed up when used with jspdf. This is probably a jspdf bug, but this trick fixes it, we're not sure why.
   */
  forceResizeHack?: boolean
}

const createTransformation = (opts: TransformationOptions): string => {
  const transformations: Array<Array<string>> = []

  if (opts.resizeMax) {
    const initialInstruction: Array<string> = []

    // If portrait (or exactly square - note less than or equal) set width to max
    const widthInstruction = [`if_iw_lte_ih`, `w_${opts.resizeMax}`]

    // If landscape set height to max (or exactly square - note less than or equal) set height to max
    const heightInstruction = [`if_ih_lte_iw`, `h_${opts.resizeMax}`]

    if (opts.ext) {
      initialInstruction.push(`f_${opts.ext}`)
    }
    if (opts.offset !== null) {
      initialInstruction.push(`so_${opts.offset}`)
    }

    transformations.push(
      initialInstruction,
      widthInstruction,
      heightInstruction
    )

    if (opts.forceResizeHack) {
      // See above. If the height is already at the requested size then force a resize to 1 pixel smaller.
      transformations.push([
        `if_iw_lte_ih_and_iw_eq_${opts.resizeMax}`,
        `w_${opts.resizeMax - 1}`,
      ])
      transformations.push([
        `if_ih_lte_iw_and_ih_eq_${opts.resizeMax}`,
        `h_${opts.resizeMax - 1}`,
      ])
    }
  } else if (opts.ext) {
    const ext = opts.ext === 'jpeg' ? 'jpg' : opts.ext
    let instruction = `f_${ext}`
    if (opts.offset !== null) {
      instruction = `${instruction},so_${opts.offset}`
    }
    if (transformations.length === 0) {
      transformations.push([instruction])
    } else {
      transformations.forEach((transformations) =>
        transformations.push(instruction)
      )
    }
  }

  return transformations
    .map((transformations) => transformations.join(','))
    .join('/')
}

/**
 *
 * @param url Checks if the given url is valid
 */
export const isUrlSuitableForCloudinaryProcessing = (url: string) => {
  try {
    const tmp = new URL(url)

    if (tmp.searchParams.has('X-Amz-Signature')) {
      // S3 signed URLs don't work with cloudinary
      return false
    }

    if (url.startsWith('https://res.cloudinary.com/')) {
      // Already a cloudinary URL
      return false
    }
  } catch (_) {
    // Invalid URL
    return false
  }
  return true
}

const isContentfulUrl = (url: string) => {
  try {
    const u = new URL(url)
    return u.hostname === 'images.ctfassets.net'
  } catch (_) {
    return false
  }
}

// Folder mapping helps us when working with multiple environments. This ensures that images from each of the
// environment end up in the appropriate directory so that we can better manage images. Since we are currently using
// media fetch as the preferred approach for Cloudinary it is essentially behaving as a cache for S3 public assets.
// Before you add an item to this map you will need to remember to configure the "Auto upload mapping" under the
// upload settings section in the Cloudwatch console.
//
// If storage starts to hit limits on Cloudinary then you can safely delete one of these mapped folders in Cloudinary
// to reclaim the space on the library. Particular candidates for deletion will be;
//
// * test/certificates
// * test/customizations
// * test/signatures
// * staging/certificates
// * staging/customizations
// * staging/signatures
const folderMap = [
  {
    folder: 'test/certificates',
    url: 'https://verisart-certificates-images-test.s3.amazonaws.com/certificates',
  },
  {
    folder: 'test/files',
    url: 'https://verisart-certificates-images-test.s3.amazonaws.com/files',
  },
  {
    folder: 'test/temp-media',
    url: 'https://verisart-temp-media-upload-test.s3.amazonaws.com',
  },
  { folder: 'test', url: 'https://verisart-assets-test.s3.amazonaws.com' },
  {
    folder: 'staging/certificates',
    url: 'https://verisart-certificates-images-staging.s3.amazonaws.com/certificates',
  },
  {
    folder: 'staging/files',
    url: 'https://verisart-certificates-images-staging.s3.amazonaws.com/files',
  },
  {
    folder: 'staging/temp-media',
    url: 'https://verisart-temp-media-upload-staging.s3.amazonaws.com',
  },
  {
    folder: 'staging',
    url: 'https://verisart-assets-staging.s3.amazonaws.com',
  },
  {
    folder: 'production/certificates',
    url: 'https://verisart-certificates-images-production.s3.amazonaws.com/certificates',
  },
  {
    folder: 'production/files',
    url: 'https://verisart-certificates-images-production.s3.amazonaws.com/files',
  },
  {
    folder: 'production/temp-media',
    url: 'https://verisart-temp-media-upload-production.s3.amazonaws.com',
  },
  {
    folder: 'production',
    url: 'https://verisart-assets-production.s3.amazonaws.com',
  },
  {
    folder: 'shopify',
    url: 'https://cdn.shopify.com',
  },
]

export const urlForFolder = (url: string): string => {
  for (const mapping of folderMap) {
    if (url.startsWith(mapping.url)) {
      return url.replace(mapping.url, mapping.folder)
    }
  }
  return url
}

export default resizeImage
