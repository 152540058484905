type HeadingVariant = 'heading-1' | 'heading-2' | 'heading-3'

interface HeadingProps {
  variant: HeadingVariant
}

const headingClassName = {
  'heading-1': 'ver-text-2xl ver-font-bold',
  'heading-2': 'ver-text-base ver-font-bold',
  'heading-3': 'ver-text-base ver-font-medium',
}

const Heading: React.FC<HeadingProps> = ({ children, variant }) => {
  switch (variant) {
    case 'heading-1':
      return <h1 className={headingClassName[variant]}>{children}</h1>
    case 'heading-2':
      return <h2 className={headingClassName[variant]}>{children}</h2>
    case 'heading-3':
      return <h3 className={headingClassName[variant]}>{children}</h3>
  }
}

export default Heading
