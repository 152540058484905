import { useParams } from 'react-router-dom'
import styles from '../styles/containers.module.css'
import { Spinner } from '@verisart/shared'
import Claim from '../components/organisms/Claim'
import {
  ConnectorNames,
  isConnectWalletError,
  useConnectWallet,
} from '@verisart/nft/src'
import * as API from '../api'
import useClaimMint from '../lib/hooks/useClaimMint'
import useGetDropDetails from '../lib/hooks/useGetDropDetails'
import useClaimMintSignable from 'src/lib/hooks/useClaimSignable'
import assert from 'assert'
import TopBar from 'src/components/organisms/TopBar'
import ConnectWalletDialog, {
  useConnectWalletReducer,
} from '../components/organisms/ConnectWalletDialog'
import t from '../lib/translation'
import { useEffect, useState } from 'react'

const MintPage = () => {
  const { id } = useParams<'id'>()
  const [isPolling, setIsPolling] = useState(false)
  const { assetDetails, isLoading: isDropDetailsLoading } = useGetDropDetails(
    id as string,
    isPolling
  )
  const api = API.customer.axios

  const { account: activeWallet, platform } = useConnectWallet(api)

  const {
    claimMint,
    claimed: claimedMint,
    error: claimMintError,
    loading: claimingMint,
  } = useClaimMint(
    id as string,
    activeWallet,
    assetDetails?.blockchain,
    platform
  )

  const {
    claimSignable,
    claimed: claimedSignable,
    error: claimSignableError,
    loading: claimingSignable,
  } = useClaimMintSignable()

  const handleClaim = () => {
    if (assetDetails?.signedMinting) {
      assert(activeWallet, 'activeWallet connected wrongly')
      claimSignable(id as string, activeWallet)
    } else {
      claimMint()
    }
  }

  useEffect(() => {
    if (assetDetails !== undefined && assetDetails.completed === false) {
      setIsPolling(true)
    } else {
      setIsPolling(false)
    }
  }, [assetDetails])

  const [state, dispatch] = useConnectWalletReducer()

  const connecting = state.working

  return (
    <>
      <div
        className={`ver-w-full ver-flex ver-flex-col ver-mb-20 ${styles.container}`}
      >
        <TopBar blockchain={assetDetails?.blockchain} />
        <div className="ver-flex ver-flex-grow ver-items-center ver-justify-center">
          {isDropDetailsLoading ? (
            <Spinner />
          ) : (
            <Claim
              activeWallet={activeWallet}
              assetDetails={assetDetails}
              claimed={assetDetails?.claimed || claimedMint || claimedSignable}
              loading={connecting || claimingMint || claimingSignable}
              connectError={
                state.name === 'Failure'
                  ? isConnectWalletError(state.connectError)
                    ? state.connectError?.message
                    : state.connectError
                  : undefined
              }
              claimError={claimMintError || claimSignableError}
              onBack={() => {
                window.open(
                  t.claim.button.redirectLink
                    .replaceAll(
                      '$contractAddress',
                      assetDetails?.contractAddress || ''
                    )
                    .replaceAll('$tokenId', assetDetails?.tokenIds?.[0] ?? '')
                    .replaceAll('$blockchain', assetDetails?.blockchain || ''),
                  '_blank'
                )
              }}
              onConnect={() => dispatch({ type: 'ChoosePlatform' })}
              onConnectMagic={() =>
                dispatch({
                  type: 'Connect',
                  platform: ConnectorNames.MagicLink,
                })
              }
              onClaim={handleClaim}
              platform={platform}
            />
          )}
        </div>
        <ConnectWalletDialog state={state} dispatch={dispatch} />
      </div>
    </>
  )
}

export default MintPage
