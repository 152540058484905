import { Blockchain, scannerBaseUrl } from '@verisart/nft/src'
type EthThingProps = {
  text: string
  asLink: boolean | undefined
  blockchain?: Blockchain
  type: 'transaction' | 'address' | 'tokenId'
}
import {
  truncateEthereumAddress,
  truncateString,
} from '@verisart/shared/src/utils/formatStrings'

/**
 * Truncated ethereum text with copy button and optional hyperlink to etherscan
 *
 * @deprecated use ChainItem instead
 */
const EthereumTruncatedString: React.FC<EthThingProps> = ({
  text,
  asLink,
  blockchain,
  type = 'address',
}) => {
  const truncate =
    type === 'address'
      ? truncateEthereumAddress(text)
      : truncateString(text, 6, 4)

  return asLink && blockchain ? (
    <a
      href={
        type === 'address'
          ? `${scannerBaseUrl(blockchain)}/address/${text}`
          : `${scannerBaseUrl(blockchain)}/tx/${text}`
      }
      className="ver-underline"
      target="_blank"
      rel="noreferrer"
    >
      {truncate}
    </a>
  ) : (
    <span>{truncate}</span>
  )
}

export default EthereumTruncatedString
