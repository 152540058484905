import Text from './Text'
import { Duration as DurationLuxon } from 'luxon'
import { Duration } from '@verisart/shared'

const DurationDisplayer: React.FC<{ duration: Duration }> = ({ duration }) => {
  const { hours, minutes, seconds } = duration
  const durationLuxon = DurationLuxon.fromObject({
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  })

  return (
    <Text>
      {hours == 0
        ? durationLuxon.toFormat('mm:ss')
        : durationLuxon.toFormat('hh:mm:ss')}
    </Text>
  )
}

export default DurationDisplayer
