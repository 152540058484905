import React, { SyntheticEvent, useCallback } from 'react'
import resizeImage, {
  formatAndResizeImage,
  formatAndResizeVideo,
  isUrlSuitableForCloudinaryProcessing,
} from '../../utils/resizeImage'
import {
  CSSProperties,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Spinner from '../../ui/Spinner'
import {
  isGif,
  isHTML,
  isImage,
  isModel,
  isVideoUrl,
} from '../../utils/fileTypes'

import UnmutedIcon from '../../icons/volume-high'
import MutedIcon from '../../icons/volume-mute'

export interface PreviewProps {
  displayUrl: string
}

export interface PlayablePreviewProps {
  play?: boolean
  onPreviewStart?: () => void
  onPreviewEnd?: () => void
}

export interface ClickablePreviewProps {
  onClick?: MouseEventHandler
}

export type AssetViewProps = {
  alt?: string
  className?: string
  primaryUrl: string

  /**
   * Width of transformed media - only relevant when [transform] is true, and only used for images and videos.
   */
  width?: number
  style?: CSSProperties

  /**
   * Whether to wrap [primaryUrl] in a Cloudinary URL transformation. Only used when the
   * URL is an image or video.
   *
   * Default is true
   */
  transform?: boolean
  mediaOverride?: MediaTag

  setLoading?: (bool: boolean) => void
  triggerReset?: boolean

  /**
   * This is largely coupled to verisart.com and probably isn't usable elsewhere
   */
  inCarousel?: boolean

  /**
   * This is largely coupled to verisart.com and probably isn't usable elsewhere
   */
  previewProps?: PreviewProps
  modelMode?: ModelViewerMode

  /**
   * If false/unset then we show a mute/unmute button which can be clicked to turn on/off audio.
   * The button only appears for videos with an audio track (best effort).
   *
   * If set to true then the button is never shown.
   */
  hideMuteButton?: boolean
} & PlayablePreviewProps &
  ClickablePreviewProps

type GifAssetViewProps = {
  alt?: string
  className: string
  posterUrl: string
  liveUrl: string
  style: CSSProperties
  setLoading?: (bool: boolean) => void
} & PlayablePreviewProps &
  ClickablePreviewProps

type ImageAssetViewProps = {
  alt?: string
  className: string
  url: string
  style: CSSProperties
  setLoading?: (bool: boolean) => void
} & ClickablePreviewProps

type VideoAssetViewProps = {
  primaryUrl: string
  previewProps?: PreviewProps
  className: string
  width: number
  style: CSSProperties
  transform: boolean
  setLoading?: (bool: boolean) => void
  triggerReset?: boolean
  inCarousel?: boolean
  hideMuteButton?: boolean
} & PlayablePreviewProps &
  ClickablePreviewProps

export type MediaTag = 'video' | 'gif' | 'image' | 'model' | 'html'

const AssetView: React.FC<AssetViewProps> = ({
  alt,
  primaryUrl,
  previewProps,
  width = 300,
  transform = true,
  className = '',
  style = {},
  mediaOverride,
  setLoading,
  triggerReset,
  modelMode,
  ...rest
}) => {
  const mediaType = useMemo(
    () => ({ tag: mediaOverride || extractMediaTypeFromUrl(primaryUrl) }),
    [mediaOverride, primaryUrl]
  )

  switch (mediaType.tag) {
    case 'video':
      return (
        <VideoAssetView
          primaryUrl={primaryUrl}
          previewProps={previewProps}
          width={width}
          transform={transform}
          className={className}
          style={style}
          setLoading={setLoading}
          triggerReset={triggerReset}
          {...rest}
        />
      )
    case 'gif':
      return (
        // Note if transform is false then Gifs will appear animated because the Cloudinary
        // feature to select a frame won't be run
        <GifAssetView
          posterUrl={
            transform
              ? formatAndResizeImage(primaryUrl, 'jpg', width)
              : primaryUrl
          }
          liveUrl={transform ? resizeImage(primaryUrl, width) : primaryUrl}
          className={className}
          style={style}
          setLoading={setLoading}
          {...rest}
        />
      )
    case 'image':
      return (
        <ImageAssetView
          url={transform ? resizeImage(primaryUrl, width) : primaryUrl}
          alt={alt}
          className={className}
          style={style}
          setLoading={setLoading}
          {...rest}
        />
      )
    case 'model':
      return (
        <ModelViewer
          src={primaryUrl}
          className={className}
          style={style}
          onClick={rest.onClick}
          mode={modelMode ?? 'rotate'}
        />
      )
    case 'html':
      return (
        <div style={{ position: 'relative', width: '100%', maxWidth: 600 }}>
          <iframe
            src={primaryUrl}
            allow={`accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; ${
              rest.inCarousel && 'camera *;'
            }`}
            sandbox={`allow-scripts ${rest.inCarousel && 'allow-same-origin'}`}
            style={{
              width: '100%',
              maxWidth: '100%',
              aspectRatio: '1 / 1',
            }}
          />
          {/* Having a transparent element on top of iframe so the onClick event can be propagated */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          ></div>
        </div>
      )
  }
}

const useLoadHandler = (url: string | undefined) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!url) {
      setLoading(false)
      return
    }

    const image = new Image()
    image.onload = () => {
      setLoading(false)
      image.onload = null
      image.src = ''
    }
    image.src = url

    return () => {
      image.onload = null
      image.src = ''
    }
  }, [url])

  return loading
}

const GifAssetView: React.FC<GifAssetViewProps> = ({
  alt,
  className,
  style,
  play = false,
  onPreviewStart,
  onPreviewEnd,
  posterUrl,
  liveUrl,
  onClick,
  setLoading,
}) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const loading = useLoadHandler(posterUrl)

  useEffect(() => {
    setLoading?.(loading)
  }, [loading, setLoading])

  const imgStyle = {
    ...(loading ? { display: 'none' } : {}),
    ...style,
  }

  return (
    <>
      {loading && <ImageSpinner className={className} style={style} />}
      <img
        alt={alt}
        src={play ? liveUrl : posterUrl}
        className={className}
        style={imgStyle}
        onMouseEnter={() => onPreviewStart?.()}
        onMouseLeave={() => onPreviewEnd?.()}
        onClick={onClick}
        ref={imageRef}
      />
    </>
  )
}

const ImageAssetView: React.FC<ImageAssetViewProps> = ({
  alt,
  className,
  style,
  url,
  setLoading,
  onClick,
}) => {
  const loading = useLoadHandler(url)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    setLoading?.(loading)
  }, [loading, setLoading])

  const imgStyle = {
    ...(loading ? { display: 'none' } : {}),
    ...style,
  }

  return (
    <>
      {loading && <ImageSpinner className={className} style={style} />}
      <img
        alt={alt}
        src={url}
        className={className}
        style={imgStyle}
        ref={imageRef}
        onClick={onClick}
      />
    </>
  )
}

function hasAudio(video: any) {
  return (
    video.mozHasAudio ||
    Boolean(video.webkitAudioDecodedByteCount) ||
    Boolean(video.audioTracks && video.audioTracks.length)
  )
}

const VideoAssetView: React.FC<VideoAssetViewProps> = ({
  className,
  onPreviewStart,
  onPreviewEnd,
  play,
  width,
  onClick,
  style,
  transform,
  setLoading,
  primaryUrl,
  previewProps,
  triggerReset,
  inCarousel,
  hideMuteButton,
}) => {
  const transformedPoster =
    previewProps !== undefined
      ? resizeImage(previewProps.displayUrl, width)
      : transform && isUrlSuitableForCloudinaryProcessing(primaryUrl)
      ? formatAndResizeVideo(primaryUrl, 'jpg', width, 0)
      : undefined
  const transformedSource = transform
    ? formatAndResizeVideo(primaryUrl, 'mp4', width)
    : primaryUrl
  const videoRef = useRef<HTMLVideoElement>(null)
  const playbackStarting = useRef<Promise<void> | undefined>()

  const [isAudio, setIsAudio] = useState(false)

  useEffect(() => {
    const togglePlayback = () => {
      if (play) {
        playbackStarting.current = videoRef.current
          ?.play()
          .finally(() => (playbackStarting.current = undefined))
      } else {
        videoRef.current?.pause()
      }
    }
    playbackStarting.current
      ? playbackStarting.current.finally(togglePlayback)
      : togglePlayback()
  }, [play])

  useEffect(() => {
    if (triggerReset) {
      //We actually set the display image as the poster of the video
      //Whenever we exit the overlay, we reload the video, equivalent to resetting the video to its poster
      videoRef.current?.load()
    }
  }, [triggerReset])

  const loading = useLoadHandler(transformedPoster)
  useEffect(() => {
    setLoading?.(loading)
  }, [loading, setLoading])

  const videoStyle = {
    ...(loading ? { display: 'none' } : {}),
    ...style,
  }

  // Checking audio status
  // Check both on mounting and loaded.
  // For some reason, videos don't always seem to fire onLoadedData so we seem to need both
  // {{{
  const onLoadedData = useCallback((v: SyntheticEvent<HTMLVideoElement>) => {
    setIsAudio(hasAudio(v.target))
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      setIsAudio(hasAudio(videoRef.current))
    }
  }, [])
  // }}}

  const [muted, setMuted] = useState(true)

  return (
    <div style={{ position: 'relative' }}>
      {loading && previewProps === undefined && (
        <ImageSpinner className={className} style={style} />
      )}
      {loading && previewProps !== undefined && !inCarousel && (
        <div className={`absolute inset-0 flex justify-center p-8`}>
          <img
            className={'h-full object-contain'}
            src={resizeImage(previewProps.displayUrl, width)}
          />
          <div
            className={
              'min-h-32 z-5 absolute left-0 top-0 flex h-full w-full items-center justify-center'
            }
          >
            <Spinner />
          </div>
        </div>
      )}
      {loading && previewProps !== undefined && inCarousel && (
        <div
          className={`min-h-32 z-5 relative left-0 top-0 flex w-full items-center justify-center ${className}`}
          style={style}
        >
          <img
            src={resizeImage(previewProps.displayUrl, width)}
            className={'absolute object-contain '}
          />
          <Spinner />
        </div>
      )}
      <video
        className={className}
        style={videoStyle}
        poster={transformedPoster}
        preload="auto"
        onMouseEnter={() => onPreviewStart?.()}
        onMouseLeave={() => onPreviewEnd?.()}
        onClick={onClick}
        ref={videoRef}
        muted={muted}
        onLoadedData={onLoadedData}
        loop
      >
        <source src={transformedSource} type="video/mp4" />
      </video>
      {isAudio && !hideMuteButton && (
        <div
          style={{
            position: 'absolute',
            left: '1.3rem',
            bottom: '1.3rem',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onMouseEnter={() => onPreviewStart?.()}
          onMouseLeave={() => onPreviewEnd?.()}
          onClick={(e) => {
            setMuted(!muted)
            e.stopPropagation()
          }}
        >
          {muted ? <MutedIcon /> : <UnmutedIcon />}
        </div>
      )}
    </div>
  )
}

type ImageSpinnerProps = {
  className: string
  style: CSSProperties
}

export const ImageSpinner: React.FC<ImageSpinnerProps> = ({
  className,
  style,
}) => {
  const imgStyle: React.CSSProperties = {
    minHeight: '128px',
    zIndex: '5',
    position: 'relative',
    left: '0',
    top: '0',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'inherit',
    ...style,
  }

  return (
    <div className={className} style={imgStyle}>
      <Spinner />
    </div>
  )
}

export default AssetView

export type ModelViewerMode = 'rotate' | 'camera' | 'off'

export type ModelViewerType = {
  src: string
  mode: ModelViewerMode
  className: string
  style: React.CSSProperties
  onClick?: MouseEventHandler
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes
    }
    interface MyElementAttributes {
      src: string
      style?: React.CSSProperties
      'auto-rotate'?: boolean
      'camera-controls'?: boolean
      class?: string
      onClick?: MouseEventHandler
    }
  }
}

export const MODEL_VIEWER_SCRIPT_URL =
  'https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js'

const ModelViewer: React.FC<ModelViewerType> = ({
  mode,
  src,
  className,
  style,
  onClick,
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (customElements.get('model-viewer')) {
      setLoading(false)
      return
    }

    let script: HTMLScriptElement | null = document.querySelector(
      `script[src='${MODEL_VIEWER_SCRIPT_URL}']`
    )
    if (!script) {
      script = document.createElement('script')
      script.src = MODEL_VIEWER_SCRIPT_URL
      script.type = 'module'
      document.body.appendChild(script)
    }

    const listener = () => setLoading(false)
    script.addEventListener('load', listener)

    return () => script?.removeEventListener('load', listener)
  }, [])

  if (loading) return <ImageSpinner className={className} style={style} />

  return (
    <model-viewer
      class={`${className}`}
      onClick={onClick}
      src={src}
      auto-rotate={mode === 'rotate' ? true : undefined}
      camera-controls={mode === 'camera' ? true : undefined}
      auto-rotate-delay={mode === 'rotate' ? 2000 : undefined}
      rotation-per-second={mode === 'rotate' ? '500%' : undefined}
      style={{ aspectRatio: '1', width: '100%', height: '100%', ...style }}
    />
  )
}

export const extractMediaTypeFromUrl = (url: string): MediaTag => {
  let tag: MediaTag
  const { pathname } = new URL(url)
  if (isVideoUrl(pathname)) {
    return 'video'
  } else if (isGif(pathname)) {
    tag = 'gif'
  } else if (isModel(pathname)) {
    tag = 'model'
  } else if (isHTML(pathname)) {
    tag = 'html'
  } else {
    tag = 'image'
  }
  return tag
}

export const isURLSupportedByAssetView = (
  url: string,
  includeHtml = false
): boolean => {
  const { pathname } = new URL(url)
  return (
    isImage(pathname) ||
    isVideoUrl(pathname) ||
    isModel(pathname) ||
    (includeHtml && isHTML(pathname))
  )
}
