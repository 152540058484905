import React, { useState, useEffect } from 'react'
import Text from 'src/components/atoms/Text'
import Lock from '@verisart/shared/src/icons/lock-filled'
import Eye from '@verisart/shared/src/icons/eye'
import LockOpen from '@verisart/shared/src/icons/lock-filled-open'
import { UserFile } from '@verisart/shared/src/utils/ObjectDataUtils'
import t from 'src/lib/translation'
import style from './CollectorRewards.module.css'
import { truncateFilename } from '@verisart/shared/src/utils/fileTypes'

type CollectorRewardsProps = {
  id: string
  collectorRewards: UserFile[]
}

const addToCollectorsArray = (id: string) => {
  const storageResult = JSON.parse(
    localStorage.getItem('verisart.collectorRewardsShown') || '[]'
  ) as string[]
  if (!storageResult.includes(id)) {
    storageResult.push(id)
    localStorage.setItem(
      'verisart.collectorRewardsShown',
      JSON.stringify(storageResult)
    )
  }
}

const CollectorRewards: React.FC<CollectorRewardsProps> = ({
  id,
  collectorRewards,
}) => {
  const [rewardsShown, setRewardsShown] = useState(false)

  useEffect(() => {
    const collectorArray = JSON.parse(
      localStorage.getItem('verisart.collectorRewardsShown') || '[]'
    )
    collectorArray.includes(id) && setRewardsShown(true)
  }, [id])

  return (
    <div
      className={`${style['ver-wrapper']} ver-w-full ver-overflow-hidden ver-rounded-xl ver-px-8`}
    >
      <div
        className={`ver-my-6 ver-flex ver-flex-row ver-items-center ver-cursor-pointer ver-select-none`}
        onClick={() => {
          setRewardsShown(!rewardsShown)
          addToCollectorsArray(id)
        }}
      >
        <div className="ver-mr-3">{rewardsShown ? <LockOpen /> : <Lock />}</div>
        <div className="ver-w-full">
          {rewardsShown ? (
            <Text variant="large" bold>
              {t.dashboard.collectorRewards.reward}
            </Text>
          ) : (
            <span>
              <span className="ver-font-sans ver-font-bold ver-text-lg ver-text-primary">
                {t.dashboard.collectorRewards.rewards}
              </span>
            </span>
          )}
        </div>
      </div>
      {rewardsShown && (
        <ul
          className={`ver-bg-green-light ver-transition-translate ver-flex ver-flex-col ver-divide-y ver-divide-solid ver-divide-white ver-pb-4 ver-pt-2`}
        >
          {collectorRewards.map((file, i) => {
            return (
              <li
                key={`${file.url}-${file.label}-${i}`}
                className={`${style['ver-list-divider']} ${
                  file.label ? '' : 'ver-cursor-auto'
                } ver-py-4 ver-font-sans`}
              >
                <a
                  target="_blank"
                  download
                  href={file.url}
                  rel="noopener noreferrer"
                  className="ver-flex ver-flex-row ver-justify-between"
                >
                  <div className="ver-flex ver-flex-row">
                    <span
                      className={`ver-flex ver-flex-row ver-items-center ver-space-x-3`}
                    >
                      <p
                        className={`ver-text-primary ver-font-sans ver-text-base ver-font-bold ver-mr-2`}
                      >
                        {file.label
                          ? truncateFilename(file.label)
                          : t.dashboard.collectorRewards.fileLabel}
                      </p>
                    </span>
                    <Text as="p">
                      ({file && file?.fileName?.split('.').pop()?.toUpperCase()}
                      )
                    </Text>
                  </div>
                  <div className="ver-text-primary ver-mx-4 ver-flex ver-items-center">
                    <Eye />
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default CollectorRewards
