import {
  SimpleRegistrationError,
  SimpleRegistrationErrorCause,
  translations,
} from './common'

export const createSimpleRegistrationError = (
  cause: SimpleRegistrationErrorCause
): SimpleRegistrationError => ({
  type: 'simple_registration_error',
  name: 'RegistrationError',
  message: translations[cause],
  isRegistrationError: true,
  cause,
})
