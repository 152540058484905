import Button from '../atoms/Button'
import {
  ConnectorNames,
  ConnectWalletError,
  isWalletLinkedOtherComplexRegistrationError,
  translations,
  useConnectWallet,
  WalletLinkedOtherComplexRegistrationError,
} from '@verisart/nft/src'
import Dialog from '../atoms/Dialog'
import { useQueryClient } from 'react-query'
import { ClaimPlatform, completeClaimChallenge } from '@verisart/nft/src/http'
import * as API from '../../api'
import ConnectMetamaskDialog from '../molecules/ConnectMetamaskDialog'
import { Dispatch, Reducer, useCallback, useEffect, useReducer } from 'react'

type CommonState = {
  working: boolean
}

type ConnectState = {
  name: 'Connect'
  platform: ConnectorNames
} & CommonState

type SimpleState = {
  name: 'Idle' | 'ChoosePlatform' | 'Connecting' | 'Success'
} & CommonState

type ForceModalFailureState = {
  name: 'ForceModal'
  connectError: WalletLinkedOtherComplexRegistrationError
} & CommonState

type TotalFailureState = {
  name: 'Failure'
  connectError: ConnectWalletError | string
} & CommonState

type State =
  | ConnectState
  | SimpleState
  | ForceModalFailureState
  | TotalFailureState

interface SimpleAction {
  type: 'ChoosePlatform' | 'Success' | 'Close' | 'Connecting'
}

interface ConnectAction {
  type: 'Connect'
  platform: ConnectorNames
}

interface FailAction {
  type: 'Fail'
  payload: ConnectWalletError | string
}

type Action = SimpleAction | ConnectAction | FailAction

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ChoosePlatform':
      return { name: 'ChoosePlatform', working: true }
    case 'Connect':
      return { name: 'Connect', platform: action.platform, working: true }
    case 'Connecting':
      return { name: 'Connecting', working: true }
    case 'Success':
      return { name: 'Success', working: false }
    case 'Fail':
      if (
        isWalletLinkedOtherComplexRegistrationError(action.payload) &&
        state.name !== 'ForceModal'
      ) {
        return {
          name: 'ForceModal',
          connectError: action.payload,
          working: true,
        }
      } else {
        return { name: 'Failure', connectError: action.payload, working: false }
      }
    case 'Close':
      return { name: 'Idle', working: false }
  }
}

export const useConnectWalletReducer = (): [State, Dispatch<Action>] => {
  const { connectWallet } = useConnectWallet(
    API.customer.axios,
    // TODO: This was taken from a previous version of the code. We can probably move this logic into
    //       the useEffect below and then we can probably remove this callback from useConnectWallet
    (error: ConnectWalletError | string | undefined) => {
      if (error) {
        dispatch({ type: 'Fail', payload: error })
      } else {
        dispatch({ type: 'Success' })
      }
    }
  )

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    name: 'Idle',
    working: false,
  })

  // Handle the connect request
  useEffect(() => {
    if (state.name === 'Connect') {
      dispatch({ type: 'Connecting' })
      connectWallet(
        state.platform,
        ClaimPlatform.CUSTOMER_DASHBOARD,
        API.customer.shopDomain
      )
    }
  }, [connectWallet, state])

  // Uncomment to debug the state
  // useEffect(() =>
  //   // eslint-disable-next-line no-console
  //   console.info(state),
  //   [state]
  // )

  return [state, dispatch]
}

type MoveAlreadyLinkedDialogProps = {
  state: State
  dispatch: Dispatch<Action>
}
const ConnectWalletDialog: React.FC<MoveAlreadyLinkedDialogProps> = ({
  state,
  dispatch,
}) => {
  const queryClient = useQueryClient()
  const api = API.customer.axios

  const onHide = useCallback(
    (cancelled: boolean) => {
      if (cancelled) {
        dispatch({ type: 'Close' })
      } // else we've already handled the close in `onPlatformChosen`
    },
    [dispatch]
  )

  const onPlatformChosen = useCallback(
    async (connectorName: ConnectorNames) => {
      dispatch({ type: 'Connect', platform: connectorName })
    },
    [dispatch]
  )

  return (
    <>
      <ConnectMetamaskDialog
        show={state.name === 'ChoosePlatform'}
        hide={onHide}
        connectWallet={onPlatformChosen}
      />

      {/* Handles the case where the user has already linked their wallet to another account */}
      {state.name === 'ForceModal' && (
        <Dialog
          show={true}
          onDismiss={
            state.name === 'ForceModal'
              ? () => dispatch({ type: 'Fail', payload: state.connectError })
              : undefined
          }
        >
          <div className={'ver-w-80 ver-flex ver-flex-col ver-gap-10'}>
            <div className="ver-w-full ver-center">
              <p>{state.connectError.misc.detailedMessage}</p>
            </div>
            <div className="ver-w-full ver-flex ver-flex-col ver-gap-4">
              <Button
                onClick={async (e) => {
                  e.stopPropagation()
                  try {
                    await completeClaimChallenge(api, {
                      accountCommitment:
                        state.connectError.misc.signedChallenge
                          .accountCommitment,
                      address: state.connectError.misc.signedChallenge.address,
                      challenge:
                        state.connectError.misc.signedChallenge.challenge,
                      signature:
                        state.connectError.misc.signedChallenge.signature,
                      moveExisting: true,
                    })
                    await queryClient.invalidateQueries('account')
                    dispatch({ type: 'Success' })
                  } catch (e: any) {
                    console.error('Something went wrong')
                    dispatch({
                      type: 'Fail',
                      payload: translations['unknown'] + e.message,
                    })
                  }
                }}
              >
                Move Wallet
              </Button>
              <Button
                fullWidth
                secondary
                onClick={() => {
                  if (state.name === 'ForceModal') {
                    dispatch({ type: 'Fail', payload: state.connectError })
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default ConnectWalletDialog
