import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { generateClaimPath, generateMintPath } from 'src/lib/paths'
import { Spinner } from '@verisart/shared'
import DashboardOrganism from '../components/organisms/Dashboard'
import useGetPurchasedList from '../lib/hooks/useGetPurchasesList'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'

const PAGE_SIZE = 20
const PAGE_QUERY_PARAM = 'page'

const Dashboard: React.FC = () => {
  const [isPolling, setIsPolling] = useState(false)

  const [page = 0, setPage] = useQueryParam<number>(
    PAGE_QUERY_PARAM,
    NumberParam as any
  )

  const [showAddOn = 'false'] = useQueryParam<string>(
    'showAddOn',
    StringParam as any
  )

  const data = useGetPurchasedList(isPolling, page, PAGE_SIZE)

  // TODO: This isn't going to work if reserved items leak onto page 2
  useEffect(() => {
    if (
      data !== undefined &&
      data.items.some((it) => it.mintStatus === 'MINTING')
    ) {
      setIsPolling(true)
    } else {
      setIsPolling(false)
    }
  }, [data])

  const navigate = useNavigate()
  const onClaim = (id: string, mint: boolean) => {
    const to = mint ? generateMintPath(id) : generateClaimPath(id)
    navigate(to)
  }

  if (data === undefined) {
    return (
      <div className="ver-w-full ver-h-screen ver-flex ver-justify-center ver-items-center">
        <Spinner />
      </div>
    )
  }

  return (
    <DashboardOrganism
      data={data.items}
      totalCount={data.totalCount}
      page={page}
      setPage={setPage}
      pageSize={PAGE_SIZE}
      onClaim={onClaim}
      showUpsellBanner={showAddOn === 'true'}
    />
  )
}

export default Dashboard
