import React, { useState } from 'react'
import { ConnectorNames, useConnectWalletNoLinking } from '@verisart/nft/src'
import ConnectMetamaskDialog from '../molecules/ConnectMetamaskDialog'

/**
 * A component to help test wallet connections on 3rd party sites without having an NFT
 */
const TestWallet: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [errorJson, setErrorJson] = useState<string | undefined>(undefined)
  const { connectWalletNoLinking, disconnectAll, account } =
    useConnectWalletNoLinking()

  const doSetError = (e: any) => {
    setError(e.message ?? 'Unknown error')
    try {
      setErrorJson(JSON.stringify(e, null, 2))
    } catch (e) {
      // ignore
    }
  }

  const connectWalletNoLinkingWrapper = async (
    connectorName: ConnectorNames
  ) => {
    try {
      setError(undefined)
      await connectWalletNoLinking(connectorName)
    } catch (e) {
      doSetError(e)
    }
  }

  return (
    <div className="ver-w-full ver-flex ver-justify-center ver-items-center ver-grid ver-space-y-4 ver-mt-20">
      <div>
        <button onClick={() => setShowDialog(true)}>Connect</button>
      </div>
      <div>
        <button onClick={() => disconnectAll()}>Reset</button>
      </div>
      <div>
        <button onClick={() => window?.ethereum?.enable()}>
          Run window.ethereum.enable()
        </button>
      </div>

      <div>Account: {account}</div>
      <div>Error: {error}</div>
      <div>window.ethereum: {window.ethereum}</div>
      <div>window.ethereum.isMetaMask: {window.ethereum?.isMetaMask}</div>
      <div
        style={{ maxWidth: '500px', maxHeight: '300px', overflow: 'scroll' }}
      >
        ErrorJSON: <pre>{errorJson}</pre>
      </div>
      <ConnectMetamaskDialog
        show={showDialog}
        hide={() => setShowDialog(false)}
        connectWallet={connectWalletNoLinkingWrapper}
      />
    </div>
  )
}

export default TestWallet
