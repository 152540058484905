import mixpanel from 'mixpanel-browser'
import { ConnectorNames } from '@verisart/nft/src'

type ShopifyClaimStartedProps = {
  walletType: ConnectorNames | undefined
}

export const trackShopifyClaimStarted = (
  eventProps: ShopifyClaimStartedProps
): void => {
  mixpanel.track('Shopify Claim Started', {
    'Wallet Type': eventProps.walletType,
  })
}

type ShopifyTransferProps = {
  walletType: ConnectorNames | undefined
  fromAddress: string
  toAddress: string
}

export const trackNftTransferStarted = (
  eventProps: ShopifyTransferProps
): void => {
  mixpanel.track('Shopify NFT Transfer Started', {
    'Wallet Type': eventProps.walletType,
    'from address': eventProps.fromAddress,
    'to address': eventProps.toAddress,
  })
}

export const trackNftTransferCompleted = (
  eventProps: ShopifyTransferProps
): void => {
  mixpanel.track('Shopify NFT Transfer Completed', {
    'Wallet Type': eventProps.walletType,
    'from address': eventProps.fromAddress,
    'to address': eventProps.toAddress,
  })
}

type ShopifyTransferClickedProps = {
  walletType: ConnectorNames | undefined
  fromAddress: string
}

export const trackNftTransferButtonClicked = (
  eventProps: ShopifyTransferClickedProps
): void => {
  mixpanel.track('Shopify NFT Transfer Completed', {
    'Wallet Type': eventProps.walletType,
    Address: eventProps.fromAddress,
  })
}
