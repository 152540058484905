import { Artist, ArtistAttributes } from './Artist'
import { Blockchain } from '@verisart/nft/src'

export type PrimaryURLAndDisplayURL = {
  primaryUrl: string
  displayUrl?: string
}

export enum CertificateType {
  NONE = 'NONE',
  PRODUCT = 'PRODUCT',
  VERISART = 'VERISART',
}

export enum CertificateSource {
  VERISART = 'VERISART',
  SHOPIFY = 'SHOPIFY',
  VERISART_NFT = 'VERISART_NFT',
  SHOPIFY_NFT = 'SHOPIFY_NFT',
  WOOCOMMERCE = 'WOOCOMMERCE',
  WOOCOMMERCE_NFT = 'WOOCOMMERCE_NFT',
  VERISART_PARTNER = 'VERISART_PARTNER',
}

export enum Unit {
  CM = 'CM',
  INCH = 'INCH',
  PIXEL = 'PIXEL',
}

export enum ObjectType {
  VIDEO = 'VIDEO',
  NFT = 'NFT',
  PAINTING = 'PAINTING',
  SCULPTURE = 'SCULPTURE',
  PRINT = 'PRINT',
  DESIGN = 'DESIGN',
  COLLECTIBLE = 'COLLECTIBLE',
  PHOTO = 'PHOTO',
  DRAWING = 'DRAWING',
  MIXED_MEDIA = 'MIXED_MEDIA',
  FURNITURE = 'FURNITURE',
  BOOK = 'BOOK',
  EPHEMERA = 'EPHEMERA',
  DIGITAL_ARTWORK = 'DIGITAL_ARTWORK',
  OTHER = 'OTHER',
}

export enum FileType {
  FRONT = 'FRONT',
  BACK = 'BACK',
  DETAIL = 'DETAIL',
  DOCUMENT = 'DOCUMENT',
  OTHER = 'OTHER',
  PRIMARY = 'PRIMARY',
  OTHER_FILE = 'OTHER_FILE',

  /** Added for NFTs to indicate a static display image/thumbnail used when the primary image is a video */
  DISPLAY_IMAGE = 'DISPLAY_IMAGE',

  NFT_METADATA = 'NFT_METADATA',
}

export type ChangedFiles = {
  [filename: string]: EditCertificateFile
}

export type EditCertificateFile = {
  label?: string
  type?: FileType
  isPublic?: boolean
  isImage?: boolean
  isPrimary?: boolean
}

export type CertificateFile = {
  url: string
  fileName: string
  type: FileType
  isPublic: boolean
  isImage: boolean
  isPrimary: boolean
  label?: string
  externalHTML?: string
  id: string
}

export type CreateFileType = {
  type: FileType
  isPrimary: boolean
  isPublic: boolean
  isImage: boolean
}

// core has a different representation of files for creation
export type CreateCertificateFile = {
  url: string
} & CreateFileType

export enum ComputedConfidenceLevel {
  UNVERIFIED_RECORD = 'UNVERIFIED_RECORD',
  ISSUER_RECORD = 'ISSUER_RECORD',
  COA = 'COA',
  COA_PLUS = 'COA_PLUS',
}

export enum ComputedCertificateFeature {
  VERIFIED_ISSUER = 'VERIFIED_ISSUER',
  ARTIST_AUTHORIZED = 'ARTIST_AUTHORIZED',
  BASIC_METADATA = 'BASIC_METADATA',
  BASIC_METADATA_DIGITAL = 'BASIC_METADATA_DIGITAL',
  ENHANCED_METADATA = 'ENHANCED_METADATA',
  SECURE_QR_ATTACHED = 'SECURE_QR_ATTACHED',
  NFT_TESTNET = 'NFT_TESTNET',
  PRINTED_CERTIFICATE_ISSUED = 'PRINTED_CERTIFICATE_ISSUED',
  NFC_ATTACHED = 'NFC_ATTACHED',
  VERIFIED_ISSUER_BUSINESS = 'VERIFIED_ISSUER_BUSINESS',
}

export type Owner = {
  shop?: string
  name?: string
  account?: string
}

/**
 * Extends the raw response from the server with some useful additional properties
 * from the latest version.
 *
 * These additional fields are added in useGetCertificate.ts
 */
export type CertificateData = {
  /** Convenience way of accessing the latest version */
  latest: CertificateVersion

  /** Convenience way of accessing the first version */
  first: CertificateVersion

  /** Details as of the latest version */
  public: VPublicDetails
  private: VPrivateDetails | null | undefined
} & CertificateDataRaw

export type BitcoinTransaction = {
  id: string
  isoDateTime: string
}

export type IdentifiedEdition = {
  id?: string
  info: Edition
}

export type Edition = {
  number?: number
  volume?: number
  apNumber?: number
  apVolume?: number
  note?: string
}

export type ArtistProof = {
  volume?: number
}

export type Medium = {
  description: string
}

export type Dimensions = {
  width: number
  height: number
  depth?: number | null
  unit: Unit
}

export type VerisartCertificateArtist = {
  id: string
  attributes: ArtistAttributes

  /**
   * If not null then the issuer had an authorised relationship with the artist
   * at this version. If this is the latest version, then you can consider the certificate
   * a COA
   */
  relationship: ArtistRelationship | undefined
  signatureImage: string | undefined
  signatureImageHash: string | undefined
}

export type Project = {
  type: 'FAIR_TRADE_ART'
  promotionLogo: string
}

export type QRSecureCertificate = {
  type: QrSecureType
  serial: string | undefined
  linkedAt: string
  linkedBy: QrSecureLinkedBy
}

export type Trait = {
  traitType?: string
  value?: string | number
  displayType?: string
}

/**
 * Provides a way of grouping together certificates which originated from
 * the same product/variant without revealing any information.
 *
 * (You probably want to use ProductPublic).
 *
 * See core docs for more details
 */
export type ProductPublic = {
  productHash: string | null | undefined
  variantHash: string | null | undefined
}

enum PrintOrigin {
  NFT = 'NFT',
  PREDEFINED = 'PREDEFINED',
}

enum PrintFlags {
  COMPOSED = 'COMPOSED',
}

enum PrintNFTAssetType {
  IMAGE = 'IMAGE',
  GENERATIVE_HTML = 'GENERATIVE_HTML',
}

type PrintNFT = {
  blockchain: Blockchain
  contractAddress: string
  tokenId: string
  metadataURI: string
  metadataCapturedAt: Date
  assetURI: string
  assetType: PrintNFTAssetType
  assetDimensions: Dimensions
  assetCapturedAt: Date
}

type Print = {
  origin: PrintOrigin
  nft?: PrintNFT
  flags: Set<PrintFlags>
}

export type VPublicDetails = {
  title: string
  type?: CertificateType.VERISART
  objectType?: ObjectType
  objectTypeOther?: string
  edition?: Edition
  editionObject?: string
  productionYear?: number
  productionYearEnd?: number
  productionLocation?: Location
  location?: Location
  medium?: Medium
  dimensions?: Dimensions
  legacy: Record<string, any> | undefined
  customization?: Customization | undefined
  product?: ProductPublic | null | undefined
  confidenceLevel: ComputedConfidenceLevel
  features: ComputedCertificateFeature[]
  // Use `computedConfidenceLevel` and `computedFeatures` in the CertificateData type
  // confidenceLevel: ComputedConfidenceLevel
  // features: ComputedCertificateFeature[]
  /** At the time of writing this will always be exactly length 1. This is a list to support future enhancements. */
  artists?: VerisartCertificateArtist[]
  notes?: Record<string, string>
  terms?: Terms
  tags?: string[]
  nft?: NFT
  duration?: Duration
  project?: Project
  description?: string
  linkedQr?: QRSecureCertificate[]
  traits?: Trait[]
  inventoryNumber?: string
  print?: Print
}

export type Location = {
  description: string
}

export enum EditAllowed {
  NONE = 'NONE',
  LIMITED = 'LIMITED',
  FULL = 'FULL',
}

export type ArtistWithCollaborators = {
  collaborationMembers: Artist[]
} & Artist

export enum QrSecureType {
  PDF = 'PDF',
  LABEL = 'LABEL',
  HOLOGRAPHIC = 'HOLOGRAPHIC',
  HOLOGRAPHIC_STICKER = 'HOLOGRAPHIC_STICKER',
  PRINTED_CERTIFICATE = 'PRINTED_CERTIFICATE',
  NFC_STICKER = 'NFC_STICKER',
}

export enum QrSecureLinkedBy {
  OWNER = 'OWNER',
  ISSUER = 'ISSUER',
}

export type QrSecureMatch = {
  type: QrSecureType
  serial: string | null
  linkedAt: string
  linkedBy: QrSecureLinkedBy

  /** Set to true if the user arrived at the cert page with the appropriate secret */
  matchedInRequest: boolean
}

/** Raw response from core API */
export type CertificateDataRaw = {
  id: string
  createdAt: string
  qr: string

  /** Set if the cert has a QR holographic sticker attached */
  qrSecureSticker?: QrSecureMatch
  issuedByRequester: boolean
  ownedByRequester: boolean
  editAllowed: EditAllowed
  versions: CertificateVersion[]
  customization?: Customization
  artist?: ArtistWithCollaborators
  certificateVisibility: CertificateVisibility
  signatureImage: string | undefined
  artistOwnerIsVerified: boolean
  computedConfidenceLevel: ComputedConfidenceLevel
  computedFeatures: ComputedCertificateFeature[]
  qrLinkable: boolean
  canonicalUrl: string
  source: CertificateSource
}

export enum CertificateVersionType {
  CREATE = 'CREATE',
  TRANSFER = 'TRANSFER',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type CertificateVersion = {
  createdAt: string
  public: VPublicDetails
  private: VPrivateDetails | null | undefined
  versionType: CertificateVersionType
  extendedVersionType?: string
  otsCompleteAt?: string
  otsTransactionId?: string
  files: CertificateFile[]
  publicImages: CertificateFile[]
  owner?: Owner
  privateImagesCount: number
  privateFilesCount: number
  ownerPublicKey?: string
  ownerPublicKeySecp?: string
  isOwnerVerified: boolean
  versionNote: string | null
}

export type Customization = {
  logo?: string
  link?: string
  background?: string
  issuerName?: string
  issuerNote?: string
  issuerLink?: string
  subheading?: string
  templateName?: string
  templateId?: string
  id?: string
  promotionLogo?: string
  subtitleLogo?: string
}

export type ListCertificateDataWithCount = {
  itemCount: number
  data: ListCertificateData[]
  switchedFilter?: boolean
}

export type EditionGroup = {
  id: string
  volume?: number
  apVolume?: number
  editionsCount: number
  apCount: number
  editions?: string
  apEditions?: string
  deletedAt?: string
}

export type ListCertificateData = {
  title: string
  certificateId: string | undefined
  thumbnail: string
  artist?: Artist
  ownedByRequester: boolean
  issuedByRequester: boolean
  visibility: CertificateVisibility
  productionYear?: number
  productionYearEnd?: number
  singularEdition?: IdentifiedEdition
  editionGrouped?: EditionGroup
  transferableAfter?: string | null
  nft: NFT | undefined
  issuer?: Owner
  mintDraftId?: string
  images: PrimaryURLAndDisplayURL
  nftTestnet: boolean
  coaorHigher?: boolean
  objectType?: ObjectType
}

export type CertificateRef = {
  id: string
}

export type UploadFile = {
  url: string
  type: FileType
  isPublic: boolean
  isImage: boolean
  isPrimary: boolean
  label?: string
}

/**
 * Used with [MultiDropzone] to represent a file that has been uploaded
 *
 * IMPORTANT: These values must be represented as hidden inputs in the form or the data will be lost.
 */
export type CertifyFormFile = {
  /**
   * The file ID of an existing file (this is only set when editing an existing certificate
   * and is set to the value of [CertificateFile.id])
   */
  existingFileId: string | undefined

  /**
   * Indicates that the file has been changed
   * (for complex legacy reasons it was easier to do this that to try and use
   * react-hook-form to track changes)
   */
  isChanged?: 'true'

  /**
   * Indicates that the file has been deleted
   * (for complex legacy reasons it was easier to do this that to try and use
   * react-hook-form to track changes)
   */
  isDeleted?: 'true'

  /**
   * The url of the file. If editing an existing certificate this will be set
   * to the existing URL. When adding a new file, this will be a URL in the
   * temporary S3 bucket
   */
  url: string

  /**
   * The file type (only relevant when adding images)
   */
  type: FileType | undefined

  /**
   * The label (only relevant when adding additionalFiles/collector rewards)
   */
  label: string | undefined
}

/**
 * Used when setting public details for the first time
 */
export type CreateVPublicDetails = {
  title: string
  objectType?: string
  objectTypeOther?: string | null
  edition?: Edition | null
  productionYear?: number | null
  productionYearEnd?: number | null
  productionLocation?: Location | null
  location?: Location | null
  medium?: Medium
  dimensions?: Dimensions | null
  artist?: string
  notes?: Record<string, string> | null
  customizationId?: string | null
  nft?: NFT | null
  duration?: Duration | null
  description?: string | null
  inventoryNumber?: string | null
}

/**
 * Used when modifying individual fields of
 * public details (non null values indicates a change)
 */
export type EditVPublicDetails = {
  title?: string | null
  objectType?: string | null
  objectTypeOther?: string | null
  productionYear?: number | null
  productionYearEnd?: number | null
  productionLocation?: Location | null
  location?: Location | null
  medium?: Medium | null
  dimensions?: Dimensions | null
  artist?: string | null
  notes?: Record<string, string> | null
  nft?: NFT | null
  duration?: Duration | null
  description?: string | null
  customization?: string | null
  inventoryNumber?: string | null
  clearFields?: string[] | null
}

export type NFT = {
  blockchain: Blockchain
  mintingPlatform?: string | null
  mintingPlatformOther?: string | null
  contractAddress: string
  tokenId: string
  ownerAddress: string
  link?: string | null
  blockNumber?: string | null
  timestamp?: string | null
  draft?: boolean | null
  tags?: string[]
  transactionId?: string | null
  metadataURI?: string | null
  previousOwnerAddress?: string | null
}

export type Duration = {
  hours: number
  minutes: number
  seconds: number
}

export type Terms = {
  transferableAfter?: string | null
}

type TokenGatePurchase = {
  blockchain: Blockchain
  contractAddress: string
  tokenId: string
  claimedBy: string
}

export type ProductPrivate = {
  shop: string | null
  orderName: string | null
  tokenGate: TokenGatePurchase | null | undefined
}

export type VPrivateDetails = {
  inventoryNumber?: string | null
  notes?: Record<string, string> | null
  product?: ProductPrivate | null
}

export type EditCertificateRequest = {
  changes: EditVPublicDetails
  privateChanges: VPrivateDetails | null
  newFiles: UploadFile[]
  deletedFiles: string[]
  changedFiles: ChangedFiles
  isPrivate: boolean
  qrLinkable: boolean | null
  linkQRBySerial: string | null
}

export type CreateCertificateRequest = {
  verisartCertificate: CreateVPublicDetails
  verisartCertificatePrivate: VPrivateDetails
  files: UploadFile[]
  isPrivate: boolean
  isFairTrade: boolean
  qrLinkable: boolean
  linkQRBySerial: string | null
}

export type CreateEditionRequestExtras = {
  apVolume: number | null
  volume: number | null
  editions: string | null
  apEditions: string | null
  unnumberedEditions: number | null
  unnumberedApEditions: number | null
  editionNote: string | null
}

export type CreateEditionRequest = CreateEditionRequestExtras &
  CreateCertificateRequest

export type EditEditionRequest = {
  editions: string | null
  apEditions: string | null
  addUnnumberedEditions?: number | null
  addUnnumberedApEditions: number | null
  changes: EditVPublicDetails | null
  privateChanges: VPrivateDetails | null
  newFiles: UploadFile[]
  deletedFiles: string[]
  changedFiles: ChangedFiles
  volume: number | null
  apVolume: number | null
  note: string | null
  clearVolume: boolean | null
  clearApVolume: boolean | null
  qrLinkable: boolean | null
  linkQRBySerial: string | null
  visibility: CertificateVisibility | null
}

export type MutateResponse = {
  data: { id: string }
}

export type ArtistRelationshipDetails = {
  artistId: string
  accountId: string
  type: ArtistRelationship
  status: string
  signatureImage?: string
  signatureImageHash?: string
}

export enum ArtistRelationship {
  SELF = 'SELF',
  REPRESENTS = 'REPRESENTS',
}

export enum CertificateVisibility {
  OWNER = 'OWNER',
  PUBLIC = 'PUBLIC',
}

export type Signature = {
  url: string
}

export type ProductionYears = {
  productionYear: number
  productionYearEnd: number | null | undefined
}

export function toProductionYears(obj: {
  productionYear?: number | null
  productionYearEnd?: number | null
}): ProductionYears | null {
  if (!obj.productionYear && obj.productionYear !== 0) return null
  return {
    productionYear: obj.productionYear,
    productionYearEnd: obj.productionYearEnd,
  }
}
