import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 186 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="93" cy="93" r="93" fill="white" />
    <path
      d="M48.2919 151.975L45.9605 160.713L46.054 160.774L53.1305 155.146L54.9483 156.337L45.7524 163.353L43.7119 162.016L46.4786 150.787L48.2919 151.975Z"
      fill="black"
    />
    <path
      d="M55.0513 168.606L59.3198 158.567L65.8493 161.343L65.2011 162.868L60.4903 160.865L59.3314 163.59L63.704 165.449L63.0558 166.974L58.6832 165.115L57.5181 167.855L62.2682 169.874L61.62 171.399L55.0513 168.606Z"
      fill="black"
    />
    <path
      d="M69.3104 173.977L71.7946 163.354L75.778 164.286C76.594 164.477 77.2455 164.779 77.7325 165.192C78.2229 165.605 78.55 166.101 78.7139 166.679C78.882 167.255 78.8868 167.881 78.7283 168.559C78.569 169.24 78.2855 169.798 77.8778 170.231C77.4744 170.662 76.9606 170.948 76.3366 171.09C75.7133 171.229 74.9937 171.203 74.1776 171.012L71.3405 170.349L71.7141 168.751L74.2919 169.354C74.7691 169.466 75.1752 169.491 75.5102 169.431C75.846 169.367 76.1165 169.225 76.3215 169.003C76.5309 168.778 76.6796 168.477 76.7678 168.1C76.8559 167.724 76.8568 167.385 76.7703 167.084C76.6847 166.779 76.5059 166.522 76.234 166.313C75.9629 166.1 75.587 165.937 75.1063 165.825L73.3428 165.412L71.2347 174.427L69.3104 173.977ZM75.9236 170.447L77.4277 175.875L75.2804 175.373L73.823 169.956L75.9236 170.447Z"
      fill="black"
    />
    <path
      d="M87.1524 166.045L86.2137 176.914L84.2449 176.744L85.1835 165.875L87.1524 166.045Z"
      fill="black"
    />
    <path
      d="M99.8998 169.036C99.8234 168.575 99.5935 168.225 99.2099 167.987C98.8298 167.75 98.3473 167.647 97.7623 167.681C97.3511 167.705 97.0019 167.787 96.7148 167.928C96.4277 168.069 96.2122 168.25 96.0685 168.472C95.9247 168.694 95.8589 168.941 95.871 169.214C95.8841 169.441 95.9468 169.635 96.0592 169.796C96.1751 169.956 96.3269 170.09 96.5144 170.196C96.7017 170.3 96.9075 170.384 97.1318 170.449C97.3561 170.514 97.5815 170.567 97.8079 170.608L98.8436 170.804C99.2604 170.876 99.6631 170.983 100.052 171.124C100.444 171.265 100.798 171.451 101.114 171.682C101.433 171.913 101.691 172.198 101.889 172.539C102.086 172.88 102.198 173.286 102.225 173.758C102.262 174.396 102.131 174.967 101.833 175.472C101.535 175.973 101.085 176.377 100.484 176.686C99.8858 176.99 99.1508 177.168 98.2787 177.218C97.4313 177.267 96.6882 177.178 96.0491 176.951C95.4136 176.725 94.9059 176.37 94.5259 175.886C94.1496 175.403 93.9264 174.8 93.8565 174.079L95.7975 173.967C95.8477 174.345 95.9828 174.654 96.2029 174.894C96.423 175.134 96.7015 175.306 97.0383 175.411C97.3787 175.516 97.7545 175.557 98.1658 175.533C98.5948 175.508 98.9669 175.423 99.2822 175.277C99.6008 175.127 99.8457 174.931 100.017 174.69C100.188 174.446 100.266 174.167 100.252 173.855C100.232 173.572 100.135 173.343 99.9612 173.168C99.7873 172.989 99.5497 172.846 99.2483 172.739C98.9503 172.628 98.6034 172.534 98.2077 172.457L96.9503 172.21C96.0401 172.031 95.3109 171.722 94.7629 171.284C94.2182 170.843 93.9234 170.232 93.8785 169.452C93.8416 168.81 93.983 168.238 94.3028 167.736C94.626 167.234 95.0814 166.832 95.6688 166.532C96.256 166.228 96.9307 166.054 97.6929 166.01C98.4658 165.965 99.1507 166.061 99.7476 166.297C100.348 166.529 100.828 166.873 101.189 167.329C101.549 167.782 101.752 168.314 101.798 168.927L99.8998 169.036Z"
      fill="black"
    />
    <path
      d="M110.973 175.458L108.922 175.95L110.111 164.446L112.484 163.877L118.769 173.588L116.717 174.08L111.861 166.327L111.778 166.347L110.973 175.458ZM110.043 171.283L115.637 169.941L116.007 171.484L110.413 172.826L110.043 171.283Z"
      fill="black"
    />
    <path
      d="M125.227 171.315L120.844 161.326L124.59 159.682C125.357 159.345 126.059 159.196 126.697 159.234C127.337 159.271 127.896 159.472 128.374 159.836C128.854 160.196 129.234 160.694 129.513 161.331C129.794 161.972 129.902 162.588 129.836 163.179C129.771 163.766 129.532 164.304 129.118 164.792C128.702 165.276 128.111 165.687 127.344 166.024L124.675 167.195L124.016 165.692L126.44 164.629C126.889 164.432 127.229 164.209 127.461 163.96C127.692 163.707 127.823 163.431 127.854 163.13C127.886 162.825 127.825 162.495 127.67 162.141C127.514 161.786 127.311 161.514 127.062 161.325C126.811 161.133 126.513 161.035 126.17 161.03C125.825 161.022 125.427 161.118 124.975 161.316L123.317 162.044L127.037 170.521L125.227 171.315ZM128.401 164.524L132.861 167.966L130.841 168.852L126.426 165.391L128.401 164.524Z"
      fill="black"
    />
    <path
      d="M133.397 157.17L132.481 155.789L139.734 150.977L140.649 152.357L137.84 154.222L142.955 161.931L141.322 163.015L136.206 155.305L133.397 157.17Z"
      fill="black"
    />
    <path
      d="M6.60662 88.1639L6.73643 86.192L13.8482 86.6601C14.6277 86.7114 15.3013 86.9408 15.8688 87.3483C16.4365 87.7522 16.8651 88.2965 17.1546 88.981C17.4405 89.6654 17.5543 90.4505 17.496 91.3363C17.4374 92.2257 17.2216 92.9909 16.8484 93.6318C16.4717 94.2725 15.9755 94.7559 15.3597 95.0819C14.7439 95.408 14.0462 95.5453 13.2667 95.494L6.15491 95.0258L6.28472 93.0539L13.2317 93.5112C13.6853 93.5411 14.0957 93.4684 14.4631 93.2933C14.8308 93.1147 15.1276 92.8495 15.3536 92.4978C15.5761 92.1459 15.7038 91.7201 15.7366 91.2205C15.7695 90.7209 15.6988 90.2821 15.5244 89.904C15.3466 89.5222 15.0871 89.2204 14.7459 88.9986C14.4046 88.7769 14.0071 88.6511 13.5536 88.6212L6.60662 88.1639Z"
      fill="black"
    />
    <path
      d="M10.9569 67.4722L21.422 70.553L20.9256 72.2393L12.3456 75.0718L12.3201 75.1587L19.4483 77.2572L18.8902 79.153L8.42521 76.0721L8.92464 74.3757L17.5082 71.5497L17.5353 71.4578L10.4019 69.3578L10.9569 67.4722Z"
      fill="black"
    />
    <path
      d="M15.4841 56.8618L24.3801 58.4903L24.4338 58.3921L18.2594 51.7862L19.3021 49.8794L27.0286 58.4875L25.8581 60.628L14.444 58.7639L15.4841 56.8618Z"
      fill="black"
    />
    <path
      d="M32.5362 49.9611L24.2029 42.9209L28.7819 37.501L30.0473 38.5701L26.7438 42.4804L29.0061 44.3917L32.0725 40.7622L33.3379 41.8313L30.2715 45.4608L32.5461 47.3824L35.8772 43.4396L37.1426 44.5087L32.5362 49.9611Z"
      fill="black"
    />
    <path
      d="M42.5399 39.1575L35.7256 30.6386L38.9202 28.0832C39.5746 27.5597 40.2147 27.2342 40.8402 27.1066C41.4686 26.9769 42.0606 27.0263 42.6163 27.2548C43.1726 27.4784 43.6681 27.8619 44.1029 28.4054C44.5398 28.9517 44.8027 29.5191 44.8914 30.1075C44.9806 30.6909 44.8883 31.2719 44.6143 31.8503C44.338 32.426 43.8727 32.9755 43.2183 33.499L40.9429 35.3191L39.9181 34.0379L41.9855 32.3842C42.3681 32.0781 42.6394 31.7748 42.7991 31.4742C42.9566 31.1708 43.0115 30.87 42.9639 30.5716C42.9167 30.2682 42.7723 29.9654 42.5305 29.6631C42.2887 29.3609 42.0228 29.1507 41.7328 29.0325C41.4405 28.9116 41.128 28.8933 40.7952 28.9776C40.4602 29.0591 40.1 29.254 39.7146 29.5623L38.3003 30.6936L44.0832 37.9231L42.5399 39.1575ZM43.8531 31.7771L49.0498 33.9503L47.3277 35.3278L42.1684 33.1246L43.8531 31.7771Z"
      fill="black"
    />
    <path
      d="M50.8984 20.0707L56.2477 29.5782L54.5254 30.5472L49.1761 21.0397L50.8984 20.0707Z"
      fill="black"
    />
    <path
      d="M63.2341 26.0886L59.4238 15.8666L65.9722 13.4256L66.5509 14.9779L61.8541 16.7286L62.8886 19.5037L67.1361 17.9204L67.7147 19.4727L63.4672 21.056L65.0858 25.3984L63.2341 26.0886Z"
      fill="black"
    />
    <path
      d="M76.1193 10.852L78.2985 21.5413L76.3621 21.936L74.183 11.2468L76.1193 10.852Z"
      fill="black"
    />
    <path
      d="M86.0077 20.3367L85.5832 9.43586L92.673 9.15975L92.7374 10.8151L87.6223 11.0143L87.7376 13.9737L92.4854 13.7888L92.5499 15.4442L87.802 15.6291L87.9179 18.6044L93.0756 18.4036L93.1401 20.0589L86.0077 20.3367Z"
      fill="black"
    />
    <path
      d="M104.375 21.0025L100.727 20.4056L102.489 9.63962L106.21 10.2486C107.279 10.4235 108.162 10.7892 108.859 11.3459C109.56 11.8996 110.052 12.6115 110.334 13.4817C110.616 14.3519 110.666 15.346 110.483 16.464C110.3 17.5854 109.933 18.515 109.384 19.2528C108.839 19.9912 108.14 20.512 107.288 20.8152C106.44 21.119 105.469 21.1814 104.375 21.0025ZM102.953 19.0372L104.557 19.2996C105.307 19.4223 105.955 19.3879 106.5 19.1966C107.047 19.0017 107.491 18.6516 107.833 18.1462C108.175 17.6372 108.414 16.971 108.548 16.1474C108.683 15.3239 108.669 14.6198 108.505 14.0353C108.343 13.4474 108.036 12.978 107.586 12.6273C107.14 12.2737 106.553 12.0372 105.824 11.918L104.163 11.6462L102.953 19.0372Z"
      fill="black"
    />
    <path
      d="M124.342 26.8681L129.38 17.1921L133.008 19.0814C133.752 19.4685 134.308 19.9223 134.678 20.443C135.05 20.9653 135.244 21.5268 135.26 22.1275C135.28 22.7266 135.13 23.3348 134.808 23.9522C134.485 24.5727 134.072 25.0424 133.57 25.3614C133.072 25.6788 132.504 25.8291 131.864 25.8122C131.226 25.7922 130.535 25.5887 129.792 25.2016L127.207 23.856L127.965 22.4008L130.313 23.6235C130.748 23.8498 131.135 23.9753 131.474 23.9999C131.815 24.0214 132.113 23.95 132.367 23.7858C132.625 23.6201 132.844 23.3656 133.022 23.0223C133.201 22.679 133.286 22.3508 133.277 22.0377C133.269 21.7215 133.16 21.4283 132.948 21.1579C132.738 20.8845 132.414 20.6338 131.976 20.4058L130.37 19.5694L126.095 27.7807L124.342 26.8681ZM131.623 25.0864L131.736 30.7181L129.78 29.6996L129.71 24.0901L131.623 25.0864Z"
      fill="black"
    />
    <path
      d="M137.079 34.2552L143.893 25.7362L149.434 30.1682L148.399 31.4619L144.401 28.2643L142.551 30.5771L146.262 33.545L145.227 34.8387L141.517 31.8707L139.657 34.196L143.687 37.4201L142.652 38.7138L137.079 34.2552Z"
      fill="black"
    />
    <path
      d="M159.693 46.533L158.428 44.9946C158.644 44.7432 158.801 44.4784 158.898 44.2003C158.999 43.9199 159.045 43.6356 159.038 43.3472C159.031 43.0589 158.969 42.7741 158.852 42.4929C158.741 42.2121 158.579 41.9428 158.367 41.6851C157.99 41.2271 157.541 40.9161 157.02 40.7518C156.501 40.5854 155.939 40.5837 155.332 40.7468C154.729 40.9077 154.111 41.2487 153.477 41.7699C152.833 42.3002 152.372 42.847 152.095 43.4105C151.824 43.9744 151.72 44.5244 151.784 45.0604C151.853 45.5969 152.074 46.0927 152.449 46.5479C152.656 46.8002 152.883 47.009 153.13 47.1742C153.381 47.3399 153.645 47.4563 153.921 47.5235C154.199 47.5935 154.484 47.6098 154.775 47.5725C155.069 47.5379 155.358 47.4424 155.643 47.286L156.901 48.8311C156.453 49.1029 155.975 49.2873 155.466 49.3842C154.959 49.4838 154.445 49.4906 153.923 49.4046C153.405 49.3163 152.901 49.1286 152.411 48.8414C151.925 48.5519 151.475 48.1562 151.062 47.6544C150.453 46.914 150.081 46.1122 149.945 45.2488C149.809 44.3854 149.928 43.5202 150.299 42.6531C150.671 41.7861 151.31 40.9803 152.215 40.2357C153.122 39.4888 154.037 39.0189 154.959 38.8259C155.884 38.6307 156.757 38.6828 157.578 38.982C158.399 39.2813 159.112 39.7984 159.716 40.5333C160.102 41.0022 160.395 41.4924 160.595 42.0038C160.795 42.5152 160.898 43.0335 160.902 43.5587C160.909 44.0816 160.813 44.5957 160.612 45.101C160.417 45.6067 160.11 46.0841 159.693 46.533Z"
      fill="black"
    />
    <path
      d="M167.456 63.5118C166.407 64.042 165.409 64.2996 164.462 64.2846C163.52 64.2712 162.681 64.027 161.943 63.5522C161.208 63.0805 160.624 62.4184 160.193 61.5658C159.763 60.7132 159.575 59.8493 159.63 58.974C159.689 58.1003 159.992 57.2787 160.539 56.5093C161.089 55.7415 161.888 55.0932 162.934 54.5647C163.983 54.0345 164.978 53.7761 165.92 53.7895C166.867 53.8045 167.708 54.0478 168.444 54.5195C169.181 54.9944 169.765 55.6581 170.196 56.5106C170.627 57.3632 170.814 58.2256 170.757 59.0977C170.702 59.973 170.399 60.7946 169.848 61.5624C169.302 62.3318 168.505 62.9816 167.456 63.5118ZM166.559 61.7385C167.298 61.3653 167.862 60.9349 168.252 60.4473C168.647 59.9612 168.872 59.4496 168.927 58.9126C168.985 58.374 168.88 57.84 168.613 57.3107C168.345 56.7814 167.978 56.3804 167.51 56.1077C167.045 55.8334 166.499 55.7095 165.871 55.736C165.249 55.7641 164.568 55.9647 163.83 56.3379C163.092 56.7111 162.525 57.1408 162.13 57.6269C161.74 58.1145 161.516 58.6276 161.458 59.1663C161.403 59.7033 161.509 60.2364 161.777 60.7657C162.044 61.295 162.41 61.6968 162.875 61.9712C163.343 62.2439 163.888 62.3662 164.511 62.3381C165.138 62.3116 165.821 62.1117 166.559 61.7385Z"
      fill="black"
    />
    <path
      d="M164.986 72.5279L175.528 69.7197L176.581 73.6727C176.796 74.4825 176.837 75.1995 176.702 75.8234C176.567 76.4509 176.284 76.9729 175.851 77.3896C175.422 77.8088 174.872 78.108 174.199 78.2871C173.523 78.4672 172.898 78.4794 172.324 78.3237C171.754 78.1705 171.259 77.8521 170.84 77.3684C170.424 76.8838 170.108 76.2366 169.893 75.4268L169.143 72.6113L170.728 72.189L171.41 74.7471C171.536 75.2207 171.704 75.5911 171.915 75.8583C172.129 76.1246 172.383 76.296 172.675 76.3725C172.972 76.4515 173.307 76.4411 173.681 76.3415C174.055 76.2419 174.354 76.0831 174.579 75.8651C174.808 75.6463 174.95 75.3676 175.007 75.029C175.067 74.6896 175.034 74.2814 174.907 73.8044L174.441 72.0544L165.495 74.4375L164.986 72.5279ZM171.213 76.7012L167.132 80.5833L166.564 78.4523L170.658 74.6165L171.213 76.7012Z"
      fill="black"
    />
    <path
      d="M168.371 90.5933L168.152 86.9031L179.042 86.2571L179.265 90.0218C179.329 91.103 179.166 92.0447 178.776 92.8469C178.39 93.6524 177.803 94.2884 177.016 94.7549C176.229 95.2213 175.271 95.4881 174.14 95.5552C173.005 95.6224 172.018 95.4693 171.178 95.0959C170.338 94.7259 169.676 94.1586 169.193 93.394C168.71 92.6329 168.436 91.6993 168.371 90.5933ZM169.976 88.7746L170.072 90.3964C170.117 91.155 170.293 91.7795 170.599 92.27C170.909 92.7604 171.348 93.1167 171.917 93.3392C172.488 93.5614 173.191 93.6478 174.024 93.5984C174.857 93.549 175.54 93.3803 176.075 93.0925C176.613 92.8045 177.003 92.4025 177.247 91.8864C177.494 91.3737 177.595 90.7487 177.552 90.0114L177.452 88.3311L169.976 88.7746Z"
      fill="black"
    />
  </svg>
)

export default SvgComponent
