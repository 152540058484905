const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']
const videoExtensions = ['.mp4', '.mov', '.wmv']
const modelExtensionsMain = ['.glb', '.gltf']

export const imageFileTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
]

const videoFileTypes = ['video/mp4', 'video/quicktime']

const uppyModelFileTypesMain = [
  'model/gltf+json',
  'model/gltf-binary',

  // The current version of Uppy doesn't seem to support GLB mime types. Fortunately we can add
  // file extensions. But this requires an extra workaround to map back into MIME - see fixUppyType()
  ...modelExtensionsMain,
]

/**
 * File types for use with Uppy which are "presentational" - i.e. something which can be used as a primary asset in
 * a certificate or NFT.
 *
 * Note that because of the GLB issue, these are a mixture of MIME type and file types
 */
export const uppyPresentationalFileTypes = [
  ...imageFileTypes,
  ...videoFileTypes,
  ...uppyModelFileTypesMain,
]

/**
 * File types which can be added as additional files (non presentational).
 * Note that you can still add presentational files here - they just won't be rendered as such on COAs.
 *
 * Note that because of the GLB issue, these are a mixture of MIME type and file types
 */
export const uppyAdditionalFileTypes = [
  'application/pdf',
  'text/plain',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'video/x-ms-wmv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'audio/mpeg',
  'audio/mp3',
  ...uppyPresentationalFileTypes,
  '.vrm',
  '.fbx',
  'model/gltf-binary+vrm',
  'model/fbx',
  'application/zip',
]

/**
 * Note that because of the GLB issue, these are a mixture of MIME type and file types
 */
export const uppyNFTFileTypes = [
  ...imageFileTypes,
  ...uppyModelFileTypesMain,
  'video/mp4',
]

/**
 * Uppy doesn't handle GLB mime types so we have to map back to the mime type
 * @see uppyModelFileTypesMain
 */
export function fixUppyType(
  type: string | undefined,
  extension: string | undefined,
  name: string
): string | undefined {
  if (type === 'application/octet-stream') {
    let fixedExtension = extension ?? name
    const dot = fixedExtension.lastIndexOf('.')
    if (dot !== -1) {
      fixedExtension = fixedExtension.substring(dot + 1)
    }

    if (fixedExtension === 'glb') {
      return 'model/gltf-binary'
    } else if (fixedExtension === 'gltf') {
      return 'model/gltf+json'
    } else if (fixedExtension === 'vrm') {
      return 'model/gltf-binary+vrm'
    } else if (fixedExtension === 'fbx') {
      return 'model/fbx'
    } else {
      throw new Error(
        `Found application/octet-stream type for unknown extension ${fixedExtension}`
      )
    }
  } else {
    return type
  }
}

export const contentTypeToName = {
  'application/pdf': 'PDF',
  'text/plain': 'text',
  'text/csv': 'CSV',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word document',
  'application/msword': 'Word document',
  'video/mp4': 'MP4',
  'video/quicktime': 'Quicktime',
  'video/x-ms-wmv': 'WMV',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Excel spreadsheet',
  'application/vnd.ms-excel': 'Excel spreadsheet',
  'image/jpg': 'JPEG',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'audio/mpeg': 'MP3',
  'audio/mp3': 'MP3',
  'model/gltf+json': 'glTF JSON',
  'model/gltf+binary': 'glTF Binary',
  'model/gltf-binary+vrm': 'VRM',
  'model/fbx': 'FBX',
}

export const isVideoUrl = (url: string): boolean => {
  return videoExtensions.some((it) => url?.toLowerCase().endsWith(it))
}

export const isGif = (url: string): boolean =>
  url.toLowerCase().endsWith('.gif')

export const isModel = (url: string): boolean =>
  modelExtensionsMain.some((it) => url.toLowerCase().endsWith(it))

export const isImage = (url: string): boolean =>
  imageExtensions.some((it) => url.toLowerCase().endsWith(it))

export const isHTML = (url: string): boolean =>
  url.toLowerCase().endsWith('.html')

export const truncateFilename = (filename: string): string => {
  const maxLength = 20
  return filename.length > maxLength
    ? filename.slice(0, maxLength) + '...'
    : filename
}
