type ClassNamesParam = string | Record<string, boolean | undefined> | undefined

/**
 * Creates a string for a `className` property with appropriate spacing between items.
 *
 * The goal behind this function is to help separating class names when conditionally building strings. It's quite easy
 * to have a subtle bug in the concatenation of classes. This becomes even more challenging when you have multiple
 * concatenations to deal with. Consider the following snippet:
 *
 * ```
 * <div className={`text-bold border border-red${spacer ? 'p-10' : ''} ${light ? 'text-secondary' : 'text-white'}`}</div>
 * ```
 *
 * This will result in `border border-redp-10`, if class list has multiple conditionals this can be a subtle bug to spot.
 *
 * Using this utility function you can now rewrite the above line as the following:
 *
 * ```
 * <div className={classNames(
 *   'text-bold border border-red',
 *   light ? 'text-secondary' : 'text-white',
 *   {
 *     ['p-10']: spacer
 *   }
 * )}>
 * ```
 *
 * The object that you pass in can have multiple classes and conditions deciding how to conditionally build up the
 * className value.
 */
export const classNames = (...classNames: ClassNamesParam[]) => {
  const finalClassNames = new Set<string>()
  classNames.forEach((className) => {
    if (typeof className === 'string') {
      finalClassNames.add(className.trim())
    }
    if (typeof className === 'object') {
      Object.keys(className).forEach((key) => {
        if (className[key] === true) {
          finalClassNames.add(key.trim())
        }
      })
    }
  })

  return Array.from(finalClassNames).join(' ')
}
