import Text from '../../atoms/Text'
import { ReactComponent as IcoInfo } from './ico-info.svg'

type NotificationStyle = 'info' | 'error' | 'warning'

interface NotificationProps {
  body: React.ReactNode
  icon?: React.ReactNode
  style?: NotificationStyle
  title?: string
  additionalClasses?: string
}

const wrapperClasses = (style: NotificationStyle): string => {
  const classNames =
    'ver-w-full ver-p-5 ver-flex ver-flex-row ver-items-center ver-text-secondary ver-rounded-md'

  switch (style) {
    case 'info':
      return [classNames, 'ver-bg-outline'].join(' ')
    case 'error':
      return [classNames, 'ver-bg-surface-error'].join(' ')
    case 'warning':
      return [classNames, 'ver-bg-surface-warning'].join(' ')
  }
}

const icons: { [key in NotificationStyle]: React.ReactNode } = {
  error: <IcoInfo />,
  info: <IcoInfo />,
  warning: <IcoInfo />,
}

const Notification: React.VFC<NotificationProps> = ({
  body,
  title,
  additionalClasses,
  ...rest
}) => {
  const style = rest.style ?? 'info'
  const icon = rest.icon ?? icons[style]

  return (
    <div className={`${wrapperClasses(style)}  ${additionalClasses}`}>
      {icon && (
        <div className="ver-w-6 ver-h-6 ver-basis-6 ver-mr-5 ver-mt-2">
          {icon}
        </div>
      )}
      <div>
        {title && (
          <Text as="p" variant="large" bold>
            {title}
          </Text>
        )}
        <Text as="p" variant="small">
          {body}
        </Text>
      </div>
    </div>
  )
}

export default Notification
