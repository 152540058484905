const userAgent = (): string | undefined => {
  return window.navigator?.userAgent?.toLowerCase()
}

const device = () => {
  const find = (needle: string) => {
    const ua = userAgent()
    if (!ua) {
      return false
    }
    return ua.indexOf(needle) !== -1
  }

  return {
    ios(): boolean {
      return this.iphone() || this.ipod() || this.ipad()
    },
    iphone(): boolean {
      return !this.windows() && find('iphone')
    },
    ipod(): boolean {
      return find('ipod')
    },
    ipad(): boolean {
      const iPadOS13Up = find('macintel') && window.navigator.maxTouchPoints > 1
      return find('ipad') || iPadOS13Up
    },
    android(): boolean {
      return !this.windows() && find('android')
    },
    windows(): boolean {
      return find('windows')
    },
  }
}

export default device
