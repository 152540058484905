import { useState } from 'react'
import Text from '../../atoms/Text'
import style from './NotesText.module.css'
import t from '../../../lib/translation'
import { NoteWithPrivate } from '@verisart/shared/src/utils/CertificateNotesUtils'

type NotesTextProps = {
  notes: NoteWithPrivate[]
}

const MAX_CHAR_COUNT = 500
const NotesText: React.FC<NotesTextProps> = ({ notes }) => {
  const [open, setOpen] = useState<boolean>(false)

  const charNumber = notes
    .map((note) => note.note.length)
    .reduce((prev, next) => prev + next, 0)

  if (charNumber < MAX_CHAR_COUNT) {
    return (
      <ul>
        {notes.map((note, idx) => (
          <li key={idx} className="mb-3">
            {note &&
              !note.isPrivate &&
              note.note.split('\n').map((s, i) => {
                return (
                  <Text key={`description-${i}`}>
                    <p key={`description-${i}`}>{s}</p>
                    <br />
                  </Text>
                )
              })}
          </li>
        ))}
      </ul>
    )
  }
  return (
    <div>
      <div className="ver-relative">
        <ul
          className={`${
            !open ? `${style['topBottomOverflowFade']}` : 'ver-h-auto'
          }`}
        >
          {notes.map((note, idx) => (
            <li key={idx} className="mb-3">
              {note &&
                !note.isPrivate &&
                note.note.split('\n').map((s, i) => {
                  return (
                    <Text key={`description-${i}`}>
                      <p key={`description-${i}`}>{s}</p>
                      <br />
                    </Text>
                  )
                })}
            </li>
          ))}
        </ul>
        <div
          className={`${open ? 'ver-hidden' : `ver-absolute ${style['fade']}`}`}
        />
      </div>
      <span
        className={'ver-font-bold ver-cursor-pointer'}
        onClick={() => setOpen(!open)}
      >
        <Text>
          {!open ? t.dashboard.notes.readMore : t.dashboard.notes.readLess}
        </Text>
      </span>
    </div>
  )
}

export default NotesText
