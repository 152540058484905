import React from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../atoms/Text'
import TestNFTNotification from './TestNFTNotification'
import styles from './PreviewCard.module.css'
import { ReactComponent as TickHourglass } from '../atoms/CardTag/ico-hourglass.svg'
import { AssetView } from '@verisart/shared'

export interface Overlay {
  title: string
  message: string
}

export type PreviewCardProps = {
  image: string
  imageBadge?: React.ReactElement
  title: string
  className?: string
  href?: string
  openNewWindow?: boolean
  overlayMessage?: string | null
  nftTestnet: boolean
  overlay?: Overlay
}

const AssetOverlay: React.FC<Overlay> = ({ title, message }) => {
  return (
    <div className={`${styles['overlay']}`}>
      <div
        className={
          'ver-flex ver-px-5 ver-gap-4 ver-flex-col ver-h-full ver-justify-center ver-text-center'
        }
      >
        <div className={'ver-flex ver-justify-center ver-text-white'}>
          <TickHourglass />
        </div>
        <Text bold variant={'large'}>
          {title}
        </Text>
        <Text variant={'large'}>{message}</Text>
      </div>
    </div>
  )
}

export const PreviewCard: React.FC<PreviewCardProps> = ({
  href,
  openNewWindow,
  title,
  image,
  imageBadge,
  children,
  overlayMessage,
  nftTestnet,
  overlay,
}) => {
  const navigate = useNavigate()
  return (
    <article
      data-test="previewCard"
      onClick={
        href
          ? () => {
              if (openNewWindow) {
                window.open(href)
              } else {
                navigate(href)
              }
            }
          : undefined
      }
      className={'ver-flex ver-flex-col ver-justify-end ver-group'}
    >
      <div
        className={`ver-relative ver-border-x ver-border-t ver-border-solid ver-border-outline ${
          !!href && 'ver-cursor-pointer'
        }`}
      >
        {nftTestnet && <TestNFTNotification size="sm" />}
        {overlayMessage && (
          <div className="ver-absolute ver-h-full ver-w-full ver-flex ver-transition-opacity ver-items-center ver-justify-center ver-opacity-0 group-hover:ver-opacity-100 ">
            <div
              className={
                'ver-absolute ver-h-full ver-w-full ver-bg-secondary ver-opacity-70'
              }
            ></div>

            <div className="ver-relative ver-text-center ver-text-surface">
              <Text>
                <p>
                  {overlayMessage.substring(0, overlayMessage.indexOf('.') + 2)}
                </p>
                <p>
                  {overlayMessage.substring(overlayMessage.indexOf('.') + 1)}
                </p>
              </Text>
            </div>
          </div>
        )}
        <div className={'ver-relative ver-min-h-64'}>
          {overlay ? (
            <AssetOverlay title={overlay?.title} message={overlay?.message} />
          ) : null}
          <AssetView
            style={{ width: '100%' }}
            primaryUrl={image}
            alt={title}
            play={true}
            width={400}
          />
        </div>
        <div className="ver-absolute ver-bottom-0 ver-right-0 ver-p-2">
          {imageBadge}
        </div>
      </div>

      <div className={href ? 'ver-cursor-pointer' : ''}>
        {children && children}
      </div>
    </article>
  )
}
