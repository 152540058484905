import { VersionType } from '../../components/organisms/Claim'
import * as API from '../../api'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { NFTTransferStatus } from '../../api'
import {
  CertificateFile,
  CertificateVersion,
  ComputedCertificateFeature,
  NFT,
  ProductionYears,
  toProductionYears,
  VPrivateDetails,
  VPublicDetails,
  ComputedConfidenceLevel,
} from '@verisart/shared'
import { formatArtistName } from '@verisart/shared'
import {
  NoteWithPrivate,
  notesRecordsToForm,
} from '@verisart/shared/src/utils/CertificateNotesUtils'

const VIDEOS_PATTERN = /\.(mov|mp4|wmv)$/

export interface NFTWithVersionType {
  nft: NFT | null | undefined
  versionType: VersionType
  timestamp: string
}

export interface Certificate {
  artist: string | null
  productionYears: ProductionYears | null
  thumbnail: string
  title: string
  files: CertificateFile[]
  public: VPublicDetails
  publicImages: CertificateFile[]
  computedFeatures: ComputedCertificateFeature[]
  computedConfidenceLevel: ComputedConfidenceLevel
  notes: NoteWithPrivate[]
  private: VPrivateDetails | null | undefined
}

export const useGetCertificate = (
  id: string,
  getNftHistory?: boolean
): {
  certificate: Certificate | undefined
  isLoading: boolean
  nft: NFT | undefined | null
  versions: Array<NFTWithVersionType> | undefined
  withdrawalState: NFTTransferStatus | undefined
} => {
  const enabled = !!id // this only runs on the non empty string
  const { data, isLoading } = useQuery(
    ['certificates', id],
    async () => API.customer.getCertificate(id),
    {
      enabled,
      select(res: AxiosResponse) {
        return res.data
      },
    }
  )

  const latestVersion: CertificateVersion =
    data?.certificateData?.versions?.[data.certificateData.versions.length - 1]

  const certificate: Certificate | undefined = latestVersion
    ? {
        public: latestVersion.public,
        private: latestVersion.private,
        publicImages: latestVersion.publicImages,
        files: latestVersion.files,
        title: latestVersion.public.title,
        thumbnail:
          [
            latestVersion.publicImages.find(
              (image) =>
                image.type === 'DISPLAY_IMAGE' &&
                !image.url.match(VIDEOS_PATTERN)
            )?.url,
            latestVersion.publicImages.find(
              (image) => image.isPrimary && !image.url.match(VIDEOS_PATTERN)
            )?.url,
          ].filter((url) => !!url)[0] ?? '',
        artist: data.certificateData.artist?.name
          ? formatArtistName(data.certificateData.artist.name)
          : null,
        productionYears: toProductionYears(latestVersion.public),
        computedFeatures: data.certificateData.computedFeatures,
        computedConfidenceLevel: data.certificateData.computedConfidenceLevel,
        notes: notesRecordsToForm(
          latestVersion.private?.notes,
          latestVersion.public.notes
        ),
      }
    : undefined

  const nft = latestVersion?.public?.nft

  const versions: NFTWithVersionType[] | undefined = getNftHistory
    ? data?.certificateData.versions?.map((it: CertificateVersion) => {
        return {
          nft: it.public.nft,
          versionType: it.versionType,
          timestamp: it.createdAt,
        }
      })
    : undefined

  const withdrawalState = data?.withdrawalState

  return { certificate, isLoading, nft, versions, withdrawalState }
}

export default useGetCertificate
