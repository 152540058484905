import { useCallback } from 'react'
import Dialog from '../atoms/Dialog'
import Text from '../atoms/Text'
import { Blockchain } from '@verisart/nft/src'
import { ReactComponent as WalletConnect } from '../../icons/ico-walletconnect-logo.svg'
import { ReactComponent as CoinbaseIcon } from '../../icons/ico-coinbase.svg'
import { ReactComponent as MetaMask } from '../../icons/ico-metamask.svg'
import { ReactComponent as MagicLink } from '../../icons/ico_magic.svg'
import { ReactComponent as WalletConnectButton } from '../../icons/WalletConnectButton.svg'
import { ReactComponent as CoinbaseWalletButton } from '../../icons/CoinbaseWalletButton.svg'
import { ReactComponent as MagicLinkButton } from '../../icons/MagicLinkButton.svg'

import t from '../../lib/translation'
import useMetaMaskOnboarding, {
  isMetaMaskInstalled,
} from '@verisart/nft/src/useMetaMaskOnboarding'
import { ClaimPlatform } from '@verisart/nft/src/http'
import { ConnectorNames } from '@verisart/nft/src/useConnectWallet'
import * as API from '../../api'

interface ConnectMetamaskDialogInterface {
  show: boolean
  hide: (cancelled: boolean) => void
  connectWallet: (
    connectorNames: ConnectorNames,
    claimPlatform: ClaimPlatform,
    shopDomain: string | null,
    blockchain?: Blockchain
  ) => void
  blockchain?: Blockchain
  claim?: boolean
}

const ConnectMetamaskDialog: React.FC<ConnectMetamaskDialogInterface> = ({
  show,
  hide,
  connectWallet,
  blockchain,
  claim,
}) => {
  const { startMetaMaskOnboarding } = useMetaMaskOnboarding()

  const handlePlatformSelection = useCallback(
    async (_platform: ConnectorNames) => {
      const shopDomain = API.customer.shopDomain
      const claimPlatform = ClaimPlatform.CUSTOMER_DASHBOARD
      if (_platform === ConnectorNames.MagicLink) {
        connectWallet(_platform, claimPlatform, shopDomain, blockchain)
      } else if (_platform === ConnectorNames.Injected) {
        if (isMetaMaskInstalled()) {
          connectWallet(_platform, claimPlatform, shopDomain)
        } else {
          startMetaMaskOnboarding?.()
        }
      } else {
        connectWallet(_platform, claimPlatform, shopDomain)
      }
      hide(false)
    },
    [hide, connectWallet, blockchain, startMetaMaskOnboarding]
  )

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  return (
    <>
      <Dialog show={show} onDismiss={() => hide(true)}>
        <div className={'ver-w-72 ver-flex ver-flex-col ver-gap-5'}>
          <Text bold variant={'base'}>
            {claim ? t.dialog.connectWallet.title : t.works.connectWallet}
          </Text>

          {!isMobile() ? (
            <>
              <button
                className="ver-flex ver-items-center ver-p-2 ver-border ver-border-solid ver-border-outline ver-w-full"
                type="button"
                onClick={() => handlePlatformSelection(ConnectorNames.Injected)}
              >
                <MetaMask />
                <span
                  data-test="connectMetamask"
                  className="ver-flex-grow ver-font-sans ver-text-base ver-font-medium"
                >
                  Metamask
                </span>
              </button>
              <button
                className="ver-flex ver-items-center ver-p-2 ver-border ver-border-solid ver-border-outline ver-w-full"
                type="button"
                onClick={() =>
                  handlePlatformSelection(ConnectorNames.WalletConnect)
                }
              >
                <WalletConnect />
                <span className="ver-flex-grow ver-font-sans ver-text-base ver-font-medium">
                  WalletConnect
                </span>
              </button>

              <button
                className="ver-flex ver-items-center ver-p-2 ver-border ver-border-solid ver-border-outline ver-w-full"
                type="button"
                onClick={() => handlePlatformSelection(ConnectorNames.Coinbase)}
              >
                <CoinbaseIcon />
                <span className="ver-flex-grow ver-font-sans ver-text-base ver-font-medium">
                  Coinbase Wallet
                </span>
              </button>
              <button
                className="ver-flex ver-items-center ver-p-2 ver-border ver-border-solid ver-border-outline ver-w-full"
                type="button"
                onClick={() =>
                  handlePlatformSelection(ConnectorNames.MagicLink)
                }
              >
                <MagicLink />
                <span className="ver-flex-grow ver-font-sans ver-text-base ver-font-medium">
                  Magic Link
                </span>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() =>
                  handlePlatformSelection(ConnectorNames.WalletConnect)
                }
              >
                <WalletConnectButton />
              </button>

              <button
                type="button"
                onClick={() => handlePlatformSelection(ConnectorNames.Coinbase)}
              >
                <CoinbaseWalletButton />
              </button>
              <button
                type="button"
                onClick={() =>
                  handlePlatformSelection(ConnectorNames.MagicLink)
                }
              >
                <MagicLinkButton />
              </button>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default ConnectMetamaskDialog
