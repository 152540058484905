import styles from '../styles/containers.module.css'
import ReadMoreText from '../components/atoms/ReadMoreText/ReadMoreText'
import SimpleFooter from '../components/molecules/SimpleFooter'
import { ReactComponent as IcoOpeansea } from '../icons/ico-opensea.svg'
import { ReactComponent as IcoPolygonscan } from '../icons/ico-polygonscan.svg'
import { ReactComponent as IcoEthereumscan } from '../icons/ico-ethereumscan.svg'
import { ReactComponent as IcoBasescan } from '../icons/ico-basescan.svg'
import { ReactComponent as IcoVerisart } from '../icons/ico-verisart-logo.svg'
import DurationDisplayer from '../components/atoms/DurationDisplayer'
import config from '../lib/config'
import useGetCertificate, { Certificate } from '../lib/hooks/useGetCertificate'
import VersionHistoryDisplayer from '../components/molecules/VersionHistoryDisplayer'
import TransferNFT from '../components/molecules/TransferNFT'
import Text from '../components/atoms/Text'
import React, { useCallback, useState } from 'react'
import { Spinner } from '@verisart/shared'
import Button from '../components/atoms/Button'
import t, { formatString } from '../lib/translation'
import { useNavigate, useParams } from 'react-router-dom'
import { generateClaimPath } from 'src/lib/paths'
import {
  BlockchainClass,
  blockchainToClass,
  openSeaAssetsBaseUrl,
  scannerBaseUrl,
} from '@verisart/nft/src'
import TestNFTNotification from '../components/molecules/TestNFTNotification'
import NotesText from 'src/components/molecules/NotesText/NotesText'
import CollectorRewards from 'src/components/molecules/CollectorRewards'
import { AssetView } from '@verisart/shared/src'
import { isModel } from '@verisart/shared/src/utils/fileTypes'
import {
  dimensionString,
  editionString,
  formatProductionYears,
} from '@verisart/shared/src/utils/formatStrings'
import assert from 'assert'
import { ComputedCertificateFeature } from '@verisart/shared/src'
import CertificateBadgeIcon from '@verisart/shared/src/ui/CertificateBadgeIcon'
import EmptyLayout from '../layouts/EmptyLayout'
import TopBar from 'src/components/organisms/TopBar'
import EthereumTruncatedString from 'src/components/organisms/EthereumTruncatedString'

const blockchainShortName = {
  ETHEREUM: 'Ethereum',
  POLYGON: 'Polygon',
  ETHEREUM_GOERLI: 'Ethereum Goerli Testnet',
  ETHEREUM_SEPOLIA: 'Sepolia Testnet',
  FAKE_ETHEREUM_ON_SEPOLIA: 'Fake Ethereum (on Sepolia)',
  FAKE_BASE_ON_BASE_SEPOLIA: 'Fake Base (on Base-Sepolia)',
  POLYGON_MUMBAI: 'Polygon Mumbai Testnet',
  POLYGON_AMOY: 'Polygon Amoy Testnet',
  BASE_MAINNET: 'Base',
  BASE_SEPOLIA: 'Base Sepolia Testnet',
}

const WorkPage = () => {
  const [play, setPlay] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams<'id'>()
  const { certificate, nft, versions, withdrawalState } = useGetCertificate(
    id as string,
    true
  )
  const nftSuffix = `/${nft?.contractAddress}/${nft?.tokenId}`

  const navigateToClaim = useCallback(() => {
    navigate(generateClaimPath(id as string))
  }, [navigate, id])

  const collectorFiles = certificate?.files.filter((file) => !file.isPublic)

  return (
    <>
      <TopBar blockchain={nft?.blockchain} showAddOn={certificate && !nft} />
      {certificate ? (
        <>
          <div>
            <div className={`${styles['container']}`}>
              <div
                className={'ver-flex ver-py-4 sm:ver-px-8 ver-px-2 ver-w-full'}
              >
                {withdrawalState === 'NOT_REQUESTED' && (
                  <div className="ver-flex-1">
                    <div className={'ver-flex ver-justify-end ver-h-full'}>
                      <div
                        className={'ver-flex ver-flex-col ver-justify-center '}
                      >
                        <div className="ver-w-24 sm:ver-w-52">
                          <Button fullWidth onClick={navigateToClaim}>
                            {t.claim.button.confirm}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {certificate.computedFeatures.includes(
                ComputedCertificateFeature.NFT_TESTNET
              ) && <TestNFTNotification size="sm" />}
              <ImageView
                certificate={certificate}
                setPlay={setPlay}
                play={play}
              />
              <div className={`ver-flex md:ver-justify-center`}>
                <div className="ver-pt-10 ver-px-4 md:ver-px-16 ver-flex-grow ver-max-w-[1920px]">
                  {certificate.artist && (
                    <Text variant={'large-2xl'} bold>
                      {certificate.artist}
                    </Text>
                  )}
                  <br />
                  <Text variant={'large-3.5xl'} bold>
                    {certificate.title}
                  </Text>
                  <div className={'ver-mt-10'}>
                    <div className={'ver-flex ver-flex-col md:ver-flex-row '}>
                      <div>
                        {nft && (
                          <div className={'ver-inline-block'}>
                            <a
                              href={`${config.verisartBaseUrl}/works/${
                                id as string
                              }`}
                              target={'_blank'}
                              rel={'noreferrer'}
                            >
                              <img
                                src={
                                  'https://verisart-marketing-production.s3.amazonaws.com/emails/MintedVerisartBlack.svg'
                                }
                                className={'ver-inline-block ver-h-16'}
                                alt=""
                              />
                            </a>
                          </div>
                        )}
                        {certificate.public.description && (
                          <div
                            className={'ver-max-w-5xl md:ver-pr-12 ver-mt-16'}
                          >
                            <Text variant={'large-2xl'} bold>
                              {t.works.descriptionHeading}
                            </Text>
                            <div
                              data-test="certificateDescription"
                              className="ver-mt-4"
                            >
                              <ReadMoreText
                                text={certificate.public.description}
                              />
                            </div>
                          </div>
                        )}
                        <div className={'ver-mt-16'}>
                          <Text variant={'large-2xl'} bold>
                            {t.works.detailsHeading}
                          </Text>
                          {certificate.private?.product?.orderName && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.orderName}:
                              </Text>
                              <Text>
                                {certificate.private?.product?.orderName}
                              </Text>
                            </div>
                          )}
                          {certificate.private?.product?.tokenGate
                            ?.blockchain && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.tokenGateBlockchain}:
                              </Text>
                              <Text>
                                {
                                  blockchainShortName[
                                    certificate.private?.product?.tokenGate
                                      ?.blockchain
                                  ]
                                }
                              </Text>
                            </div>
                          )}
                          {certificate.private?.product?.tokenGate
                            ?.contractAddress && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {
                                  t.works.metafieldLabel
                                    .tokenGateContractAddress
                                }
                                :
                              </Text>
                              <Text>
                                <EthereumTruncatedString
                                  type="address"
                                  asLink={true}
                                  text={
                                    certificate.private?.product?.tokenGate
                                      ?.contractAddress
                                  }
                                  blockchain={
                                    certificate.private?.product?.tokenGate
                                      ?.blockchain
                                  }
                                />
                              </Text>
                            </div>
                          )}
                          {certificate.private?.product?.tokenGate?.tokenId && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.tokenGateTokenId}:
                              </Text>
                              <Text>
                                <EthereumTruncatedString
                                  type="tokenId"
                                  asLink={false}
                                  text={
                                    certificate.private?.product?.tokenGate
                                      ?.tokenId
                                  }
                                />
                              </Text>
                            </div>
                          )}
                          {certificate.private?.product?.tokenGate
                            ?.claimedBy && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.tokenGateClaimedBy}:
                              </Text>
                              <Text>
                                <EthereumTruncatedString
                                  type="address"
                                  asLink={true}
                                  text={
                                    certificate.private?.product?.tokenGate
                                      ?.claimedBy
                                  }
                                  blockchain={
                                    certificate.private?.product?.tokenGate
                                      ?.blockchain
                                  }
                                />
                              </Text>
                            </div>
                          )}
                          {certificate.productionYears && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.productionYear}
                              </Text>

                              <Text>
                                {formatProductionYears(
                                  certificate.productionYears
                                )}
                              </Text>
                            </div>
                          )}
                          {certificate.public.medium && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>{t.works.metafieldLabel.medium}</Text>
                              <Text>
                                {certificate.public.medium.description}
                              </Text>
                            </div>
                          )}
                          {certificate.public.dimensions && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.dimensions}
                              </Text>
                              <Text>
                                {dimensionString(certificate.public.dimensions)}
                              </Text>
                            </div>
                          )}
                          {certificate.public.duration && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>
                                {t.works.metafieldLabel.duration}
                              </Text>

                              <DurationDisplayer
                                duration={certificate.public.duration}
                              />
                            </div>
                          )}
                          {certificate.public.edition && (
                            <div className="ver-mt-3 ver-flex ver-gap-2">
                              <Text bold>{t.works.metafieldLabel.edition}</Text>
                              <Text>
                                {editionString(certificate.public.edition)}
                              </Text>
                            </div>
                          )}
                        </div>
                        {certificate.notes.length > 0 && (
                          <div
                            className={'ver-max-w-5xl md:ver-pr-12 ver-mt-16'}
                          >
                            <Text variant={'large-2xl'} bold>
                              {t.works.notesHeading}
                            </Text>
                            <NotesText notes={certificate.notes} />
                          </div>
                        )}
                        <div className={'ver-mt-12'}>
                          {nft && (
                            <>
                              <span
                                className={
                                  'ver-cursor-pointer ver-inline-block'
                                }
                              >
                                <a
                                  target={'_blank'}
                                  rel="noreferrer"
                                  href={
                                    openSeaAssetsBaseUrl(nft.blockchain) +
                                    nftSuffix
                                  }
                                >
                                  <IcoOpeansea
                                    className={'ver-inline-block ver-mr-4'}
                                  />
                                  <Text>
                                    {formatString(t.works.view, 'OpenSea')}
                                  </Text>
                                </a>
                              </span>
                              {blockchainToClass(nft.blockchain) ==
                                BlockchainClass.POLYGON && (
                                <a
                                  target={'_blank'}
                                  rel="noreferrer"
                                  className={'ver-mt-4 ver-block ver-w-fit'}
                                  href={`${scannerBaseUrl(
                                    nft.blockchain
                                  )}/token/${nft.contractAddress}?a=${
                                    nft.tokenId
                                  }`}
                                >
                                  <span
                                    className={'ver-mt-4 ver-cursor-pointer'}
                                  >
                                    <IcoPolygonscan
                                      className={'ver-inline-block ver-mr-4'}
                                    />
                                    <Text>
                                      {formatString(
                                        t.works.view,
                                        'Polygonscan'
                                      )}
                                    </Text>
                                  </span>
                                </a>
                              )}
                              {blockchainToClass(nft.blockchain) ==
                                BlockchainClass.ETHEREUM && (
                                <a
                                  target={'_blank'}
                                  rel="noreferrer"
                                  className={'ver-mt-4 ver-block ver-w-fit'}
                                  href={`${scannerBaseUrl(
                                    nft.blockchain
                                  )}/token/${nft.contractAddress}?a=${
                                    nft.tokenId
                                  }`}
                                >
                                  <span
                                    className={'ver-mt-4 ver-cursor-pointer'}
                                  >
                                    <IcoEthereumscan
                                      className={'ver-inline-block ver-mr-4'}
                                    />
                                    <Text>
                                      {formatString(t.works.view, 'Etherscan')}
                                    </Text>
                                  </span>
                                </a>
                              )}

                              {blockchainToClass(nft.blockchain) ==
                                BlockchainClass.BASE && (
                                <a
                                  target={'_blank'}
                                  rel="noreferrer"
                                  className={'ver-mt-4 ver-block ver-w-fit'}
                                  href={`${scannerBaseUrl(
                                    nft.blockchain
                                  )}/token/${nft.contractAddress}?a=${
                                    nft.tokenId
                                  }`}
                                >
                                  <span
                                    className={'ver-mt-4 ver-cursor-pointer'}
                                  >
                                    <IcoBasescan
                                      className={'ver-inline-block ver-mr-4'}
                                    />
                                    <Text>
                                      {formatString(t.works.view, 'Basescan')}
                                    </Text>
                                  </span>
                                </a>
                              )}
                            </>
                          )}
                          <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            className={'ver-mt-4 ver-block ver-w-fit'}
                            href={`${config.verisartBaseUrl}/works/${
                              id as string
                            }`}
                          >
                            <span className={'ver-mt-4 ver-cursor-pointer'}>
                              <IcoVerisart
                                className={'ver-inline-block ver-mr-4'}
                              />
                              <Text>
                                {formatString(t.works.view, 'Verisart')}
                              </Text>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className={
                          'ver-mt-24 md:ver-mt-0 md:ver-flex ver-flex-col ver-justify-start ver-items-end md:ver-flex-grow'
                        }
                      >
                        <div>
                          <div className="ver-mb-12">
                            {id &&
                              collectorFiles &&
                              !!collectorFiles.length && (
                                <CollectorRewards
                                  id={id}
                                  collectorRewards={collectorFiles}
                                />
                              )}
                          </div>
                          {nft && (
                            <div className="ver-mb-12">
                              <TransferNFT
                                blockchain={nft.blockchain}
                                contractAddress={nft.contractAddress}
                                tokenId={nft.tokenId}
                              />
                            </div>
                          )}
                          <div>
                            {versions && certificate.public.nft && (
                              <>
                                <Text variant={'large-2xl'} bold>
                                  {t.works.purchaseHistoryTitle}
                                </Text>
                                <div className={'ver-mt-4'}>
                                  <VersionHistoryDisplayer
                                    versionDetails={versions}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SimpleFooter />
        </>
      ) : (
        <EmptyLayout>
          <div className="ver-w-full ver-text-center">
            <Spinner />
          </div>
        </EmptyLayout>
      )}
    </>
  )
}

export default WorkPage

type ImageViewProps = {
  certificate: Certificate
  setPlay: (bool: boolean) => void
  play: boolean
}

const ImageView: React.FC<ImageViewProps> = ({ certificate }) => {
  const primaryImage = certificate.publicImages.find(
    (file) => file.isPublic && file.isPrimary
  )
  assert(primaryImage, 'Primary image not found')

  const primaryUrl = primaryImage.externalHTML ?? primaryImage.url

  // Workaround for models. Unlike <img> and <video> models don't have a natural size
  const model = isModel(primaryImage.url)

  return (
    <div
      className={'ver-w-full ver-p-8 ver-pb-16 ver-bg-background ver-relative'}
    >
      <div className={`ver-relative ver-w-full ver-flex ver-justify-center`}>
        <div
          style={{ cursor: 'pointer' }}
          className={`ver-flex ver-flex-col ver-justify-center ${
            model ? 'ver-w-full' : ''
          }`}
        >
          <AssetView
            style={{ maxHeight: '75vh' }}
            primaryUrl={primaryUrl}
            mediaOverride={primaryImage.externalHTML ? 'html' : undefined}
            alt=""
            play
          />
          <button
            onClick={() => window.open(primaryUrl)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
              padding: 0,
              zIndex: 10,
            }}
          />
        </div>
      </div>
      {
        <div className="ver-absolute ver-right-4 md:ver-right-32">
          <CertificateBadgeIcon
            confidenceLevel={certificate.computedConfidenceLevel}
            project={certificate.public.project}
          />
        </div>
      }
    </div>
  )
}
