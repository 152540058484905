import MetaMaskOnboarding from '@metamask/onboarding'
import { useEffect, useRef } from 'react'
import device from './deviceDetect'
import Web3 from 'web3'

const METAMASK_DAPP_DEEPLINK = 'https://metamask.app.link/dapp/'

export const isMetaMaskInstalled = (): boolean => {
  return (
    MetaMaskOnboarding.isMetaMaskInstalled() ||
    // Incredibly, MetaMaskOnboarding.isMetaMaskInstalled() returns false
    // in embedded mode on Android. This is the best we can do
    navigator.userAgent.includes('MetaMaskMobile')
  )
}

const useMetaMaskOnboarding = () => {
  const metaMaskOnboarding = useRef<MetaMaskOnboarding>()
  useEffect(() => {
    if (!metaMaskOnboarding.current) {
      metaMaskOnboarding.current = new MetaMaskOnboarding()
    }
  }, [])
  return {
    startMetaMaskOnboarding: () => {
      if (device().ios() || device().android()) {
        // While MetaMaskOnboarding does work on mobile, it seems to do a weird thing where it tries to get you
        // to use a desktop and link it somehow. We decided it's much better to simply go via the mobile app.
        // If you don't have Metamask app installed it will redirect to app store page.
        // If you already have Metamask installed on your mobile, it will open our site there and we will act
        // in embedded mode.
        const scheme = `${window.location.protocol}//`
        window.location.href = window.location.href.replace(
          scheme,
          METAMASK_DAPP_DEEPLINK
        )
      } else {
        metaMaskOnboarding.current?.startOnboarding()

        if (Web3.givenProvider) {
          // Workaround for a weird Coinbase issue. If you start the onboarding for Metamask but you have Coinbase
          // app installed then this seems to cause some kind of infinite reload. Seems like a bug between Coinbase
          // and the metamask onboarding library
          metaMaskOnboarding.current?.stopOnboarding()
        }
      }
    },
  }
}

export default useMetaMaskOnboarding
