import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Routes, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import './globals.css'
import RootLayout from './layouts/RootLayout'
import {
  addOnPath,
  claimPath,
  mintPath,
  testWalletPath,
  worksPath,
} from './lib/paths'

// Register pages for use with React router
import LegacyClaim from './pages/LegacyClaim'
import Dashboard from './pages/Dashboard'
import Mint from './pages/Mint'
import Work from './pages/Work'
import { ConnectWalletContextProvider } from '@verisart/nft/src'
import { initWagmi } from '@verisart/nft/src/ConnectWalletContextProvider'
import TestWallet from './components/organisms/TestWallet'
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk'
import { logging } from '@optimizely/optimizely-sdk'
import AddOnRedirect from './pages/AddOnRedirect'

initWagmi(
  process.env.REACT_APP_ALCHEMY_KEY as string,
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
  process.env.REACT_APP_MAGIC_LINK_KEY as string
)

const logLevel = 'ERROR'

export const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_PUBLIC_OPTIMIZELY_SDK_KEY,
  logger: logging.createLogger({ logLevel }),
})

const App = () => {
  const [queryClient] = useState(() => new QueryClient())
  return (
    <OptimizelyProvider optimizely={optimizely} user={{ id: 'anonymous' }}>
      <ConnectWalletContextProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<RootLayout />}>
                <Route index element={<Dashboard />} />
                <Route path={claimPath} element={<LegacyClaim />} />
                <Route path={mintPath} element={<Mint />} />
                <Route path={worksPath} element={<Work />} />
                <Route path={testWalletPath} element={<TestWallet />} />
                <Route path={addOnPath} element={<AddOnRedirect />} />
              </Route>
            </Routes>
          </QueryClientProvider>
        </QueryParamProvider>
      </ConnectWalletContextProvider>
    </OptimizelyProvider>
  )
}

export default App
