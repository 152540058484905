import React, { PropsWithChildren } from 'react'

interface StackProps {
  className?: string
  horizontal?: boolean
  spacing?: 'sm' | 'md'
}

const spacingClassName = (horizontal: boolean, spacing: 'sm' | 'md') => {
  switch (spacing) {
    case 'sm':
      return horizontal ? 'ver-ml-1' : 'ver-mt-1'
    default:
      return horizontal ? 'ver-ml-5' : 'ver-mt-5'
  }
}

const Stack = React.forwardRef<HTMLDivElement, PropsWithChildren<StackProps>>(
  ({ children, className, horizontal, spacing = 'md' }, ref) => {
    const wrapperClassName = [
      className,
      'ver-flex ver-justify-center',
      horizontal ? 'ver-flex-row' : 'ver-flex-col',
    ].join(' ')
    return (
      <div className={wrapperClassName} ref={ref}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const childClassName = (child as any)['props']?.['className']
            const className = childClassName
              ? `${childClassName} ${spacingClassName(
                  horizontal ?? false,
                  spacing
                )}`
              : spacingClassName(horizontal ?? false, spacing)
            return React.cloneElement(child, { className } as
              | HTMLElement
              | SVGElement)
          }
          return (
            <div className={spacingClassName(horizontal ?? false, spacing)}>
              {child}
            </div>
          )
        })}
      </div>
    )
  }
)
Stack.displayName = 'Stack'

export default Stack
