import Text from './Text'
import Heading from './Heading'
import globalStyle from '../globals.module.css'
import { ReactNode } from 'react'
import { formatProductionYears, ProductionYears } from '@verisart/shared'

export type CardDetailsProps = {
  artistName: string | null
  title: string
  subtitle?: ReactNode
  productionYears: ProductionYears | null
  footer?: JSX.Element
  showCardStack?: boolean
}

export const CardDetails: React.FC<CardDetailsProps> = ({
  artistName,
  title,
  subtitle,
  productionYears,
  footer,
  showCardStack,
}) => {
  return (
    <div
      className={`ver-flex ver-flex-col ${
        showCardStack ? globalStyle['paper'] : ''
      }`}
    >
      <div className="ver-p-5 ver-border ver-border-solid ver-border-outline ver-flex ver-flex-col ver-justify-start ver-text-secondary ver-h-56 ver-bg-surface">
        {artistName && <Heading variant="heading-2">{artistName}</Heading>}

        <div className="ver-flex ver-flex-row ver-text-lg ver-font-normal ver-leading-6">
          <p className="ver-truncate ver-pr-0.5">{title}</p>
          {productionYears && (
            <p className="ver-inline ver-flex-shrink-0">
              , {formatProductionYears(productionYears)}
            </p>
          )}
        </div>

        {subtitle && (
          <p className="ver-text-seconary-variant">
            <Text>{subtitle}</Text>
          </p>
        )}

        {footer && footer}
      </div>
    </div>
  )
}
