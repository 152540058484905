export default {
  get environment(): string {
    return process.env.REACT_APP_ENVIRONMENT_TYPE || 'development'
  },

  get verisartBaseUrl(): string {
    if (this.environment === 'development') {
      return 'http://localhost:3000'
    } else if (this.environment === 'test') {
      return 'https://website-test.verisart.com'
    } else {
      return 'https://verisart.com'
    }
  },

  get isTest(): boolean {
    return this.environment === 'test' || this.environment === 'development'
  },
}
