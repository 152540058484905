import type { KeyboardEvent } from 'react'
import { useCallback } from 'react'
import ReactDOM from 'react-dom'
import IconClose from '../../icons/close'

interface DialogProps {
  show?: boolean
  onDismiss?: () => void
  showCloseButton?: boolean
  disableBackgroundClose?: boolean
}
const Dialog: React.FC<DialogProps> = ({
  children,
  show,
  onDismiss,
  showCloseButton = false,
  disableBackgroundClose = false,
}) => {
  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onDismiss?.()
      }
    },
    [onDismiss]
  )
  const onClickOverlay = useCallback(() => {
    if (disableBackgroundClose) {
      return
    }
    onDismiss?.()
  }, [disableBackgroundClose, onDismiss])

  if (!document.body || !show) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      className="ver-grid ver-items-center ver-justify-items-center ver-w-screen ver-h-screen ver-fixed ver-inset-0 ver-z-dialog ver-bg-dialog-overlay"
      onKeyPress={onEscape}
      onClick={onClickOverlay}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="ver-py-9 ver-px-8 ver-bg-surface ver-relative"
      >
        {showCloseButton && (
          <button
            onClick={onDismiss}
            className="ver-absolute ver-top-2 ver-right-2 ver-text-lg ver-font-bold ver-cursor-pointer ver-p-1"
          >
            <IconClose />
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body
  )
}
export default Dialog
