import {
  Blockchain,
  ConnectWalletError,
  getMainChainIdNumber,
  isConnectWalletError,
  registerWallet,
  translations,
} from './common'
import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { AxiosInstance } from 'axios'
import Web3 from 'web3'
import assert, { fail } from 'assert'
import useGetAccountBlockchainAddresses, {
  BlockchainAddress,
} from './useGetAccountBlockchainAddresses'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import {
  Connector,
  ConnectorAlreadyConnectedError,
  useAccount,
  useConnect,
  useDisconnect,
} from 'wagmi'
import { createSimpleRegistrationError } from './errors'
import { ClaimPlatform } from './http'
import { UniversalWalletConnector } from '@magiclabs/wagmi-connector'
import { getNetwork, switchNetwork } from '@wagmi/core'

export enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  Coinbase = 'Coinbase',
  MagicLink = 'MagicLink',
}

export type ConnectWalletResponse = {
  account: string
  connector: Connector<any, any>
  platform?: ConnectorNames
}

export type ConnectWallet = (
  connectorName: ConnectorNames,
  claimPlatform: ClaimPlatform,
  shopDomain: string | null,
  /**
   * Which chain to initialise with - required for ConnectorNames.MagicLink, ignored in all other cases
   */
  blockchain?: Blockchain,
  force?: boolean
) => Promise<ConnectWalletResponse>

export type UseConnectWalletReturn = {
  /**
   * Whether the hook is still loading (some API calls need to happen before it can determine if an account
   * is linked)
   */
  loading: boolean

  /**
   * Whether a wallet connect+link is in progress. This includes everything from the point at which connecting
   * starts, through doing a core link API call
   */
  connecting: boolean

  /**
   * Mutation to start the connection process.
   *
   * This is async so you can await the response. Errors will cause an update to the [error] value
   * and will also get thrown as exceptions. This allows you to handle errors directly instead of reactively.
   *
   * If a wallet is already linked, this is NOT treated as an error.
   */
  connectWallet: ConnectWallet

  /**
   * The wallet-connected AND core-linked account (this is only set if this is also linked in core)
   */
  account: string | undefined

  /**
   * The wallet-connected account (set even if the wallet is not yet linked in core)
   */
  accountInWallet: string | undefined
} & Pick<
  UseConnectWalletNoLinkingReturn,
  'connector' | 'disconnect' | 'disconnectAll' | 'showMagicWallet' | 'platform'
>

const getLinked = (
  addresses: BlockchainAddress[],
  address: string | undefined
): string | undefined => {
  return addresses.filter((it) => it.address === address)[0]?.address
}

/**
 * Create a Web3 instance from a connector.
 *
 * IMPORTANT! Do this as late as possible and try not to hang on to the Web3 instance for too long.
 * On certain wallets (e.g. Magic.Link) this instance will become stale if you do things like
 * changing network etc.
 *
 * IMPORTANT 2: If you are doing a transaction, you MUST pass in `blockchain`. This is because some connectors
 * (e.g. ConnectWallet actually can in theory support multiple chains connected at the same time). Or, they may
 * ignore the switch chain request, and then lazily switch at the point the TX is done.
 * Passing in the chain ID is a sanity check.
 * If you know you're only using this for signing purposes, it's OK to pass null.
 */
export const web3FromConnector = async (
  connector: Connector<any, any>,
  blockchain: Blockchain | null
): Promise<Web3> => {
  const chain = blockchain ? getMainChainIdNumber(blockchain) : undefined
  // eslint-disable-next-line no-console
  console.info('Getting provider for chain', chain, blockchain)
  const tmp = new Web3(await connector.getProvider({ chainId: chain }))
  // eslint-disable-next-line no-console
  console.info('Got provider for chain', chain, blockchain)
  return tmp
}

/**
 * Hook used for getting and connecting the current core-linked wallet.
 *
 * This only returns accounts which are connected AND linked to core.
 *
 * @see UseConnectWalletReturn
 */
const useConnectWallet = (
  api: AxiosInstance,
  setError?: (e: ConnectWalletError | string | undefined) => void
): UseConnectWalletReturn => {
  const [connecting, setConnecting] = useState<boolean>(false)
  const { data: getAccountData, isLoading } =
    useGetAccountBlockchainAddresses(api)

  const {
    connectWalletNoLinking,
    connector,
    account,
    disconnect,
    disconnectAll,
    showMagicWallet,
    platform,
  } = useConnectWalletNoLinking()

  const activeAccount = account?.toLowerCase()
  const activeLinkedWallet = getAccountData
    ? getLinked(getAccountData.blockchainAddresses, activeAccount)
    : undefined

  const queryClient = useQueryClient()

  const connectWallet = useCallback(
    async (
      platform: ConnectorNames,
      claimPlatform: ClaimPlatform,
      shopDomain: string | null,

      /** The initial blockchain to connect to if known */
      blockchain?: Blockchain,
      force = false
    ): Promise<ConnectWalletResponse> => {
      let account: string | null = null
      let connector: Connector<any, any> | null = null
      try {
        setConnecting(true)
        const tmp = await connectWalletNoLinking(platform, blockchain)
        account = tmp.account
        connector = tmp.connector

        await registerWallet(
          api,
          await web3FromConnector(
            connector,
            null /* only used for off-chain signature so can be null */
          ),
          account,
          force,
          claimPlatform,
          shopDomain
        )

        // Make sure the form will immediately reflect the linked change
        await queryClient.invalidateQueries('account')

        setError?.(undefined)

        // eslint-disable-next-line no-console
        console.info('Completed connectWallet')

        return {
          platform,
          account,
          connector,
        }
      } catch (e: any) {
        if (e.cause !== 'already_linked') {
          console.error('Error in connectWallet', e)
          if (isConnectWalletError(e)) {
            setError?.(
              e.cause === 'already_claimed_by_other'
                ? e
                : 'Sorry, your wallet could not be linked. Please try again.'
            )
          } else {
            setError?.(translations['unknown'] + e.message)
          }
          throw e
        }

        setError?.(undefined)

        assert(account)
        assert(connector)

        return {
          platform,
          account,
          connector,
        }
      } finally {
        setConnecting(false)
      }
    },
    [connectWalletNoLinking, api, queryClient, setError]
  )

  return {
    connecting,
    loading: isLoading,
    connectWallet,
    connector,
    account: activeLinkedWallet,
    accountInWallet: account,
    disconnect,
    disconnectAll,
    showMagicWallet,
    platform,
  }
}

export type ConnectWalletNoLinking = (
  connectorName: ConnectorNames,
  blockchain?: Blockchain
) => Promise<ConnectWalletResponse>

export type UseConnectWalletNoLinkingReturn = {
  connectWalletNoLinking: ConnectWalletNoLinking

  /**
   * The Wagmi connection. Hint: use [web3FromConnector] to get a Web3 instance
   */
  connector: Connector<any, any> | undefined
  account: string | undefined

  /**
   * Disconnects wagmi (the currently selected connector)
   */
  disconnect: () => Promise<void>

  /**
   * Disconnects wagmi and all known connectors. This is kind of a nuclear option only really intended for logout.
   */
  disconnectAll: () => Promise<void>

  /**
   * If the current connector is magic wallet, this will show the
   * magic wallet UI. Otherwise it will be undefined.
   */
  showMagicWallet: (() => Promise<void>) | undefined
  platform: ConnectorNames | undefined
}

/**
 * Connects the wallet in Wagmi without linking to core
 */
export const useConnectWalletNoLinking =
  (): UseConnectWalletNoLinkingReturn => {
    const { connectAsync, connectors } = useConnect()
    const { disconnectAsync } = useDisconnect()
    const { address, connector } = useAccount()

    const connectWalletNoLinking = useCallback(
      async (
        _platform: ConnectorNames,
        blockchain: Blockchain | undefined
      ): Promise<ConnectWalletResponse> => {
        try {
          const newConnector: Connector<any, any> | undefined = connectors.find(
            (c) => {
              if (_platform === ConnectorNames.Coinbase) {
                return c instanceof CoinbaseWalletConnector
              } else if (_platform === ConnectorNames.WalletConnect) {
                return c instanceof WalletConnectConnector
              } else if (_platform === ConnectorNames.Injected) {
                return c instanceof MetaMaskConnector
              } else if (_platform === ConnectorNames.MagicLink) {
                return c instanceof UniversalWalletConnector
              }
            }
          )

          if (!newConnector) {
            fail(`Unknown connector ${_platform}`)
          }

          const chainId = blockchain
            ? getMainChainIdNumber(blockchain)
            : undefined

          try {
            // eslint-disable-next-line no-console
            console.info(
              `useConnectWalletNoLinking: Starting connect for ${_platform} on ${chainId} (${blockchain})`
            )
            await connectAsync({ connector: newConnector, chainId })
            // eslint-disable-next-line no-console
            console.info('useConnectWalletNoLinking: Connect complete')
          } catch (e: any) {
            if (e instanceof ConnectorAlreadyConnectedError) {
              // eslint-disable-next-line no-console
              console.info(
                'useConnectWalletNoLinking: Connection already exists'
              )
            } else if (
              e.message.includes('User denied account') ||
              e.message.includes('Connection request reset')
            ) {
              throw createSimpleRegistrationError('metamask_user_cancelled')
            } else {
              throw e
            }
          }

          const account = await newConnector.getAccount()

          // eslint-disable-next-line no-console
          console.info('useConnectWalletNoLinking: Got wallet and provider')

          return {
            platform: _platform,
            account,
            connector: newConnector,
          }
        } catch (e) {
          console.error('Caught error in useConnectWalletNoLinking', e)
          throw e
        }
      },
      [connectors, connectAsync]
    )

    const disconnectAll = useCallback(async () => {
      await disconnectAsync()
      await Promise.all(connectors.map((c) => c.disconnect()))
    }, [connectors, disconnectAsync])

    const platform =
      connector instanceof UniversalWalletConnector
        ? ConnectorNames.MagicLink
        : connector instanceof CoinbaseWalletConnector
        ? ConnectorNames.Coinbase
        : connector instanceof WalletConnectConnector
        ? ConnectorNames.WalletConnect
        : connector instanceof MetaMaskConnector
        ? ConnectorNames.Injected
        : undefined

    const showMagicWallet = useCallback(async () => {
      if (connector instanceof UniversalWalletConnector) {
        await connector?.magic?.wallet.showUI()
      }
    }, [connector])

    return {
      connectWalletNoLinking,
      connector: connector,
      account: address,
      disconnect: disconnectAsync,
      disconnectAll,

      // Note we do a second instanceof check here so we can return undefined if the connector is not a magic wallet
      showMagicWallet:
        connector instanceof UniversalWalletConnector
          ? showMagicWallet
          : undefined,
      platform,
    }
  }

export const isSwitchBlockchainNeeded = (blockchain: Blockchain): boolean => {
  const currentNetwork = getNetwork()
  const requiredChain = getMainChainIdNumber(blockchain)
  // eslint-disable-next-line no-console
  console.info(
    `Current network is ${currentNetwork.chain?.id}, required network is ${requiredChain}`
  )
  return currentNetwork.chain?.id !== requiredChain
}

export async function switchBlockchain(blockchain: Blockchain) {
  // eslint-disable-next-line no-console
  console.info('Switching blockchain to', blockchain)
  await switchNetwork({ chainId: getMainChainIdNumber(blockchain) })
}

export const ensureBlockchain = async (blockchain: Blockchain) => {
  if (isSwitchBlockchainNeeded(blockchain)) {
    await switchBlockchain(blockchain)
  }
}

export default useConnectWallet
