import * as React from 'react'
import { ReactComponent as IcoVerisartLogoType } from '../../icons/ico-verisart-logo-type.svg'
import t from '../../lib/translation'

const SimpleFooter: React.FunctionComponent = () => {
  return (
    <footer className="ver-flex ver-justify-start ver-w-full ver-py-4 ver-mt-24">
      <div className="ver-px-4 md:ver-px-16 ver-max-w-[1920px]">
        <span className="ver-flex ver-items-center ver-space-x-2">
          <p>{t.footer.poweredBy}</p>
          <a href="https://verisart.com" target="_blank" rel="noreferrer">
            <IcoVerisartLogoType />
          </a>
        </span>
      </div>
    </footer>
  )
}

export default SimpleFooter
