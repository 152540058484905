import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as Sentry from '@sentry/browser'
import config from './lib/config'
import assert from 'assert'
import mixpanel from 'mixpanel-browser'

let shopFound = false

// Handle iframe mode. In that case we expect to be visited at `/<shop_domain>` as the root
if (!window.verisartAppConfig?.shopDomain) {
  const url = new URL(window.location.href)
  const firstPart = url.pathname.split('/')[1]

  if (firstPart && firstPart.includes('.')) {
    // Expect a domain name
    assert(
      window.verisartAppConfig,
      'Expected window.verisartAppConfig to be set'
    )
    window.verisartAppConfig.pathPrefix = `/${firstPart}`
    window.verisartAppConfig.shopDomain = firstPart
    shopFound = true
  }
} else {
  shopFound = true
}

const basename = window.verisartAppConfig?.pathPrefix ?? '/'
const environment = config.environment || process.env.NODE_ENV

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.contextLinesIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: true,
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
})

const mixPanelToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN
if (mixPanelToken) {
  mixpanel.init(mixPanelToken, { api_host: 'https://api-eu.mixpanel.com' }, '')
}

if (!shopFound) {
  ReactDOM.render(
    <p>Error: No shop found! Browse to `/&lt;shop_domain&gt;`</p>,
    document.getElementById('verisart-app')
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={basename}>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('verisart-app')
  )
}

const iframeMode = window.parent !== window

if (iframeMode) {
  window.addEventListener('load', function () {
    const verisartAppElement = document.getElementById('verisart-app')

    if (verisartAppElement) {
      // Log the initial scroll height
      logScrollHeight(verisartAppElement)

      // Set up a MutationObserver to monitor changes in the 'verisart-app' element
      const observer = new MutationObserver(() => {
        logScrollHeight(verisartAppElement)
      })

      // Define the configuration for the MutationObserver
      const config = { childList: true, subtree: true }

      // Start observing the 'verisart-app' element with the defined configuration
      observer.observe(verisartAppElement, config)

      // Add an event listener for window resize
      window.addEventListener('resize', () => {
        logScrollHeight(verisartAppElement)
      })
    } else {
      // eslint-disable-next-line no-console
      console.error("Element with ID 'verisart-app' not found")
    }

    // Function to log the scroll height of the element
    function logScrollHeight(element) {
      const childElements = element.children
      let totalHeight = 0

      for (const childElement of childElements) {
        const childHeight =
          childElement.scrollHeight +
          parseFloat(getComputedStyle(childElement).marginTop) +
          parseFloat(getComputedStyle(childElement).marginBottom)
        totalHeight += childHeight
      }

      const shopDomain = window.verisartAppConfig?.shopDomain
      window.parent.postMessage(
        'resize|' + totalHeight,
        `https://${shopDomain}/customer-dashboard/`
      )
    }
  })
}
