// import NFTIcon from '../../icons/ico-nftcard.svg'
import { ReactComponent as NFTIcon } from '../../icons/ico-nftcard.svg'
import t, { formatString } from '../../lib/translation'

const EmptyState = () => {
  const iframeMode = window.parent !== window

  return (
    <div className="ver-w-full ver-flex ver-justify-center ver-items-center ver-space-x-5">
      <NFTIcon />
      <div className="ver-flex ver-flex-col">
        <span>{t.dashboard.emptyState.noNfts}</span>
        {!iframeMode && (
          <span>
            {formatString(
              t.dashboard.emptyState.purchase.string,
              <a className="ver-underline" href="/">
                {t.dashboard.emptyState.purchase.link}
              </a>
            )}
          </span>
        )}
      </div>
    </div>
  )
}

export default EmptyState
