import { NFTWithVersionType } from '../../lib/hooks/useGetCertificate'
import VersionLine from '../atoms/VersionLine'
import assert from 'assert'

// Assumes the version details come in order
// At the time of writing this file, core does that
const VersionHistoryDisplayer: React.FC<{
  versionDetails: Array<NFTWithVersionType>
}> = ({ versionDetails }) => {
  const [firstEvent, secondEvent] = versionDetails

  if (!firstEvent?.nft || !secondEvent?.nft) {
    return null
  }

  const renderVersionLine = (version: NFTWithVersionType) => {
    assert(version.nft)
    return (
      <VersionLine
        type={version.versionType}
        address={version.nft.ownerAddress}
        timestamp={version.timestamp}
        blockchain={version.nft.blockchain}
        transactionId={version.nft.transactionId}
      />
    )
  }

  return (
    <div>
      <ul>
        {versionDetails
          .slice(2)
          .reverse()
          .map((version) => {
            if (
              version.versionType === 'CREATE' ||
              version.versionType === 'TRANSFER'
            ) {
              return renderVersionLine(version)
            }
          })}
        {firstEvent.nft.ownerAddress === secondEvent.nft.ownerAddress ? (
          <VersionLine
            type={'CREATE'}
            address={firstEvent.nft.ownerAddress}
            timestamp={firstEvent.timestamp}
            blockchain={firstEvent.nft.blockchain}
            sameOwner={true}
            transactionId={firstEvent.nft.transactionId}
          />
        ) : (
          <>
            {renderVersionLine(secondEvent)}
            {renderVersionLine(firstEvent)}
          </>
        )}
      </ul>
    </div>
  )
}

export default VersionHistoryDisplayer
