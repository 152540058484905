import { AssetDetails } from '../../components/organisms/Claim'
import * as API from '../../api'
import { useQuery } from 'react-query'
import { SuperLazyDetailsResponse } from '../../api'
import { formatArtistName } from '@verisart/shared'
import { isTestnet } from '@verisart/nft/src'

export const useGetDropDetails = (
  id: string,
  isPolling?: boolean
): {
  assetDetails: AssetDetails | undefined
  isLoading: boolean
} => {
  const enabled = !!id
  const { data, isLoading } = useQuery<SuperLazyDetailsResponse>(
    ['superLazyMintOrder', id],
    async () => (await API.customer.getSuperLazyMintOrder(id)).data,
    {
      enabled,
      refetchInterval: isPolling && 5000,
    }
  )

  if (!data)
    return {
      assetDetails: undefined,
      isLoading: true,
    }

  const assetDetails = {
    title: data.title,
    thumbnail: data.imageUrl,
    artist: data.artist.name ? formatArtistName(data.artist.name) : '',
    quantity: data.quantity,
    volume: data.volume,
    productionYears: null,
    nftTestnet: isTestnet(data.blockchain),
    signedMinting: data.signedMinting,
    claimed: data.claimed,
    completed: data.completed,
    blockchain: data.blockchain,
    contractAddress: data.contractAddress,
    tokenIds: data.tokenIds,
    firstCertificatePrimaryAssetUrl: data.firstCertificatePrimaryAssetUrl,
    firstCertificateImageUrl: data.firstCertificateImageUrl,
    firstCertificateTitle: data.firstCertificateTitle,
  }

  return { assetDetails, isLoading }
}

export default useGetDropDetails
