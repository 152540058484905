import React from 'react'

import UnverifiedRecordBadge from '../icons/badgeUnverifiedRecord'
import IssuerRecordBadge from '../icons/badgeIssuerRecord'
import COABadge from '../icons/badgeCOA'
import FairtradeArtLogo from '../icons/fair-trade-art-logo'
import { ComputedConfidenceLevel, Owner, Project } from '../types/Certificate'

type CertificateBadgeIconType = {
  confidenceLevel: ComputedConfidenceLevel
  project: Project | null | undefined
  issuer?: Owner | undefined
}
const CertificateBadgeIcon: React.FC<CertificateBadgeIconType> = ({
  confidenceLevel,
  project,
}) => {
  if (project?.type === 'FAIR_TRADE_ART') return <FairtradeArtLogo />
  if (confidenceLevel == ComputedConfidenceLevel.COA) {
    return <COABadge />
  } else if (confidenceLevel === ComputedConfidenceLevel.COA_PLUS) {
    return (
      <img
        src="https://verisart-assets-production.s3.amazonaws.com/badges/COA_Plus_badge.png"
        alt="COA+ badge"
        loading="lazy"
        width="82px"
      />
    )
  } else if (confidenceLevel === ComputedConfidenceLevel.ISSUER_RECORD) {
    return <IssuerRecordBadge />
  } else {
    return <UnverifiedRecordBadge />
  }
}

export default CertificateBadgeIcon
