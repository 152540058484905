import { forwardRef } from 'react'

type InputProps = {
  fullWidth?: boolean
} & Pick<
  // Do NOT add `className`! // If you need extra native props exposed, add them here on a case by case basis.
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'type' | 'name' | 'placeholder' | 'pattern'
>

// Do not add className to this - follow the props pattern
// Note, the forwardRef allows react-hook-form to work properly with these
const Input = forwardRef<any, InputProps>((props, ref) => {
  let className =
    'ver-h-11 ver-border ver-border-solid ver-border-secondary ver-px-2 ver-bg-transparent '

  if (props.fullWidth) {
    className = className + 'ver-w-full '
  }

  return <input className={className} ref={ref} {...props} />
})

export default Input
